import { useState, useMemo, useEffect, useCallback, ChangeEvent } from 'react'
import { v4 as makeUuid } from 'uuid'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { AvailableSettings, SettingValue } from 'types/Settings'

import {
  getAccountSettings,
  getDomainSettings,
  resetAccountAndDomainSettings,
  setHasPageChanges,
  updateAccountSettings,
  updateDomainSettings
} from 'redux/features/settings/settingsSlice'
import { isSuccess, isPending } from 'redux/toolkit/api'
import { useDirtyFormCheck } from 'lib/useDirtyFormCheck'
import { getBlockTransition, setBlockTransition } from 'lib/routes'

export interface State {
  form: FormState
  charactersLeft: number
  isUpdateInProgress: boolean
  isDirtyForm: boolean
  key: string
}

interface FormState {
  [AvailableSettings.OUTBOUND_TAGLINE_ENABLED]: string
  [AvailableSettings.OUTBOUND_TAGLINE]: string
}

export interface EventHandlers {
  onCheckboxChange: (e: ChangeEvent<HTMLInputElement>) => void
  onTextChange: (e: ChangeEvent<HTMLTextAreaElement>) => void
  onSave: () => void
  onCancelConfirm: () => void
  helpConfig: {
    isOpen: boolean
    onHelpClick: () => void
    onCloseHelp: () => void
  }
}

export type UseTaglineLogic = [State, EventHandlers]

const SETTINGS_LIST = [AvailableSettings.OUTBOUND_TAGLINE_ENABLED, AvailableSettings.OUTBOUND_TAGLINE]
export const MAX_CHAR = 3000

export const useTaglineLogic = (): UseTaglineLogic => {
  const [formObject, setFormObject] = useState<FormState>({
    [AvailableSettings.OUTBOUND_TAGLINE_ENABLED]: SettingValue.DISABLED,
    [AvailableSettings.OUTBOUND_TAGLINE]: ''
  })
  const [isHelpDialogOpened, setIsHelpDialogOpened] = useState<boolean>(false)

  const dispatch = useAppDispatch()

  const {
    accessTokenObject,
    isUpdateAccountSettingsPending,
    isUpdateDomainSettingsPending,
    isUpdateAccountSettingsSuccess,
    isUpdateDomainSettingsSuccess,
    accountSettings,
    domainSettings
  } = useAppSelector(_stores => ({
    accessTokenObject: _stores.auth.accessTokenObject,
    isUpdateAccountSettingsPending: isPending(_stores.settings.updateAccountSettingsApiStatus),
    isUpdateDomainSettingsPending: isPending(_stores.settings.updateDomainSettingsApiStatus),
    isUpdateAccountSettingsSuccess: isSuccess(_stores.settings.updateAccountSettingsApiStatus),
    isUpdateDomainSettingsSuccess: isSuccess(_stores.settings.updateDomainSettingsApiStatus),
    accountSettings: _stores.settings.accountSettings,
    domainSettings: _stores.settings.domainSettings
  }))
  const [shouldUpdateInitialForm, setShouldUpdateInitialForm] = useState(false)
  const [isDirtyForm, resetInitialForm] = useDirtyFormCheck([formObject])

  // key is used to force a re-render of <SettingsPage>
  // The Cancel button is supposed to reset the initial form values only after a confirmation.
  // The need for a confirmation (block) is set up via useDirtyFormCheck
  const [key, setKey] = useState(makeUuid())

  // init
  useEffect(() => {
    if (accessTokenObject?.pdDomainId) {
      dispatch(getDomainSettings({ domainId: accessTokenObject?.pdDomainId, settings: SETTINGS_LIST }))
    } else {
      dispatch(getAccountSettings(SETTINGS_LIST))
    }
    // eslint-disable-next-line
  }, [])

  const onHelpClick = useCallback(() => {
    setIsHelpDialogOpened(true)
  }, [])

  const onCloseHelp = useCallback(() => {
    setIsHelpDialogOpened(false)
  }, [])

  const setInitialFormState = useCallback(() => {
    if (accessTokenObject?.pdDomainId) {
      setFormObject({
        [AvailableSettings.OUTBOUND_TAGLINE_ENABLED]:
          (domainSettings[AvailableSettings.OUTBOUND_TAGLINE_ENABLED] as string) || SettingValue.DISABLED,
        [AvailableSettings.OUTBOUND_TAGLINE]: (domainSettings[AvailableSettings.OUTBOUND_TAGLINE] as string) || ''
      })
    } else {
      setFormObject({
        [AvailableSettings.OUTBOUND_TAGLINE_ENABLED]:
          (accountSettings[AvailableSettings.OUTBOUND_TAGLINE_ENABLED] as string) || SettingValue.DISABLED,
        [AvailableSettings.OUTBOUND_TAGLINE]: (accountSettings[AvailableSettings.OUTBOUND_TAGLINE] as string) || ''
      })
    }
    setShouldUpdateInitialForm(true)
  }, [accessTokenObject, domainSettings, accountSettings])

  useEffect(() => {
    setInitialFormState()
  }, [accountSettings, domainSettings, setInitialFormState])

  // set initialForm values
  useEffect(() => {
    if (shouldUpdateInitialForm) {
      setShouldUpdateInitialForm(false)
      resetInitialForm()
    }
  }, [shouldUpdateInitialForm, resetInitialForm])

  // update state on add/remove
  useEffect(() => {
    if (isUpdateAccountSettingsSuccess || isUpdateDomainSettingsSuccess) {
      if (accessTokenObject?.pdDomainId) {
        dispatch(getDomainSettings({ domainId: accessTokenObject?.pdDomainId, settings: SETTINGS_LIST }))
      } else {
        dispatch(getAccountSettings(SETTINGS_LIST))
      }
      dispatch(setHasPageChanges(false))
    }
  }, [dispatch, accessTokenObject, isUpdateAccountSettingsSuccess, isUpdateDomainSettingsSuccess])

  // unmount
  useEffect(
    () => () => {
      dispatch(resetAccountAndDomainSettings())
    },
    [dispatch]
  )

  const onCheckboxChange = useCallback(() => {
    const value =
      formObject[AvailableSettings.OUTBOUND_TAGLINE_ENABLED] === SettingValue.DISABLED
        ? SettingValue.ENABLED
        : SettingValue.DISABLED
    setFormObject({ ...formObject, outbound_tagline_enabled: value } as FormState)
    dispatch(setHasPageChanges(true))
  }, [dispatch, formObject])

  const onTextChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setFormObject({ ...formObject, outbound_tagline: e.target.value } as FormState)
      dispatch(setHasPageChanges(true))
    },
    [dispatch, formObject]
  )

  const onSave = useCallback(() => {
    if (accessTokenObject?.pdDomainId) {
      dispatch(
        updateDomainSettings({
          domainId: accessTokenObject.pdDomainId,
          settings: formObject
        })
      )
    } else {
      dispatch(updateAccountSettings({ settings: formObject }))
    }
  }, [dispatch, formObject, accessTokenObject])

  const onCancelConfirm = useCallback(() => {
    const block = getBlockTransition()
    if (block) {
      block(() => {
        setBlockTransition(undefined)
        setInitialFormState()
        setKey(makeUuid())
      })
    }
  }, [setInitialFormState])

  const charactersLeft = useMemo(() => MAX_CHAR - formObject[AvailableSettings.OUTBOUND_TAGLINE].length, [formObject])

  return useMemo(
    () => [
      {
        form: formObject,
        charactersLeft,
        isDirtyForm,
        isUpdateInProgress: isUpdateAccountSettingsPending || isUpdateDomainSettingsPending,
        key
      },
      {
        onCheckboxChange,
        onTextChange,
        onSave,
        onCancelConfirm,
        helpConfig: {
          isOpen: isHelpDialogOpened,
          onHelpClick,
          onCloseHelp
        }
      }
    ],
    [
      formObject,
      charactersLeft,
      isUpdateAccountSettingsPending,
      isUpdateDomainSettingsPending,
      key,
      onCheckboxChange,
      onTextChange,
      onSave,
      onCancelConfirm,
      isHelpDialogOpened,
      onHelpClick,
      onCloseHelp,
      isDirtyForm
    ]
  )
}
