const WEBUI_EVENTS = {
  // dashboard
  DASHBOARD_VIEW: 'Dashboard Pageview',

  // login
  LOGIN_PAGE_VIEW: 'Login Pageview',
  AUTOLOGIN_ACTION_PAGE_VIEW: 'AutoLogin Action Pageview',
  AZURE_SSO_PAGE_VIEW: 'Azure SSO Pageview',

  // message log
  MESSAGE_LOG_PAGE_VIEW: 'Message Log Pageview',
  MESSAGE_DETAIL_PAGE_VIEW: 'Message Detail Pageview',
  REPORT_INCORRECTLY_DELIVERED: 'Message Log Report as incorrectly delivered',
  REPORT_INCORRECTLY_BLOCKED: 'Message Log Report as incorrectly blocked',
  PROVIDE_ADDITIONAL_DETAILS: 'Message Log Provide Additional Details button',

  // reset password
  RESET_PASSWORD_PAGE_VIEW: 'Reset Password Action Pageview',

  // verify link account
  VERIFY_LINK_ACCOUNT_PAGE_VIEW: 'Verify Link Account Action Pageview',

  // support
  SUPPORT_PAGE_VIEW: 'Support Pageview',

  // settings
  SETTINGS_BULK_EDIT_PAGE_VIEW: 'Settings Bulk Edit Pageview',
  SETTINGS_CHANGE_PASSWORD_PAGE_VIEW: 'Settings Change Password Pageview',
  SETTINGS_LINKED_ACCOUNTS_PAGE_VIEW: 'Settings Linked Accounts Pageview',
  SETTINGS_QUARANTINE_NOTIFICATION_PAGE_VIEW: 'Settings Quarantine Notificaion Pageview',
  SETTINGS_SENDER_POLICIES_PAGE_VIEW: 'Settings Sender Policies Pageview',

  // License Compliance Page
  LC_PAGEVIEW: 'License Compliance Pageview',

  // Domains page
  DOMAINS_PAGE_VIEW: 'Domains Pageview',

  // Opt in event
  OPTED_IN_REACT: 'Bcc user optin enabled and switched to react'
} as const

export default WEBUI_EVENTS
