import util from 'util'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { useFormatMessage } from 'lib/localization'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isPending } from 'redux/toolkit/api'
import { getSenderPolicies, putBulkEditPolicies } from 'redux/features/settings/settingsSlice'
import { listToCsvString } from 'lib/listToCsvString'
import { setErrorSnackBar } from 'redux/features/app/appSlice'
import { TRACKING_EVENTS, trackMixpanelEvent } from 'lib/monitoring/monitoringService'
import routesConfig from 'lib/routesConfig'
import { isPolicyNameValid, isPolicyValid } from 'lib/validation'
import { Policy, PolicyStatus } from 'types/Settings'

export interface UseBulkEditLogic {
  policiesAreLoading: boolean
  saveEnabled: boolean
  rawBulkPolicies: string
  onCancel: () => void
  onSave: () => void
  onChange: (e: React.FormEvent<HTMLTextAreaElement>) => void
}

const BASE_I18N_KEY = 'ess.settings.bulk_edit'

const idMap: { [index: string]: string } = {}
const selectorFnToCsv = (accumulator: string, currrentPolicy: Policy) => {
  idMap[currrentPolicy.name] = currrentPolicy.id
  return `${accumulator}\n${currrentPolicy.name},${currrentPolicy.policy},${currrentPolicy.comment}`
}

export const useBulkEditLogic = (): UseBulkEditLogic => {
  const dispatch = useAppDispatch()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const { senderPolicies, policiesAreLoading } = useAppSelector(_store => ({
    senderPolicies: _store.settings.senderPolicies?.results,
    policiesAreLoading: isPending(_store.settings.getSenderPoliciesApiStatus)
  }))
  const [saveEnabled, setSaveEnabled] = useState(false)
  const [rawBulkPolicies, setRawBulkPolicies] = useState('')

  const rawBulkPoliciesToArray = useCallback(
    (rawPoliciesData: string) => {
      const policyArray: Policy[] = []

      // Remove the header line
      const rawPoliciesList = rawPoliciesData.split('\n').slice(1)

      rawPoliciesList.forEach((policyString: string, index: number) => {
        // Skip empty lines
        if (!policyString.trim().length) {
          return
        }

        // Split only first 2 commas, allowing 3rd field to have commas as part of the comment
        const arr = policyString.trim().split(',')
        const policyItem = arr.slice(0, 2)
        policyItem.push(arr.slice(2).join(','))

        if (policyItem.length < 2) {
          const msg = util.format(formatMessage('error_missing_policy'), index + 1)
          throw Error(`@literal:${msg}`)
        }

        const name = policyItem[0].trim()
        const policy = policyItem[1].trim().substr(0, 10)
        const comment = policyItem.length > 2 ? policyItem[2].trim().substr(0, 100) : ''

        if (!isPolicyNameValid(name)) {
          const msg = util.format(formatMessage('error_invalid_name'), index + 1)
          throw Error(`@literal:${msg}`)
        }

        if (!isPolicyValid(policy)) {
          const msg = util.format(formatMessage('error_invalid_policy'), index + 1)
          throw Error(`@literal:${msg}`)
        }

        policyArray.push({
          name,
          policy: policy.toLowerCase() as PolicyStatus,
          comment,
          id: idMap[name],
          modified: new Date().getTime()
        })
      })

      return policyArray
    },
    [formatMessage]
  )

  useEffect(() => {
    trackMixpanelEvent(TRACKING_EVENTS.WEBUI.SETTINGS_BULK_EDIT_PAGE_VIEW)
    dispatch(getSenderPolicies())
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setRawBulkPolicies(listToCsvString(senderPolicies || [], selectorFnToCsv, formatMessage('header')))
    // eslint-disable-next-line
  }, [senderPolicies])

  const onCancel = useCallback(() => {
    routesConfig.SETTINGS_SENDER_POLICY.goto()
  }, [])

  const onSave = useCallback(() => {
    try {
      const policyArray = rawBulkPoliciesToArray(rawBulkPolicies)
      dispatch(
        putBulkEditPolicies({
          policies: policyArray
        })
      )
      routesConfig.SETTINGS_SENDER_POLICY.goto()
    } catch (err) {
      dispatch(
        setErrorSnackBar({
          message: err.message
        })
      )
    }
    setSaveEnabled(false)
  }, [dispatch, rawBulkPoliciesToArray, rawBulkPolicies])

  const onChange = useCallback((e: React.FormEvent<HTMLTextAreaElement>) => {
    setRawBulkPolicies(e.currentTarget.value)
    setSaveEnabled(true)
  }, [])

  return useMemo(
    () => ({
      policiesAreLoading,
      saveEnabled,
      rawBulkPolicies,
      onCancel,
      onSave,
      onChange
    }),
    [policiesAreLoading, saveEnabled, rawBulkPolicies, onCancel, onSave, onChange]
  )
}
