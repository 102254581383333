import React, { useMemo } from 'react'
import { Button, DataTable, DataTableColumn, Grid, TextField, Typography } from '@barracuda-internal/bds-core'

import inboundSettingsStyles from 'components/pages/inboundSettings/inboundSettingsStyles'

import { Cell } from 'components/libs/grid/cell'
import { useFormatMessage } from 'lib/localization'

import WarningDialog from 'components/libs/dialog/warningDialog/WarningDialog'
import { CustomRblsParsed } from 'types/CustomRbls'
import { RBL_INPUT_NAMES, UseCustomRblsTableLogicProps, useCustomRblsTableLogic } from './useCustomRblsTableLogic'

const BASE_I18N_KEY = 'ess.inbound_settings.custom_rbls'
const BASE_DIALOG_I18N_KEY = 'ess.dialog'

const CustomRblsTable: React.FC<UseCustomRblsTableLogicProps> = (props: UseCustomRblsTableLogicProps) => {
  const inboundClasses = inboundSettingsStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatDialogMessage = useFormatMessage(BASE_DIALOG_I18N_KEY)
  const [state, eventHandlers] = useCustomRblsTableLogic(props)

  return useMemo(
    () => (
      <Grid data-testid="ip-address-table-grid">
        <DataTable
          sortable={{
            allowUnsort: false,
            mode: 'single'
          }}
          sort={state.sort}
          onSortChange={eventHandlers.handleSortChange}
          data={state.tableData}
        >
          <DataTableColumn
            sortable
            field={RBL_INPUT_NAMES.ZONE}
            title={formatMessage('zone')}
            cell={({ dataItem }: { dataItem: CustomRblsParsed }) => (
              <Cell>
                {!dataItem.id && (
                  <TextField
                    fullWidth
                    defaultValue={state.formState.zone}
                    inputRef={state.form.fields.zone.ref}
                    onChange={eventHandlers.handleOnInputChange}
                    name={RBL_INPUT_NAMES.ZONE}
                    error={!!state.error}
                    helperText={state.error ? formatMessage(state.error) : ''}
                    inputProps={{ style: { padding: 8 } }}
                    data-testid="new-zone-field"
                  />
                )}
                {dataItem.id && (
                  <Typography variant="body2" data-testid="zone-field">
                    {dataItem.zone}
                  </Typography>
                )}
              </Cell>
            )}
          />
          <DataTableColumn
            sortable
            field={RBL_INPUT_NAMES.COMMENT}
            title={formatMessage('comment')}
            cell={({ dataItem }: { dataItem: CustomRblsParsed }) => (
              <Cell data-testid="comment-cell" className={inboundClasses.smallCell}>
                {!dataItem.id && (
                  <TextField
                    fullWidth
                    defaultValue={state.formState.comment}
                    inputRef={state.form.fields.comment.ref}
                    onChange={eventHandlers.handleOnInputChange}
                    name={RBL_INPUT_NAMES.COMMENT}
                    inputProps={{ style: { padding: 8 } }}
                    data-testid="new-comment-field"
                  />
                )}
                {dataItem.id && (
                  <Typography variant="body2" data-testid="comment-field">
                    {dataItem.comment}
                  </Typography>
                )}
              </Cell>
            )}
          />
          <DataTableColumn
            field="actions"
            title={formatMessage('actions')}
            cell={({ dataItem }: { dataItem: CustomRblsParsed }) => (
              <Cell data-testid="action-cell" className={inboundClasses.smallCell}>
                {!dataItem.id && (
                  <Button
                    color="primary"
                    onClick={() => {
                      eventHandlers.onAdd()
                    }}
                    variant="text"
                    data-testid="add-button"
                  >
                    {formatMessage('add')}
                  </Button>
                )}
                {dataItem.id && (
                  <Button
                    color="primary"
                    onClick={() => {
                      eventHandlers.onRemove(dataItem.id)
                    }}
                    variant="text"
                    data-testid="remove-button"
                  >
                    {formatMessage('remove')}
                  </Button>
                )}
              </Cell>
            )}
          />
        </DataTable>
        {!!state.idToRemove && (
          <WarningDialog
            title={formatDialogMessage('confirm_removal')}
            onConfirm={eventHandlers.onConfirmRemove}
            onCancel={eventHandlers.onConfirmCancel}
          />
        )}
      </Grid>
    ),
    [formatMessage, formatDialogMessage, inboundClasses, state, eventHandlers]
  )
}

export default CustomRblsTable
