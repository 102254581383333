import React, { useMemo } from 'react'

import {
  Button,
  DataTable,
  DataTableColumn,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@barracuda-internal/bds-core'

import outboundSettingsStyles from 'components/pages/outboundSettings/outboundSettingsStyles'
import { useFormatMessage } from 'lib/localization'
import { PredefinedFilterExceptions, AVAILABLE_PREDEFINED_FILTER_EXCEPTION_TYPE } from 'types/Settings'
import { Cell } from 'components/libs/grid/cell'
import WarningDialog from 'components/libs/dialog/warningDialog/WarningDialog'
import {
  usePredefinedFilterExceptionsTableLogic,
  UsePredefinedFilterExceptionsTableLogicProps
} from 'components/pages/outboundSettings/contentPolicies/usePredefinedFilterExceptionsTableLogic'

const BASE_I18N_KEY = 'ess.outbound_settings.content_policies'
const BASE_INBOUND_I18N_KEY = 'ess.inbound_settings'
const DIALOG_I18N_KEY = 'ess.dialog'

const PredefinedFilterExceptionsTable = (props: UsePredefinedFilterExceptionsTableLogicProps) => {
  const outboundClasses = outboundSettingsStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatDialogMessage = useFormatMessage(DIALOG_I18N_KEY)
  const formatInboundMessage = useFormatMessage(BASE_INBOUND_I18N_KEY)

  const [state, eventHandlers] = usePredefinedFilterExceptionsTableLogic(props)

  return useMemo(
    () => (
      <Grid container>
        <Grid container>
          <Typography variant="h4">{formatMessage('predefined_filter_exceptions')}</Typography>
          <Button
            className={outboundClasses.bulkEdit}
            onClick={eventHandlers.onBulkEdit}
            variant="contained"
            size="small"
            data-testid="bulk-button"
          >
            {formatInboundMessage('bulk_edit')}
          </Button>
        </Grid>
        <Grid container>
          <Typography variant="body2">{formatMessage('predefined_filter_exceptions_description')}</Typography>
        </Grid>
        <DataTable
          sortable={{
            allowUnsort: false,
            mode: 'single'
          }}
          sort={state.sort}
          onSortChange={eventHandlers.handleSortChange}
          data={state.tableData}
        >
          <DataTableColumn
            sortable
            field="pattern"
            title={formatMessage('pattern')}
            width={300}
            cell={({ dataItem }: { dataItem: PredefinedFilterExceptions }) => (
              <Cell data-testid="pattern-cell" className={outboundClasses.smallCell}>
                {!dataItem.id && (
                  <TextField
                    fullWidth
                    name="pattern"
                    defaultValue={state.formState.pattern}
                    inputRef={state.form.fields.pattern.ref}
                    onChange={eventHandlers.handleOnInputChange}
                    inputProps={{ style: { padding: 8 } }}
                    data-testid="new-pattern-field"
                    error={!!state.error}
                    helperText={state.error ? formatMessage(state.error) : ''}
                  />
                )}
                {dataItem.id && (
                  <Typography variant="body2" data-testid="pattern-field">
                    {dataItem.pattern}
                  </Typography>
                )}
              </Cell>
            )}
          />
          <DataTableColumn
            sortable
            field="type"
            title={formatMessage('type')}
            cell={({ dataItem }: { dataItem: PredefinedFilterExceptions }) => (
              <Cell>
                {dataItem.id === '' && (
                  <Select
                    variant="outlined"
                    className={outboundClasses.mediumDropdown}
                    name="type"
                    defaultValue={state.formState.type}
                    onChange={eventHandlers.handleOnSelectChange}
                    SelectDisplayProps={{ style: { padding: 8 } }}
                    data-testid="new-type-field"
                  >
                    {AVAILABLE_PREDEFINED_FILTER_EXCEPTION_TYPE.map(type => (
                      <MenuItem key={type} value={type}>
                        {formatMessage(type)}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                {dataItem.id !== '' && (
                  <Typography variant="body2" data-testid="type-field" className={outboundClasses.flexWrapper}>
                    {formatMessage(dataItem.type)}
                  </Typography>
                )}
              </Cell>
            )}
          />
          <DataTableColumn
            field="actions"
            title={formatMessage('actions')}
            width={200}
            cell={({ dataItem }: { dataItem: PredefinedFilterExceptions }) => (
              <Cell data-testid="action-cell" className={outboundClasses.smallCell}>
                {!dataItem.id && (
                  <Button
                    color="primary"
                    onClick={() => {
                      eventHandlers.onAdd()
                    }}
                    variant="text"
                    data-testid="add-button"
                  >
                    {formatMessage('add')}
                  </Button>
                )}
                {dataItem.id && (
                  <Button
                    color="primary"
                    onClick={() => {
                      eventHandlers.onRemove(dataItem.id)
                    }}
                    variant="text"
                    data-testid="remove-button"
                  >
                    {formatMessage('remove')}
                  </Button>
                )}
              </Cell>
            )}
          />
        </DataTable>
        {state.isConfirmDialogOpen && (
          <WarningDialog
            title={formatDialogMessage('confirm_removal')}
            onCancel={eventHandlers.onRemoveCancel}
            onConfirm={eventHandlers.onRemoveConfirm}
          />
        )}
      </Grid>
    ),
    [outboundClasses, formatMessage, formatDialogMessage, formatInboundMessage, state, eventHandlers]
  )
}

export default PredefinedFilterExceptionsTable
