import { makeStyles } from '@barracuda-internal/bds-core'

export default makeStyles(theme => ({
  sourceWrapper: {
    backgroundColor: theme.palette.common.white,
    height: 'inherit',
    padding: theme.spacing(1),
    width: 'inherit'
  },
  sourceText: {
    fontFamily: 'monospace',
    fontSize: '0.75rem',
    lineHeight: 'normal',
    whiteSpace: 'pre-wrap'
  }
}))
