/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react'
import { useAccountSwitcherLogic } from 'components/libs/accountSwitcher/useAccountSwitcherLogic'

import UnifiedAccountSwitcher from 'components/pages/reports/reportList/customizedBDSComponents/UnifiedAccountSwitcher'

import appConfig from 'config/appConfig'
import { useFormatMessage } from 'lib/localization'

import MaskLoader from 'components/libs/contentLoader/MaskLoader'

const BASE_I18N_KEY = 'ess.account_switcher'

const AccountSwitcher = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [state, eventHandlers] = useAccountSwitcherLogic()

  return useMemo(() => {
    if (!appConfig.APP.IS_ADMIN || !state.accounts || state.accounts?.length < 2) {
      return null
    }

    return (
      <>
        <MaskLoader
          open={state.accountSwitchingInProgress}
          fullPage
          message={`${formatMessage('switching_account', { account: state.selectedAccount?.name || '' })}`}
        />
        <UnifiedAccountSwitcher
          accounts={state.accounts}
          onSelectAccountCallback={eventHandlers.onSelectNewAccount}
          initAccount={state.initCCAccountId}
          buttonText={formatMessage('cancel')}
        />
      </>
    )
  }, [eventHandlers, state, formatMessage])
}

export default AccountSwitcher
