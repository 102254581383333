import { useState, useMemo, useEffect, useCallback, Dispatch, SetStateAction } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { AvailableSettings, IpAddressEntry, SettingValue, SettingsObject } from 'types/Settings'

import {
  getAccountSettings,
  getDomainSettings,
  resetAccountAndDomainSettings,
  updateAccountSettings,
  updateDomainSettings
} from 'redux/features/settings/settingsSlice'
import { isSuccess } from 'redux/toolkit/api'
import routesConfig from 'lib/routesConfig'
import { useDirtyFormCheck } from 'lib/useDirtyFormCheck'
import { useInboundSettingsRights } from 'components/libs/userRights/pages/useInboundSettingsRights'

export interface State {
  canDisableAccountRateControl: boolean
  settings: SettingsObject
  rateControlExemptions: IpAddressEntry[]
  hasPageChanges: boolean
}

export interface EventHandlers {
  onFormChange: (name: AvailableSettings) => (NewValue: any) => void
  onInputFormChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSave: () => void
  onCancelConfirm: () => void
  onAddItem: (item: IpAddressEntry) => void
  onRemoveItem: (id: string) => void
  onBulkEdit: () => void
  helpConfig: {
    isOpen: boolean
    onHelpClick: () => void
    onCloseHelp: () => void
  }
  setIsIpAddressTableDirty: Dispatch<SetStateAction<boolean>>
}

export type UseRateControlLogic = [State, EventHandlers]

const SETTINGS_LIST = [
  AvailableSettings.RATE_CONTROL_EXEMPTIONS,
  AvailableSettings.RATE_CONTROL,
  AvailableSettings.DISABLE_ACCOUNT_RATE_CONTROL
]

export const useRateControlLogic = (): UseRateControlLogic => {
  const [formObject, setFormObject] = useState<SettingsObject>({
    [AvailableSettings.RATE_CONTROL]: '',
    [AvailableSettings.DISABLE_ACCOUNT_RATE_CONTROL]: ''
  })
  const dispatch = useAppDispatch()
  const [isHelpDialogOpened, setIsHelpDialogOpened] = useState<boolean>(false)

  const {
    accessTokenObject,
    isGetAccountSettingsSuccess,
    isGetDomainSettingsSuccess,
    isUpdateAccountSettingsSuccess,
    isUpdateDomainSettingsSuccess,
    accountSettings,
    domainSettings
  } = useAppSelector(_stores => ({
    accessTokenObject: _stores.auth.accessTokenObject,
    isGetAccountSettingsSuccess: isSuccess(_stores.settings.getAccountSettingsApiStatus),
    isGetDomainSettingsSuccess: isSuccess(_stores.settings.getDomainSettingsApiStatus),
    isUpdateAccountSettingsSuccess: isSuccess(_stores.settings.updateAccountSettingsApiStatus),
    isUpdateDomainSettingsSuccess: isSuccess(_stores.settings.updateDomainSettingsApiStatus),
    accountSettings: _stores.settings.accountSettings,
    domainSettings: _stores.settings.domainSettings
  }))
  const { canDisableAccountRateControl } = useInboundSettingsRights()

  const [isDirtyForm, resetInitialForm] = useDirtyFormCheck([formObject])
  const [shouldUpdateInitialForm, setShouldUpdateInitialForm] = useState<boolean>(false)
  const [isIpAddressTableDirty, setIsIpAddressTableDirty] = useState<boolean>(false)

  // init
  useEffect(() => {
    if (accessTokenObject?.pdDomainId) {
      dispatch(getDomainSettings({ domainId: accessTokenObject?.pdDomainId, settings: SETTINGS_LIST }))
    } else {
      dispatch(getAccountSettings(SETTINGS_LIST))
    }
    // eslint-disable-next-line
  }, [])

  // unmount
  useEffect(
    () => () => {
      dispatch(resetAccountAndDomainSettings())
    },
    [dispatch]
  )

  const onHelpClick = useCallback(() => {
    setIsHelpDialogOpened(true)
  }, [])

  const onCloseHelp = useCallback(() => {
    setIsHelpDialogOpened(false)
  }, [])

  // set initialForm values
  useEffect(() => {
    if (shouldUpdateInitialForm) {
      setShouldUpdateInitialForm(false)
      resetInitialForm()
    }
  }, [shouldUpdateInitialForm, resetInitialForm])

  useEffect(() => {
    if (isGetAccountSettingsSuccess || isGetDomainSettingsSuccess) {
      if (accessTokenObject?.pdDomainId) {
        setFormObject({
          [AvailableSettings.RATE_CONTROL]: domainSettings[AvailableSettings.RATE_CONTROL] || '1000',
          [AvailableSettings.DISABLE_ACCOUNT_RATE_CONTROL]:
            domainSettings[AvailableSettings.DISABLE_ACCOUNT_RATE_CONTROL] || SettingValue.DISABLED
        })
      } else {
        setFormObject({
          [AvailableSettings.RATE_CONTROL]: accountSettings[AvailableSettings.RATE_CONTROL] || '1000',
          [AvailableSettings.DISABLE_ACCOUNT_RATE_CONTROL]:
            accountSettings[AvailableSettings.DISABLE_ACCOUNT_RATE_CONTROL] || SettingValue.DISABLED
        })
      }
      setShouldUpdateInitialForm(true)
    }
  }, [isGetAccountSettingsSuccess, isGetDomainSettingsSuccess, accountSettings, domainSettings, accessTokenObject])

  // update state on add/remove
  useEffect(() => {
    if (isUpdateAccountSettingsSuccess || isUpdateDomainSettingsSuccess) {
      if (accessTokenObject?.pdDomainId) {
        dispatch(getDomainSettings({ domainId: accessTokenObject?.pdDomainId, settings: SETTINGS_LIST }))
      } else {
        dispatch(getAccountSettings(SETTINGS_LIST))
      }
      setShouldUpdateInitialForm(true)
    }
  }, [dispatch, accessTokenObject, isUpdateAccountSettingsSuccess, isUpdateDomainSettingsSuccess])

  // unmount
  useEffect(
    () => () => {
      dispatch(resetAccountAndDomainSettings())
    },
    [dispatch]
  )

  const rateControlExemptions = useMemo(() => {
    const settings = accessTokenObject?.pdDomainId ? domainSettings : accountSettings
    return settings.rate_control_exemptions
      ? (JSON.parse(settings.rate_control_exemptions as string) as IpAddressEntry[])
      : []
  }, [accessTokenObject, domainSettings, accountSettings])

  const onFormChange = useCallback(
    (name: AvailableSettings) => (newValue: any) => {
      if (name === AvailableSettings.DISABLE_ACCOUNT_RATE_CONTROL) {
        const value = formObject.disable_account_rate_control === '1' ? '0' : '1'
        setFormObject({ ...formObject, [name]: value } as SettingsObject)
      } else {
        setFormObject({ ...formObject, [name]: newValue } as SettingsObject)
      }
    },
    [formObject]
  )

  const onInputFormChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target
      setFormObject({ ...formObject, [name]: value } as SettingsObject)
    },
    [formObject]
  )

  const onSave = useCallback(() => {
    if (accessTokenObject?.pdDomainId) {
      dispatch(
        updateDomainSettings({
          domainId: accessTokenObject.pdDomainId,
          settings: formObject
        })
      )
    } else {
      dispatch(updateAccountSettings({ settings: formObject }))
    }
  }, [dispatch, formObject, accessTokenObject])

  const updateRateControlExemptions = useCallback(
    (exemptions: IpAddressEntry[]) => {
      if (accessTokenObject?.pdDomainId) {
        dispatch(
          updateDomainSettings({
            domainId: accessTokenObject.pdDomainId,
            settings: { rate_control_exemptions: exemptions }
          })
        )
      } else {
        dispatch(updateAccountSettings({ settings: { rate_control_exemptions: exemptions } }))
      }
    },
    [dispatch, accessTokenObject]
  )

  const onAddItem = useCallback(
    (item: IpAddressEntry) => {
      // eslint-disable-next-line no-param-reassign
      item.policy = 'exempt'
      const exemptions = [...rateControlExemptions, item]
      updateRateControlExemptions(exemptions)
    },
    [rateControlExemptions, updateRateControlExemptions]
  )

  const onRemoveItem = useCallback(
    (id: string) => {
      const exemptions = rateControlExemptions.filter(entry => entry.id !== id)
      updateRateControlExemptions(exemptions)
    },
    [rateControlExemptions, updateRateControlExemptions]
  )

  const onCancelConfirm = useCallback(() => {
    if (accessTokenObject?.pdDomainId) {
      setFormObject(domainSettings)
    } else {
      setFormObject(accountSettings)
    }
  }, [accountSettings, domainSettings, accessTokenObject])

  const onBulkEdit = useCallback(() => {
    routesConfig.INBOUND_SETTINGS_RATE_CONTROL_BULK_EDIT.goto()
  }, [])

  return useMemo(
    () => [
      {
        canDisableAccountRateControl,
        settings: formObject,
        rateControlExemptions,
        hasPageChanges: isDirtyForm || isIpAddressTableDirty
      },
      {
        onFormChange,
        onInputFormChange,
        onSave,
        onCancelConfirm,
        onAddItem,
        onRemoveItem,
        onBulkEdit,
        helpConfig: {
          isOpen: isHelpDialogOpened,
          onHelpClick,
          onCloseHelp
        },
        setIsIpAddressTableDirty
      }
    ],
    [
      canDisableAccountRateControl,
      formObject,
      rateControlExemptions,
      isDirtyForm,
      isIpAddressTableDirty,
      onFormChange,
      onInputFormChange,
      onSave,
      onCancelConfirm,
      onAddItem,
      onRemoveItem,
      onBulkEdit,
      isHelpDialogOpened,
      onHelpClick,
      onCloseHelp
    ]
  )
}
