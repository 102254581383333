import { BaseSyntheticEvent, useCallback, useMemo, useState } from 'react'

export type Account = {
  id: string
  name: string
}

export interface UnifiedAccountSwitcherLogicProps {
  accounts: Account[]
  initAccount?: string
  onSelectAccountCallback: (newAccount: Account) => void
}

export enum AccountSwitcherStates {
  minimized,
  opened
}

export interface State {
  activeAccount?: Account
  accountSwitcherState: AccountSwitcherStates
  search: string
}

export interface EventHandlers {
  onSelectNewAccount: (newAccountId: string) => void
  onToggleSwitcherState: () => void
  onSearch: (e: BaseSyntheticEvent) => void
}

export const useUnifiedAccountSwitcherLogic = ({
  accounts,
  initAccount,
  onSelectAccountCallback
}: UnifiedAccountSwitcherLogicProps): [State, EventHandlers] => {
  const [accountSwitcherState, setAccountSwitcherState] = useState<AccountSwitcherStates>(
    AccountSwitcherStates.minimized
  )
  const [search, setSearch] = useState<string>('')

  const activeAccount = useMemo(() => accounts?.find(account => account.id === initAccount), [accounts, initAccount])

  const onSelectNewAccount = useCallback(
    (newAccountId: string) => {
      const newAccount = accounts?.find(account => account.id === newAccountId)

      if (newAccount && activeAccount?.id !== newAccount.id) {
        setAccountSwitcherState(AccountSwitcherStates.minimized)
        onSelectAccountCallback(newAccount)
      }
    },
    [accounts, activeAccount, onSelectAccountCallback]
  )

  const onToggleSwitcherState = useCallback(() => {
    setSearch('')
    setAccountSwitcherState(
      accountSwitcherState === AccountSwitcherStates.minimized
        ? AccountSwitcherStates.opened
        : AccountSwitcherStates.minimized
    )
  }, [accountSwitcherState])

  const onSearch = useCallback((e: BaseSyntheticEvent) => {
    setSearch(e.target.value)
  }, [])

  return useMemo(
    () => [
      {
        activeAccount,
        accountSwitcherState,
        search
      },
      {
        onSelectNewAccount,
        onToggleSwitcherState,
        onSearch
      }
    ],
    [activeAccount, accountSwitcherState, onSelectNewAccount, onToggleSwitcherState, search, onSearch]
  )
}
