import { colors, makeStyles } from '@barracuda-internal/bds-core'

export type StyleProps =
  | {
      disabled?: boolean
      disablePositioning?: boolean
      indent?: boolean
    }
  | undefined

const userContentWrapperStyles = makeStyles(theme => ({
  pageContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  pageTitle: {
    marginTop: theme.spacing(1),
    height: theme.spacing(7)
  },
  helpIcon: {
    cursor: 'pointer',
    marginLeft: theme.spacing(0.5),
    width: theme.spacing(2.5),
    marginTop: theme.spacing(1),
    color: colors.blue600
  },
  helperText: {
    fontWeight: 400,
    fontSize: 14,
    marginTop: -theme.spacing(0.5)
  },
  cancelButton: {
    marginRight: theme.spacing(2)
  },
  formSpacer: {
    height: theme.spacing(2)
  },
  formSpacerHalf: {
    height: theme.spacing(1)
  },
  searchField: {
    backgroundColor: colors.white,
    width: '100%'
  },
  blockTitle: {
    display: 'inline-flex',
    alignItems: 'center',

    '&.collapsible': {
      cursor: 'pointer'
    }
  }
}))

export default userContentWrapperStyles
