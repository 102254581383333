import React, { useMemo } from 'react'

import { Button, Grid } from '@barracuda-internal/bds-core'
import inboundSettingsStyles from 'components/pages/inboundSettings/inboundSettingsStyles'
import { useFormatMessage } from 'lib/localization'
import { useRateControlLogic } from 'components/pages/inboundSettings/rateControl/useRateControlLogic'
import InboundRateControl from 'components/pages/support/help/inboundRateControl/InboundRateControl'
import { GROUP_TITLE, TOPICS } from 'components/pages/support/config'
import IpAddressTable from 'components/libs/ipAddressTable/IpAddressTable'
import { AvailableSettings, SettingValue } from 'types/Settings'
import styles from 'components/pages/inboundSettings/rateControl/rateControlStyles'
import {
  PageTitle,
  PageSubtitle,
  FormSpacer,
  FormRow,
  InlineRadioGroup,
  InlineInputField
} from 'components/libs/layout/pages/pageLayout'
import { SettingsPageContainer } from 'components/libs/layout/pages/SettingsPageContainer'
import { useSettingsStateLogic } from 'components/libs/useSettingsState'
import routesConfig from 'lib/routesConfig'

const BASE_I18N_KEY = 'ess.inbound_settings.rate_control'
const BASE_INBOUND_I18N_KEY = 'ess.inbound_settings'

const RateControl: React.FC = () => {
  const inboundClasses = inboundSettingsStyles()
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatInboundMessage = useFormatMessage(BASE_INBOUND_I18N_KEY)
  const [state, eventHandlers] = useRateControlLogic()
  const [UseGetSettingsStateLogic] = useSettingsStateLogic()

  return useMemo(
    () => (
      <SettingsPageContainer dirtyCheck isDirtyForm={state.hasPageChanges}>
        <PageTitle
          title={formatMessage('title')}
          onOpenSupport={eventHandlers.helpConfig.onHelpClick}
          onCancel={() => routesConfig.INBOUND_SETTINGS_RATE_CONTROL.goto()}
          onSubmit={eventHandlers.onSave}
          submitInProgress={UseGetSettingsStateLogic.isLoading}
          isDisabledSubmit={!state.hasPageChanges}
          help={{
            content: InboundRateControl,
            onClose: eventHandlers.helpConfig.onCloseHelp,
            title: TOPICS.rateControl,
            group: GROUP_TITLE.inboundSettings,
            isHelpOpen: eventHandlers.helpConfig.isOpen
          }}
        />
        <Grid container>
          <FormSpacer />
          <PageSubtitle>{formatMessage('rate_control_exemptions')}</PageSubtitle>
          <Grid container direction="row">
            <Button
              className={inboundClasses.bulkEdit}
              onClick={eventHandlers.onBulkEdit}
              variant="contained"
              size="small"
              data-testid="bulk-button"
            >
              {formatInboundMessage('bulk_edit')}
            </Button>
            <IpAddressTable
              data={state.rateControlExemptions}
              defaultNetmask
              onAddItem={eventHandlers.onAddItem}
              onRemoveItem={eventHandlers.onRemoveItem}
              delegateDirtyFormState={eventHandlers.setIsIpAddressTableDirty}
            />
          </Grid>
          {state.canDisableAccountRateControl && (
            <>
              <FormSpacer />
              <PageSubtitle>{formatMessage('internal_rate_control')}</PageSubtitle>
              <FormSpacer />
              <FormRow rowTitle={formatMessage('exempt_rate_control')}>
                <InlineRadioGroup
                  name={AvailableSettings.DISABLE_ACCOUNT_RATE_CONTROL}
                  value={state.settings[AvailableSettings.DISABLE_ACCOUNT_RATE_CONTROL]}
                  values={[SettingValue.ENABLED, SettingValue.DISABLED]}
                  onChange={eventHandlers.onFormChange(AvailableSettings.DISABLE_ACCOUNT_RATE_CONTROL)}
                  disabled={UseGetSettingsStateLogic.isLoading}
                  labels={[formatMessage('yes'), formatMessage('no')]}
                />
              </FormRow>
              <FormSpacer />
            </>
          )}
          <>
            <FormSpacer />
            <PageSubtitle>{formatMessage('rate_control')}</PageSubtitle>
            <FormSpacer />
            <FormRow rowTitle={formatMessage('maximum_messages')}>
              <InlineInputField
                inputClassName={classes.smallInput}
                name={AvailableSettings.RATE_CONTROL}
                onChange={eventHandlers.onInputFormChange}
                value={state.settings[AvailableSettings.RATE_CONTROL]}
                helperText={formatMessage('default_value')}
              />
            </FormRow>
          </>
        </Grid>
      </SettingsPageContainer>
    ),
    [classes, inboundClasses, formatMessage, formatInboundMessage, state, eventHandlers, UseGetSettingsStateLogic]
  )
}

export default RateControl
