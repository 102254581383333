/* eslint-disable quotes */
export default {
  support: 'Support',
  previous_page: 'Return to previous page',
  print: 'Printable view',
  search_box_title: 'Search Support Topics',
  enter_keywords: 'Enter keywords',
  search_box_example: 'Example: adding mail server, inbound settings',
  no_search_results: 'Your search query returned no results.',
  documentation: 'Documentation',
  documentation_content:
    "All administrator's documentation for the Barracuda Email Gateway Defense, including the Getting Started guide, can be found in Barracuda TechLibrary linked here:",
  support_access: 'Support Access',
  support_access_content: 'Support Access gives Barracuda Networks the ability to remotely view your account.',
  documentation_link1: 'Barracuda Campus',
  documentation_link2: 'Release Notes',
  linked_accounts: 'Linked Accounts',
  sender_policy: 'Sender Policy',
  quarantine_notification: 'Quarantine Notification',
  message_log: 'Message Log',
  search_result: 'Search Result',
  cpl_product_name: 'Barracuda Cloud Protection Layer',
  no_cpl_product_name: 'Email Gateway Defense',
  status: 'Status',
  enabled: 'Enabled',
  disabled: 'Disabled',
  enable_support_access: 'Enable Support Access',
  disable_support_access: 'Disable Support Access',
  cancel: 'Cancel',
  message_access: 'Partner Access to Messages',
  message_access_content:
    'Partner access to messages provides Barracuda Networks Partners the ability to view the content (message body) of emails for accounts that they manage.',
  enable_message_access: 'Enable Message Body Access',
  disable_message_access: 'Disable Message Body Access',
  account_configuration: 'Save and Apply Account Configuration Settings',
  account_configuration_content:
    'Save your current global account configuration settings and apply them at a later time to this or other accounts.',
  save: 'Save',
  apply: 'Apply',
  topics: {
    domains: 'Domains',
    inbound_settings: 'Inbound Settings',
    outbound_settings: 'Outbound Settings',
    user_topics: 'Users',
    end_user: 'EndUser',
    self_service_portal_topics: 'Self Service Portal',
    general_topics: 'General',
    report_topics: 'Reports',
    account_management_topics: 'Account Management',

    seen_recipients: 'Seen Recipients',
    dashboard: 'Dashboard',
    staying_in_compliance: 'Staying in Compliance',
    message_log: 'Message Log',
    atp_log: 'ATP Log',
    audit_log: 'Audit Log',
    domain_verification: 'Domain Verification',
    mail_server_test: 'Mail Server Test',
    domain_manager: 'Domain Manager',
    domain_settings: 'Domain Settings',
    recipients_list: 'Recipients List',
    anti_spam_antivirus: 'Anti-Spam/Antivirus',
    custom_rbls: 'Custom RBLs',
    ip_address_policies: 'IP Address Policies',
    regional_policies: 'Regional Policies',
    recipient_policies: 'Recipient Policies',
    sender_policies: 'Sender Policies',
    sender_authentication: 'Sender Authentication',
    content_policies: 'Content Policies',
    rate_control: 'Rate Control',
    anti_phishing: 'Anti Phishing',
    atp_settings: 'ATP Settings',
    sender_ip_address_ranges: 'Sender IP Address Ranges',
    anti_virus: 'Anti-Virus',
    tagline_footer: 'Tagline/Footer',
    dlp_encryption: 'DLP/Encryption',
    notifications: 'Notifications',
    outbound_quarantine: 'Outbound Quarantine',
    default_policy: 'Default Policy',
    add_update_users: 'Add/Update Users',
    email_continuity: 'Email Continuity',
    quarantine_notification: 'Quarantine Notification',
    users_list: 'Users List',
    linked_accounts: 'Linked Accounts',
    sender_policy: 'Sender Policy',
    specify_primary_email_domain: 'Step 1 - Specify Primary Email Domain',
    specify_email_servers: 'Step 2 - Specify Email Servers',
    configure_settings: 'Step 3 - Configure Settings',
    configure_outbound_settings_for_office_365: 'Step 4 - Configure Outbound Settings for Office365',
    route_email_through_barracuda_networks: 'Step 5 - Route Email Through Barracuda Networks',
    regular_expressions: 'Regular Expressions',
    generate_report_this_domain: 'Generate Report - This Domain',
    generate_report_all_domains: 'Generate Report - All Domains',
    syslog_integration: 'Syslog Integration',
    configure_mx_records: 'Configure MX Records',
    machine_learning: 'Machine Learning'
  },
  dialog: {
    support_access: {
      following_permission:
        'Enabling support access will give Barracuda Networks the following permissions for your account',
      make_changes: 'Make Changes',
      make_change_text: 'Barracuda Networks can make changes to this account on your behalf',
      email_information: 'View Email Information',
      email_information_text: 'Barracuda Networks can view the headers of messages in this account',
      email_content: 'View Email Content',
      email_content_text: 'Barracuda Networks can view the body of messages in this account'
    },
    message_access: {
      following_permission: 'Enabling Partner Access to Messages allows your partner/MSP to:',
      email_content: 'View Email Content',
      email_content_text: 'Message body will be available',
      email_attachment: 'View Email Attachments',
      email_attachment_text: 'Able to view email attachments in this account'
    },
    account_configuration: {
      save_config_title: 'Save Configuration Settings',
      save_config_content_saved: 'Your current global account configuration settings have been saved.',
      save_config_content_apply:
        'Save this configuration settings ID to apply these settings to this or other accounts.',
      save_config_content_valid: 'Please note that this ID is valid for 30 days.',
      save_config_content_id: 'Configuration settings ID:',
      cancel: 'Cancel',
      copy_to_clipboard: 'Copy to Clipboard',
      copied_to_clipboard: 'Token Copied!',
      apply_config_title: 'Apply Configuration Settings',
      apply_config_content_recommend:
        'You are about to overwrite the current configuration. Barracuda Networks recommends making a backup of the current configuration before continuing. ',
      apply_config_content_more: 'More Information',
      apply_config_content_enter: 'Enter the ID for the Configuration Settings you wish to apply to this account:'
    }
  }
}
/* eslint-enable quotes */
