import { useState, useMemo, useEffect, useCallback } from 'react'
import { v4 as makeUuid } from 'uuid'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import {
  AvailableSettings,
  DEFAULT_SETTINGS_OBJECT,
  EmailCategories,
  SettingsObject,
  emailCategories
} from 'types/Settings'

import {
  getAccountSettings,
  getDomainSettings,
  resetAccountAndDomainSettings,
  updateAccountSettings,
  updateDomainSettings
} from 'redux/features/settings/settingsSlice'
import { isSuccess } from 'redux/toolkit/api'
import { filterSettings } from 'components/libs/settings/settings'
import { useDirtyFormCheck } from 'lib/useDirtyFormCheck'
import { useInboundSettingsRights } from 'components/libs/userRights/pages/useInboundSettingsRights'
import { getBlockTransition, setBlockTransition } from 'lib/routes'

interface ScoringObject {
  block: number
  quarantine: number
}

export interface State {
  canEmailCategorization: boolean
  canQuarantineSettings: boolean
  canSendBrtsEvidence: boolean
  settings: SettingsObject
  scoringObject: ScoringObject
  key: string
  tableData: {
    total: number
    data: EmailCategories[]
  }
  isWarningDialogOpen: boolean
  isDirtyForm: boolean
}

export interface EventHandlers {
  onFormChange: (name: AvailableSettings) => (NewValue: any) => void
  onScoringChange: (name: ScoringType, value: number) => void
  onActionChange: (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => void
  onSave: () => void
  onCancelConfirm: () => void
  onCancelWarningDialog: () => void
  onConfirmWarningDialog: () => void
  helpConfig: {
    isOpen: boolean
    onHelpClick: () => void
    onCloseHelp: () => void
  }
}

export type UseReputationLogic = [State, EventHandlers]

const SETTINGS_LIST = [
  AvailableSettings.BRBL,
  AvailableSettings.VSCAN,
  AvailableSettings.BRTS,
  AvailableSettings.FASTSPAM_ENABLED,
  AvailableSettings.FASTSPAM_SCORES,
  AvailableSettings.BULK_EMAIL_DETECTION
]

export enum ScoringType {
  QUARANTINE = 'quarantine',
  BLOCK = 'block'
}

export const useReputationLogic = (): UseReputationLogic => {
  const dispatch = useAppDispatch()
  const [formObject, setFormObject] = useState<SettingsObject>(DEFAULT_SETTINGS_OBJECT)
  const [scoringObject, setScoringObject] = useState<ScoringObject>({ block: 0.0, quarantine: 0.0 })
  const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false)
  const [isHelpDialogOpened, setIsHelpDialogOpened] = useState<boolean>(false)
  const { canQuarantineSettings, canEmailCategorization, canSendBrtsEvidence } = useInboundSettingsRights()

  const {
    accessTokenObject,
    isUpdateAccountSettingsSuccess,
    isUpdateDomainSettingsSuccess,
    accountSettings,
    domainSettings
  } = useAppSelector(_stores => ({
    accessTokenObject: _stores.auth.accessTokenObject,
    isUpdateAccountSettingsSuccess: isSuccess(_stores.settings.updateAccountSettingsApiStatus),
    isUpdateDomainSettingsSuccess: isSuccess(_stores.settings.updateDomainSettingsApiStatus),
    accountSettings: _stores.settings.accountSettings,
    domainSettings: _stores.settings.domainSettings
  }))

  const [shouldUpdateInitialForm, setShouldUpdateInitialForm] = useState<boolean>(false)
  const [isDirtyForm, resetInitialForm] = useDirtyFormCheck([formObject, scoringObject])

  // key is used to force a re-render of <SettingsPage>
  // The Cancel button is supposed to reset the initial form values only after a confirmation.
  // The need for a confirmation (block) is set up via useDirtyFormCheck
  const [key, setKey] = useState(makeUuid())

  // init
  useEffect(() => {
    if (canSendBrtsEvidence) {
      SETTINGS_LIST.push(AvailableSettings.BRTS_SEND_EVIDENCE)
    }
    if (canEmailCategorization) {
      SETTINGS_LIST.push(
        AvailableSettings.EMAILCAT_CORPORATE,
        AvailableSettings.EMAILCAT_LISTSERVER,
        AvailableSettings.EMAILCAT_MARKETING,
        AvailableSettings.EMAILCAT_SOCIALMEDIA,
        AvailableSettings.EMAILCAT_TRANSACTIONAL
      )
    }
    if (accessTokenObject?.pdDomainId) {
      dispatch(getDomainSettings({ domainId: accessTokenObject?.pdDomainId, settings: SETTINGS_LIST }))
    } else {
      dispatch(getAccountSettings(SETTINGS_LIST))
    }
    // eslint-disable-next-line
  }, [canEmailCategorization])

  // unmount
  useEffect(
    () => () => {
      dispatch(resetAccountAndDomainSettings())
    },
    [dispatch]
  )

  const onHelpClick = useCallback(() => {
    setIsHelpDialogOpened(true)
  }, [])

  const onCloseHelp = useCallback(() => {
    setIsHelpDialogOpened(false)
  }, [])

  const setValueWithDefaults = (settings: SettingsObject) => {
    const settingsWithDefaults = settings

    if (!settingsWithDefaults.fastspam_enabled) {
      settingsWithDefaults[AvailableSettings.FASTSPAM_ENABLED] = '1'
    }

    if (!settingsWithDefaults.fastspam_scores) {
      settingsWithDefaults[AvailableSettings.FASTSPAM_SCORES] = '{"block": 5, "quarantine": 0}'
    }

    return settingsWithDefaults
  }

  const initialValues = useCallback((settings: SettingsObject) => {
    const filteredSettings = filterSettings(settings, SETTINGS_LIST)
    const settingsWithDefaults = setValueWithDefaults(filteredSettings)

    setFormObject(settingsWithDefaults)
    setScoringObject(JSON.parse(settingsWithDefaults.fastspam_scores as string))
    setShouldUpdateInitialForm(true)
  }, [])

  // set initialForm values
  useEffect(() => {
    if (shouldUpdateInitialForm) {
      setShouldUpdateInitialForm(false)
      resetInitialForm()
    }
  }, [shouldUpdateInitialForm, resetInitialForm])

  useEffect(() => {
    if (accessTokenObject?.pdDomainId) {
      initialValues(domainSettings)
    } else {
      initialValues(accountSettings)
    }
    setShouldUpdateInitialForm(true)
  }, [initialValues, accountSettings, domainSettings, accessTokenObject])

  useEffect(() => {
    if (isUpdateAccountSettingsSuccess || isUpdateDomainSettingsSuccess) {
      setShouldUpdateInitialForm(true)
    }
  }, [dispatch, isUpdateAccountSettingsSuccess, isUpdateDomainSettingsSuccess])

  const onFormChange = useCallback(
    (name: AvailableSettings) => (newValue: any) => {
      if (name === AvailableSettings.BRTS_SEND_EVIDENCE) {
        const dbValue = formObject.brts_send_evidence === '1' ? '0' : '1'
        setFormObject({ ...formObject, [name]: dbValue } as SettingsObject)
      } else if (name === AvailableSettings.VSCAN && newValue === '0') {
        setIsWarningDialogOpen(true)
      } else {
        setFormObject({ ...formObject, [name]: newValue } as SettingsObject)
      }
    },
    [formObject]
  )

  const onScoringChange = useCallback(
    (name: ScoringType, value: number) => {
      if (name === ScoringType.QUARANTINE && value >= scoringObject.block) {
        setScoringObject({ ...scoringObject, [name]: scoringObject.block - 1 < 0 ? 0 : scoringObject.block - 1 })
      } else if (name === ScoringType.BLOCK && value <= scoringObject.quarantine) {
        setScoringObject({ block: value, quarantine: value - 1 < 0 ? 0 : value - 1 })
      } else {
        setScoringObject({ ...scoringObject, [name]: value })
      }
    },
    [scoringObject]
  )

  const onActionChange = useCallback(
    (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
      const { name, value } = e.target
      setFormObject({ ...formObject, [name as string]: value })
    },
    [formObject]
  )

  const onSave = useCallback(() => {
    if (accessTokenObject?.pdDomainId) {
      dispatch(
        updateDomainSettings({
          domainId: accessTokenObject.pdDomainId,
          settings: { ...formObject, [AvailableSettings.FASTSPAM_SCORES]: scoringObject }
        })
      )
    } else {
      dispatch(
        updateAccountSettings({ settings: { ...formObject, [AvailableSettings.FASTSPAM_SCORES]: scoringObject } })
      )
    }
  }, [dispatch, formObject, scoringObject, accessTokenObject])

  const onCancelConfirm = useCallback(() => {
    const block = getBlockTransition()
    if (block) {
      block(() => {
        if (accessTokenObject?.pdDomainId) {
          initialValues(domainSettings)
        } else {
          initialValues(accountSettings)
        }
        setBlockTransition(undefined)
        setKey(makeUuid())
      })
    }
  }, [accessTokenObject, accountSettings, domainSettings, initialValues])

  const onConfirmWarningDialog = useCallback(() => {
    setFormObject({ ...formObject, [AvailableSettings.VSCAN]: '0' })
    setIsWarningDialogOpen(false)
  }, [formObject])

  const onCancelWarningDialog = useCallback(() => {
    setIsWarningDialogOpen(false)
  }, [])

  return useMemo(
    () => [
      {
        canQuarantineSettings,
        canEmailCategorization,
        canSendBrtsEvidence,
        settings: formObject,
        scoringObject,
        tableData: {
          total: emailCategories.length,
          data: emailCategories
        },
        isWarningDialogOpen,
        isDirtyForm,
        key
      },
      {
        onFormChange,
        onScoringChange,
        onActionChange,
        onSave,
        onCancelConfirm,
        onCancelWarningDialog,
        onConfirmWarningDialog,
        helpConfig: {
          isOpen: isHelpDialogOpened,
          onHelpClick,
          onCloseHelp
        }
      }
    ],
    [
      canQuarantineSettings,
      canEmailCategorization,
      canSendBrtsEvidence,
      formObject,
      scoringObject,
      isWarningDialogOpen,
      isDirtyForm,
      key,
      onFormChange,
      onScoringChange,
      onActionChange,
      onSave,
      onCancelConfirm,
      onCancelWarningDialog,
      onConfirmWarningDialog,
      isHelpDialogOpened,
      onHelpClick,
      onCloseHelp
    ]
  )
}
