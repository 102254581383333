import { useCallback, useEffect, useMemo, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isPending, isSuccess } from 'redux/toolkit/api'
import { TRACKING_EVENTS, trackMixpanelEvent } from 'lib/monitoring/monitoringService'
import { getRedeliveryQueueMessages, MlogViewConfig, resetSelectedMessage } from 'redux/features/mstore/mstoreSlice'

import appConfig from 'config/appConfig'
import { SplitterOrientation } from 'components/libs/splitter/splitterTypes'
import {
  MessageLogPreloadLogic,
  useMessageLogPreloadLogic
} from 'components/pages/messageLog/useMessageLogPreloadLogic'
import { GROUP_TITLE, TOPICS } from 'components/pages/support/config'
import EndUserLog from 'components/pages/support/help/endUserLog/EndUserLog'
import { useMessageLogRights } from 'components/libs/userRights/pages/useMessageLogRights'
import Log from '../support/help/log/Log'
import { HelpPropsComponent } from '../support/help/Help'
import OutboundQuarantineInbox from '../support/help/outboundQuarantineInbox/OutboundQuarantineInbox'

export interface UseMessageLogLogicProps {
  outboundQuarantineView?: boolean
}

export interface UseMessageLogLogic extends MessageLogPreloadLogic {
  inProgress: boolean
  showSplitter: boolean
  isHorizontal: boolean
  viewConfig: MlogViewConfig
  isAdmin: boolean
  helpConfig: {
    isOpen: boolean
    onHelpClick: () => void
    onCloseHelp: () => void
  }
  helpTitle: HelpPropsComponent | undefined
}

export const useMessageLogLogic = ({ outboundQuarantineView = false }: UseMessageLogLogicProps): UseMessageLogLogic => {
  const { uiState, preloadErrors } = useMessageLogPreloadLogic()
  const [isHelpDialogOpened, setIsHelpDialogOpened] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  const {
    inProgress,
    splitter,
    selectedMessageMid,
    viewConfig,
    isDeleteMessageSuccess,
    deletedMessageIds,
    user_roles
  } = useAppSelector(_store => ({
    inProgress: isPending(_store.mstore.getSearchApiStatus),
    splitter: _store.app.splitter,
    selectedMessageMid: _store.mstore.selectedMessageMid,
    viewConfig: _store.mstore.viewConfig,
    isDeleteMessageSuccess: isSuccess(_store.mstore.deleteMessageApiStatus),
    deletedMessageIds: _store.mstore.deletedMessageIds,
    user_roles: _store.app.appType
  }))
  const { canUseAdminVersionOfMessageLog } = useMessageLogRights()

  // Init
  useEffect(() => {
    // disabled this event for endusers since caused significant spikes in our Mixpanel MTU
    if (!appConfig.APP.IS_ENDUSER) {
      trackMixpanelEvent(TRACKING_EVENTS.WEBUI.MESSAGE_LOG_PAGE_VIEW)
    }
    const timer = setInterval(() => {
      dispatch(getRedeliveryQueueMessages())
    }, appConfig.SIDEBANDMAILER.REFRESH_INTERVAL)

    return () => {
      clearInterval(timer)
    }
    // eslint-disable-next-line
  }, [])

  const onHelpClick = useCallback(() => {
    setIsHelpDialogOpened(true)
  }, [])

  const onCloseHelp = useCallback(() => {
    setIsHelpDialogOpened(false)
  }, [])

  const helpTitle = useMemo(
    () =>
      user_roles === 'enduser'
        ? {
            content: EndUserLog,
            onClose: onCloseHelp,
            title: TOPICS.userMessageLog,
            groupTitle: GROUP_TITLE.endUser
          }
        : {
            content: outboundQuarantineView ? OutboundQuarantineInbox : Log,
            onClose: onCloseHelp,
            title: outboundQuarantineView ? TOPICS.outboundQuarantine : TOPICS.adminMessageLog,
            groupTitle: outboundQuarantineView ? GROUP_TITLE.outboundQuarantine : GROUP_TITLE.messageLog
          },
    [onCloseHelp, user_roles, outboundQuarantineView]
  )

  const showSplitter = useMemo(
    () => !!selectedMessageMid && splitter.orientation !== SplitterOrientation.none,
    [splitter, selectedMessageMid]
  )

  useEffect(() => {
    if (isDeleteMessageSuccess && deletedMessageIds && selectedMessageMid) {
      if (deletedMessageIds.includes(selectedMessageMid)) {
        dispatch(resetSelectedMessage())
      }
    }
  }, [isDeleteMessageSuccess, selectedMessageMid, deletedMessageIds, dispatch])

  return useMemo(
    () => ({
      inProgress,
      showSplitter,
      isHorizontal: splitter.orientation === SplitterOrientation.horizontal,
      uiState,
      preloadErrors,
      viewConfig,
      isAdmin: canUseAdminVersionOfMessageLog,
      helpConfig: {
        isOpen: isHelpDialogOpened,
        onHelpClick,
        onCloseHelp
      },
      helpTitle
    }),
    [
      inProgress,
      preloadErrors,
      showSplitter,
      splitter.orientation,
      uiState,
      viewConfig,
      canUseAdminVersionOfMessageLog,
      isHelpDialogOpened,
      onHelpClick,
      onCloseHelp,
      helpTitle
    ]
  )
}
