import React, { useMemo } from 'react'
import { Button, DataTable, DataTableColumn, Grid, TextField, Typography } from '@barracuda-internal/bds-core'

import { Cell } from 'components/libs/grid/cell'
import { useFormatMessage } from 'lib/localization'
import { DomainEntry } from 'types/Settings'
import styles from 'components/pages/inboundSettings/inboundSettingsStyles'
import WarningDialog from 'components/libs/dialog/warningDialog/WarningDialog'
import {
  DOMAIN_INPUT_NAMES,
  UseDomainTableLogicProps,
  useDomainTableLogic
} from 'components/libs/domainTable/useDomainTableLogic'

const BASE_I18N_KEY = 'ess.settings.domain_table'

const DomainTable: React.FC<UseDomainTableLogicProps> = (props: UseDomainTableLogicProps) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const inboundClasses = styles()
  const [state, eventHandlers] = useDomainTableLogic(props)

  return useMemo(
    () => (
      <Grid data-testid="ip-address-table-grid">
        <DataTable
          sortable={{
            allowUnsort: false,
            mode: 'single'
          }}
          sort={state.sort}
          onSortChange={eventHandlers.handleSortChange}
          data={state.tableData}
        >
          <DataTableColumn
            sortable
            field={DOMAIN_INPUT_NAMES.DOMAIN}
            title={(props.shouldHandleEmail && formatMessage('email_domain')) || formatMessage('domains')}
            cell={({ dataItem }: { dataItem: DomainEntry }) => (
              <Cell>
                {!dataItem.id && (
                  <TextField
                    fullWidth
                    defaultValue={state.formState.domain}
                    inputRef={state.form.fields.domain.ref}
                    onChange={eventHandlers.handleOnInputChange}
                    name={DOMAIN_INPUT_NAMES.DOMAIN}
                    error={!!state.error}
                    helperText={state.error}
                    inputProps={{ style: { padding: 8 } }}
                    data-testid="new-domain-field"
                  />
                )}
                {dataItem.id && (
                  <Typography variant="body2" data-testid="domain-field">
                    {dataItem.domain}
                  </Typography>
                )}
              </Cell>
            )}
          />
          <DataTableColumn
            sortable
            field={DOMAIN_INPUT_NAMES.COMMENT}
            title={formatMessage('comment')}
            cell={({ dataItem }: { dataItem: DomainEntry }) => (
              <Cell data-testid="comment-cell" className={inboundClasses.smallCell}>
                {!dataItem.id && (
                  <TextField
                    fullWidth
                    defaultValue={state.formState.comment}
                    inputRef={state.form.fields.comment.ref}
                    onChange={eventHandlers.handleOnInputChange}
                    name={DOMAIN_INPUT_NAMES.COMMENT}
                    inputProps={{ style: { padding: 8 } }}
                    data-testid="new-comment-field"
                  />
                )}
                {dataItem.id && (
                  <Typography variant="body2" data-testid="comment-field">
                    {dataItem.comment}
                  </Typography>
                )}
              </Cell>
            )}
          />
          <DataTableColumn
            field="actions"
            title={formatMessage('actions')}
            cell={({ dataItem }: { dataItem: DomainEntry }) => (
              <Cell data-testid="action-cell" className={inboundClasses.smallCell}>
                {!dataItem.id && (
                  <Button
                    color="primary"
                    onClick={() => {
                      eventHandlers.onAdd()
                    }}
                    variant="text"
                    data-testid="add-button"
                    isLoading={props.isLoading}
                  >
                    {formatMessage('add')}
                  </Button>
                )}
                {dataItem.id && (
                  <Button
                    color="primary"
                    onClick={() => {
                      eventHandlers.onRemove(dataItem.id)
                    }}
                    variant="text"
                    data-testid="remove-button"
                    isLoading={props.isLoading}
                  >
                    {formatMessage('remove')}
                  </Button>
                )}
              </Cell>
            )}
          />
        </DataTable>
        {!!state.idToRemove && (
          <WarningDialog
            title={formatMessage('confirm_removal')}
            onConfirm={eventHandlers.onConfirmRemove}
            onCancel={eventHandlers.onConfirmCancel}
          />
        )}
      </Grid>
    ),
    [formatMessage, inboundClasses, state, eventHandlers, props]
  )
}

export default DomainTable
