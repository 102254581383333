import React from 'react'
import * as EmailIcons from '@barracuda-internal/bds-core/dist/Icons/Email'
import * as CoreIcons from '@barracuda-internal/bds-core/dist/Icons/Core'
import { Tune } from '@material-ui/icons'
import IconExport from 'assets/images/icons/icon_export.svg'
import NextArrow from 'assets/images/icons/icon_next_arrow.svg'
import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function EndUserLog(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      <p>
        The Message Log displays inbound email traffic that passes through the Barracuda Email Gateway Defense for your
        account. You can filter messages by selecting options in the following menus: <b>Date Presets</b>,{' '}
        <b>Action Taken</b>,<b>Action Status</b>, or<b>Reason</b>.
      </p>
      <ul>
        <li>
          <b>Date Presets</b>
          <br />
          Limit to <b>Last 1 Day</b>, <b>Last 2 Days</b>, <b>Last 1 Week</b>, <b>Last 2 Weeks</b>, or{' '}
          <b>Last 1 Month</b>
          .
          <br />
          Note: This menu will show <b>Custom</b> if a Date/Time range is selected in <b>Advanced Search</b>.
        </li>
        <li>
          <b>Action Taken</b>
          <br />
          Limit to <b>Any</b>, <b>Allowed</b>, <b>Blocked</b>, <b>Quarantined</b>, or <b>Deferred</b> messages.
          <br />
          Note: when viewing the Quarantine log, a <b>Delete</b> button will be present. Deleting will remove the
          message(s) from the Quarantine log, but they can still be found by selecting <b>Any</b> in the Action Taken
          dropdown list.
        </li>
        <li>
          <b>Action Status</b>
          <br />
          Limit to <b>Any</b>, <b>Delivered</b>, <b>Not Delivered</b>, <b>Deferred</b>, <b>Rejected</b>, or{' '}
          <b>Spooled</b> messages.
        </li>
        <li>
          <b>Reason</b>
          <br />
          Messages with Actions taken for the selected <b>Reason</b>. For a description of reasons, see{' '}
          <a href="#help-reasons">Reasons for Actions Taken</a> below.
        </li>
      </ul>
      <p>The page is divided into two panes:</p>
      <ul>
        <li>The Message List displays your messages including various message details.</li>
        <li>
          The Reading Pane displays the contents of a clicked message. The Reading Pane is <b>On</b> by default. Click{' '}
          <Tune className={classes.icon} /> and select<b>Horizontal Split</b> or<b>Vertical Split</b> to specify where
          to display the Reading Pane.
        </li>
      </ul>
      <p>
        To view the contents of a message in the Reading Pane, click on a message once, or double-click a message to
        view the full-size message. The following sections describe the different aspects of the Message Log page:
      </p>
      <ul>
        <li>
          <a href="#help-search">Search for Messages</a>
        </li>
        <li>
          <a href="#help-scroll">Scroll through and View Messages</a>
        </li>
        <li>
          <a href="#help-display">Customize Display Appearance</a>
        </li>
        <li>
          <a href="#help-actions">Take Actions with Messages</a>
        </li>
        <li>
          <a href="#help-reasons">Reasons for Actions Taken</a>
        </li>
        <li>
          <a href="#help-column">Message Log Columns</a>
        </li>
      </ul>
      <h3 id="help-search">Search for Messages</h3>
      <b>Simple Search</b>
      <br />
      Use simple search to run a fast search based on a word, search pattern, or phrase.
      <ol>
        <li>
          If desired, select from the <b>Date Presets</b>, <b>Action Taken</b>, <b>Action Status</b>, or <b>Reason</b>{' '}
          drop-down menus to limit your search.
        </li>
        <li>
          Enter all or part of a word, search pattern, or phrase in the <b>Search</b> box; using the field name is not
          necessary, but narrows the search. For example, if you search on <i>myaddress@example.com</i>, the From, To,
          Subject and Body fields are all searched. Use the following form:
          <ul>
            <li>
              <b>IP_address</b>
              <br />
              Example: <i>ip:10.1.1.1</i>.
            </li>
            <li>
              <b>Message_id</b>
              <br />
              Click on a message, and click <b>Show Details</b> to find the message_id.
              <br />
              Example: Example: <i>message_id:1374102064-320627-22657-10347-7</i>
            </li>
            <li>
              <b>Attachment filename</b>
              <br />
              Format is <i>attachment:filename.ext</i>
            </li>
            <li>
              <b>Spam</b>
              <br />
              This filter operates both on messages you have marked as Spam and on messages determined by the Barracuda
              Email Gateway Defense to be Spam. Use the format: filter:spam
            </li>
            <li>
              <b>From</b>
              <br />
              Example: <i>header_from:myaddress@example.com</i> or <i>myaddress@example.com</i>
            </li>
            <li>
              <b>To</b>
              <br />
              Example: <i>header_to:myaddress@example.com</i> or <i>myaddress@example.com</i>
            </li>
            <li>
              <b>Envelope_from</b>
              <br />
              Example: <i>envelope_from:myaddress@example.com</i> or <i>myaddress@example.com</i>
            </li>
            <li>
              <b>Envelope_to</b>
              <br />
              Example: <i>envelope_to:myaddress@example.com</i> or <i>myaddress@example.com</i>
            </li>
            <li>
              <b>Subject</b>
              <br />
              Example: <i>subject:Tomorrow</i> or <i>Tomorrow</i>
            </li>
            <li>
              <b>Score_lt(e)</b>
              <br />
              Example: <i>score_lt:5.2</i> lists all messages where the score is <i>less than</i> 5.2.
              <br />
              Example: <i>score_lte:3.0</i> lists all messages where the score is <i>less than or equal to</i> 3.0.
              <br />
            </li>
            <li>
              <b>Score_gt(e)</b>
              <br />
              Example: <i>score_gt:6.8</i> lists all messages where the score is
              <i>greater than</i> 6.8.
              <br />
              Example: <i>score_gte:2.5</i> lists all messages where the score is
              <i>greater than or equal to</i> 2.5.
              <br />
            </li>
            <li>
              <b>Size_lt</b>
              <br />
              Example: <i>size_lt:500</i> lists all messages where the size, including attachments, is
              <i>less than</i> 500 bytes.
              <br />
            </li>
            <li>
              <b>Size_gt</b>
              <br />
              Example: <i>size_gt:1000</i> lists all messages where the size, including attachments, is{' '}
              <i>greater than</i> 1000 bytes.
              <br />
            </li>
            <li>
              <b>Delivery status</b>
              <br />
              <b>Delivered</b>,<b>Deferred</b>,<b>Not_Delivered</b>,<b>Rejected</b>,<b>Spooled</b>
              . Use the format: delivery_status:&lt;delivery status&gt;
              <br />
              Example: <i>delivery_status:deferred</i>
            </li>
          </ul>
        </li>
      </ol>
      <br />
      <b>Advanced Search</b>
      <br />
      To further filter or search for specific messages, click on the <Tune className={classes.icon} /> icon on the
      right side of the <b>Search</b> field to display the Advanced Search options. To hide these options, click
      anywhere outside of the Advanced Search. Select or enter a word or phrase on which to search in one or more of the
      following fields, then click the <b>Search</b> button.
      <ul>
        <li>
          <b>Keywords</b>
          <br />
          This field will look to match any of the other <b>Advanced Search</b> fields other than <b>Attachment</b> and
          the <b>Date/Time</b> range. Example: the Keyword &quot;Hello&quot; will match any message that has
          &quot;Hello&quot; in the <b>Envelope From</b>, <b>Envelope To</b>, <b>From</b>, <b>To</b> or <b>Subject</b>.
        </li>
        <li>
          <b>From</b>
          <br />
          Sender email address (this matches the address in the headers that mail clients display to an end-user).
        </li>
        <li>
          <b>To</b>
          <br />
          Recipient email address(es)
        </li>
        <li>
          <b>Envelope From</b>
          <br />
          This is the (sender) email address to which bounce messages are delivered. This field is also sometimes known
          as <i>envelope from</i>, <i>envelope sender</i>, <i>MAIL FROM</i>, <i>return address</i>, and other names. All
          of these names refer to the email address found by the SMTP MAIL FROM command. The Envelope From field
          contents are generally not seen by the email user.
        </li>
        <li>
          <b>Envelope To</b>
          <br />
          The real destination email address.
        </li>
        <li>
          <b>Subject</b>
          <br />
          Messages where any portion of the &quot;Subject:&quot; field contains the specified text.
        </li>
        <li>
          <b>Attachment</b>
          <br />
          Attachment file name.
        </li>
        <li>
          <b>Start Date, End Date</b>
          <br />
          Use to limit date/time range of message search results. Select the Date and then the Time for both the Start
          and End of the desired search period. Examples:
          <ul>
            <li>
              To view 10 minutes of results
              <br />
              <b>Start Date:</b> <i>2016-10-01 12:00am</i> &nbsp;&nbsp; <b>End Date:</b> <i>2016-10-01-12:10am</i>{' '}
              &nbsp;&nbsp; Returns messages from 12:00 AM through 12:09 AM, not including 12:10 AM.
            </li>
            <li>
              To view a 7 day range
              <br />
              <b>Start Date:</b> <i>2016-09-24 12:00am</i> &nbsp;&nbsp; <b>End Date:</b> <i>2016-10-01 12:00am</i>
            </li>
            <li>
              To view yesterday&apos;s messages through today
              <br />
              First fill out any other Advanced Search fields that you want to include in your search and click the{' '}
              <b>Search</b> button. Then select<b>Last 1 Day</b> from the<b>Date Presets</b> dropdown menu outside of
              the Advanced Search box.
            </li>
          </ul>
        </li>
      </ul>
      <b>Saved Searches</b>
      <br />
      To save a search filter that you want to use multiple times, do the following:
      <ol>
        <li>
          Create your new search filter by filling in the <b>Advanced Search</b> fields you want to use with appropriate
          values.
        </li>
        <li>
          Click <b>Save</b>.
        </li>
        <li>
          Enter a name to represent the saved search in the <b>Save Search</b> field.
        </li>
        <li>
          Click <b>Save</b>. The saved search is added to the list of saved searches.
        </li>
      </ol>
      To use a saved search, do the following:
      <ol>
        <li>
          Open <b>Advanced Search</b>.
        </li>
        <li>
          Click <b>Open</b>.
        </li>
        <li>
          A list of saved searches is displayed. Select the one you want to use and click <b>Open</b>.
        </li>
      </ol>
      <h3 id="help-scroll">Scroll through and View Messages</h3>
      Use the vertical scroll bar to move up and down the page. To see the Recipients, Action taken, sending IP address,
      and other message details, click on the message, and click the down arrow in the message header in the Reading
      pane. If there are more messages than are displayed on the page, click the right arrow{' '}
      <img src={NextArrow} className={classes.inlineImg} alt="right arrow" /> next to the number of messages at the
      top-right of the message table.
      <br />
      <br />
      When you view a message in the log, the following actions are available:
      <ul>
        <li>
          <b>Preview</b>
          <br />
          View message content.
        </li>
        <li>
          <b>Source</b>
          <br />
          View original message source and headers.
        </li>
        <li>
          <b>Deliver</b> <EmailIcons.Send className={classes.icon} />
          <br />
          Deliver the message to the recipient; you cannot deliver a blocked message. This option is unavailable if the
          selected message has a <b>Reason</b> of <b>Advanced Threat Protection</b>.
        </li>
        <li>
          <b>Allow List</b> <EmailIcons.Send className={classes.icon} />
          <br />
          Add this email address to the allow list, which means that messages from this email address are always
          allowed, and deliver the message. Note that this option is only available if the administrator has selected to
          allow users to exempt senders. Your administrator may not allow you to add blocked messages to the allow list.
        </li>
        <li>
          <b>Block</b>
          <CoreIcons.MenuVertical className={classes.icon} />
          <br />
          Select <b>Add Sender Email to Block list</b> or <b>Add Sender Domain to Block list</b> to always block mail
          from this domain or email address. Note that this option is only available if the administrator has selected
          to allow users to block senders.
        </li>
        <li>
          <b>Download Message</b>
          <CoreIcons.MenuVertical className={classes.icon} />
          <br />
          Download the message source.
        </li>
        <li>
          <b>Open</b> <CoreIcons.Launch className={classes.icon} />
          <br />
          Open the message in a new tab. Note that your administrator may not allow you to display the content of a
          blocked message.
        </li>
        <li>
          <b>Close</b>
          <CoreIcons.Close className={classes.icon} />
          <br />
          Close the reading pane.
        </li>
      </ul>
      <h3 id="help-display">Customize Display Appearance</h3>
      <ul>
        <li>
          <b>Resize column widths</b>
          <br />
          Click the edge of the column header you want to move and drag to the desired width. Your cursor changes to a
          double arrow, pointing right and left, when it is over a movable border.
        </li>
        <li>
          <b>Reading Pane</b> <EmailIcons.HorizontalSplit className={classes.icon} />
          <br />
          Select whether to display the message Reading Pane on the right or the bottom of the <b>Message Log</b> page
          by selecting <b>Vertical Split</b>
          or <b>Horizontal Split</b>. You can also turn the Reading Pane off by selecting <b>No Split</b>.
        </li>
      </ul>
      <h3 id="help-actions">Take Actions with Messages</h3>
      <p>
        Select one or more messages using the check boxes on the left side of the Message List, then click an action on
        the tool bar.
      </p>
      <ul>
        <li>
          <b>Deliver</b> <EmailIcons.Send className={classes.icon} />
          <br />
          After clicking the deliver icon, select either <b>Only deliver the message(s)</b> or{' '}
          <b>Deliver and add the sender(s) to your allow list</b>. The service attempts to deliver the selected messages
          to your mailbox; note that you cannot deliver messages blocked for ATP. If a message is successfully
          delivered, the
          <b>Delivery Status</b> <b>Delivered</b>. If the mail cannot be delivered, a notice displays in your browser
          window and the
          <b>Delivery Status</b> does not change. If delivered messages are not delivered to the recipient&apos;s
          mailbox, it may be due to a filter on the mail server or a service on your network catching the mail as spam.
          Check with your system administrator for more information. Additionally, check your local trash/spam folder
          for the mail.
        </li>
        <li>
          <b>Allow List</b> <EmailIcons.Send className={classes.icon} />
          <br />
          After clicking the deliver icon, select <b>Deliver and add the sender(s) to your allow list</b>. This will add
          the sender email address to the allow list (always allowed, but still scanned for viruses), and deliver the
          message. Note that this option is only available if the administrator has selected to allow users to exempt
          senders.
        </li>
        <li>
          <b>Export</b> <img src={IconExport} className={classes.inlineImg} alt="" />
          <br />
          Export either selected or all messages to an Excel file. To export all messages, click the check box at the
          top of the Message List. You are prompted for a file name to save to your local desktop or network.
        </li>
        <li>
          <b>Recategorize</b> <CoreIcons.LabelOutline className={classes.icon} />
          <br />
          Select one or more categorized emails and click <b>Recategorize</b> to change the category. For example, if
          the message has been categorized as <b>Corporate</b> but you believe it should be categorized as{' '}
          <b>Marketing Materials</b>, you can change the category via the<b>Recategorize</b> drop-down menu. This action
          submits this email message for recategorization to your selected category. If you select
          <b>Other</b> and enter a custom category, this simply updates the category for that particular email message.
          For more information about Email Categorization, see
          <a href="#help-reasons">Reasons for Actions Taken</a>{' '}
        </li>
      </ul>
      <h3 id="help-reasons">Reasons for Actions Taken</h3>
      Reason for specific Action may be one of the following:
      <ul>
        <li>
          <b>ATP Service Unavailable</b>
          <br />
          Message was deferred by the ATP service because the ATP scanning service was temporarily unavailable. The
          message is retried and, when the scan is complete, delivered.
        </li>
        <li>
          <b>AV Service Unavailable</b>
          <br />
          Your administrator&apos;s <b>Scan Email for Viruses</b> setting on the{' '}
          <b>Inbound Settings &gt; Anti-Spam/Antivirus</b> page is set to<b>Yes</b>, but the virus scanning service was
          temporarily unavailable when the message came through. The message is deferred and retried when the virus
          scanning service is available.
        </li>
        <li>
          <b>Account Suspended</b>
          <br />
          If your Barracuda Email Gateway Defense subscription expired more than 60 days ago, your account is marked as{' '}
          <b>Suspended</b>, and email are no longer scanned for spam. Email is still scanned for viruses.
        </li>
        <li>
          <b>Advanced Threat Protection</b>
          <br />
          Message was blocked by the ATP cloud-based virus scanning service. ATP is an advanced virus scanning service
          which, when enabled on the <b>Inbound Settings &gt; ATP</b> page, provides additional scanning for the
          attachment file types you specify.
        </li>
        <li>
          <b>Anti-Fraud</b>
          <br />
          Action was taken because Barracuda Anti-Fraud Intelligence detected a potential phishing scheme, which could
          be used to gather confidential information about an organization or its individual users.
        </li>
        <li>
          <b>Antivirus</b>
          <br />
          Action was taken because the message had a virus attached.
        </li>
        <li>
          <b>Attachment Content</b>
          <br />
          Action was taken because content in an attachment to the message matched a <b>Message Content Filter</b> rule.
        </li>
        <li>
          <b>Attachment Filter</b>
          <br />
          Action was taken due to <b>Attachment Filter</b> settings.
        </li>
        <li>
          <b>Barracuda Real-Time System (BRTS)</b>
          <br />
          Action was taken because BRTS detected a zero-hour spam or virus. This advanced service detects spam or virus
          outbreaks even where traditional heuristics and signatures to detect such messages do not yet exist.
        </li>
        <li>
          <b>Barracuda Reputation</b>
          <br />A list maintained by Barracuda Central that includes IP addresses of known spammers.
        </li>
        <li>
          <b>Body Content</b>
          <br />
          Action was taken because content in the message body matched a <b>Message Content Filter</b> rule.
        </li>
        <li>
          <b>Bulk Email</b>
          <br />
          Action was taken because the message qualifies as Bulk.
        </li>
        <li>
          <b>Cloudscan Service Unavailable</b>
          <br />
          The Cloudscan spam scoring service was temporarily unavailable when the message came through. The message is
          deferred and is retried when the Cloudscan service is available.
        </li>
        <li>
          <b>Content Protected</b>
          <br />
          Action was taken because the message has a password protected archive attachment.
        </li>
        <li>
          <b>Content URL</b>
          <br />
          Action was taken because the message contained one or more URLs that match <b>Intent Domain Policies</b>.
        </li>
        <li>
          <b>DKIM</b>
          <br />
          Action was taken because the message is from a domain that fails DKIM verification.
        </li>
        <li>
          <b>DMARC</b>
          <br />
          Action was taken because the message is from a domain that fails DMARC verification.
        </li>
        <li>
          <b>Email Categorization</b>
          <br />
          Email from this sender is categorized as not necessarily spam, but rather something that you may have
          subscribed to at one time and may no longer wish to receive. For example, newsletters and memberships, or
          marketing information. Categories supported appear in the Message Log <b>Reason</b> as:
          <ul>
            <li>
              <b>Email Categorization(corporate)</b>
              <br />
              Emails sent by a user at an authenticated organization from a Microsoft Exchange Server that involves
              general corporate communications. Does not include marketing newsletters.
            </li>
            <li>
              <b>Email Categorization(transactional)</b>
              <br />
              Emails related to order confirmations, bills, invoices, bank statements, delivery/shipping notices, and
              service-related surveys.
            </li>
            <li>
              <b>Email Categorization(marketing)</b>
              <br />
              Promotional emails from companies such as Constant Contact.
            </li>
            <li>
              <b>Email Categorization (mailing lists)</b>
              <br />
              Emails from mailing lists, newsgroups, and other subscription-based services such as Google and Yahoo!
              Groups.
            </li>
            <li>
              <b>Email Categorization (social media)</b>
              <br />
              Notifications and other emails from social media sites such as Facebook and LinkedIn.
            </li>
          </ul>
        </li>
        <li>
          <b>From Address</b>
          <br />
          Action was taken because of a sender or content rule for From Address.
        </li>
        <li>
          <b>Header Content</b>
          <br />
          Action was taken because content in the message header matched a <b>Message Content Filter</b> rule.
        </li>
        <li>
          <b>IP Address Policies</b>
          <br />
          Action was taken because the sending IP address is listed (as <b>Blocked</b> or <b>Exempt</b>) on the{' '}
          <b>Inbound Settings &gt; IP Address Policies</b>
          page.
        </li>
        <li>
          <b>Image Analysis</b>
          <br />
          Action was taken because Image Analysis identified this message as a bulk/spam message.
        </li>
        <li>
          <b>Intent Analysis</b>
          <br />
          Action was taken because Intention Analysis identified this message as a bulk/spam message.
        </li>
        <li>
          <b>Invalid Recipient</b>
          <br />
          &quot;To&quot; address does not exist on the mail server.
        </li>
        <li>
          <b>Malformed</b>
          <br />
          The message did not conform to the SMTP protocol; for example, the Sender, From, Date, or other required
          fields may be empty.
        </li>
        <li>
          <b>Message Too Large</b>
          <br />
          The message exceeded the maximum message size allowed by the destination mail server, which rejected the
          message. (Note that the Barracuda Email Gateway Defense allows messages of up to 300 MB).
        </li>
        <li>
          <b>No PTR Record</b>
          <br />
          Action was taken because no PTR record was found.
        </li>
        <li>
          <b>Pending Scan</b>
          <br />
          The message is deferred because attachment scanning is pending. The mail server retries later to check if the
          scan is complete and, if it is, delivers the message.
        </li>
        <li>
          <b>Security Awareness Training</b>
          <br />
          Message was sent as part of a Security Awareness Training campaign.
        </li>
        <li>
          <b>Possible Mail Loop</b>
          <br />
          IP address for the destination mail server is not correctly configured in the Barracuda Email Gateway Defense,
          and may instead contain the IP address for the Barracuda Email Gateway Defense. This causes a mail loop.
        </li>
        <li>
          <b>Predefined Attachment Content</b>
          <br />
          Action was taken because an attachment to the message contained content that matched a Predefined filter based
          on data leakage patterns (specific to U.S.).
        </li>
        <li>
          <b>Predefined Body Content</b>
          <br />
          Action was taken because the message body contained content that matched a predefined filter based on data
          leakage patterns (specific to U.S.).
        </li>
        <li>
          <b>Predefined Filter Exceptions</b>
          <br />
          The message body contained content that matched a predefined filter exception to HIPAA or Privacy content
          filters.
        </li>
        <li>
          <b>Predefined From Address</b>
          <br />
          Action was taken because the message From address contained content that matched a predefined filter based on
          data leakage patterns (specific to U.S.).
        </li>
        <li>
          <b>Predefined Header Content</b>
          <br />
          Action was taken because the message header contained content that matched a predefined filter based on data
          leakage patterns (specific to U.S.).
        </li>
        <li>
          <b>Predefined Subject Content</b>
          <br />
          Action was taken because the message subject contained content that matched a predefined filter based on data
          leakage patterns (specific to U.S.).
        </li>
        <li>
          <b>Predefined To/CC Address</b>
          <br />
          Action was taken because the message &quot;To/CC&quot; address contained content that matched a predefined
          filter based on data leakage patterns (specific to U.S.).
        </li>
        <li>
          <b>Rate Control</b>
          <br />
          Sender IP address exceeded maximum number of allowed connections in a half-hour period. The action taken is to
          defer the message unless the client continues to make connections.
        </li>
        <li>
          <b>Realtime Block List</b>
          <br />
          Action was taken because of IP Reputation Analysis, which determined that the sending IP address is listed on
          a Real-Time Block List (RBL) or DNS Block List (DNSBL).
        </li>
        <li>
          <b>Recipient</b>
          <br />
          Action was taken because of a rule for the &quot;To&quot; address.
        </li>
        <li>
          <b>Score</b>
          <br />
          Action was taken because the score for the message exceeded that set for <b>Cloudscan Scoring</b>.
        </li>
        <li>
          <b>Sender Policies</b>
          <br />
          Action was taken due to sender policies.
        </li>
        <li>
          <b>Sender Policy Framework (SPF)</b>
          <br />
          Action was taken because the Sender IP address was not listed as an allowed sender for the specified domain
          using the SPF protocol.
        </li>
        <li>
          <b>Subject Content</b>
          <br />
          Action was taken because content in the subject line matched a message content filter rule.
          <br />
          <b>Note</b>: A subject line of<i>Message Has No Content</i> indicates an incomplete SMTP transaction due to a
          failed connection. The log entry shows the from/to data, but has no header or body content. This mail includes
          messages that are malformed or are addressed to invalid recipients.
        </li>
        <li>
          <b>Suspicious</b>
          <br />
          Message deferred or blocked due to multi-level intent checks or <b>Barracuda Anti-Fraud Intelligence</b>{' '}
          checks.
        </li>
        <li>
          <b>System Sender Policies</b>
          <br />
          Applies to outbound mail. Action was taken because the sender has been blocked per policy set by Barracuda
          Networks; this action prevents the Barracuda Email Gateway Defense IP address from being blocked. Contact your
          email administrator if you have questions.
        </li>
        <li>
          <b>TLS Required</b>
          <br />
          When an inbound message must be sent over a TLS connection and the mail server does not support TLS
          connections, the message is blocked with a reason of <b>TLS required</b>.
        </li>
        <li>
          <b>To/CC Address</b>
          <br />
          Action was taken because of a recipient or content rule for &quot;To/CC&quot; Address.
        </li>
        <li>
          <b>UI Delivered</b>
          <br />
          For emails blocked or quarantined in the Message Log, the admin has the option to manually deliver those
          messages. Once the message is delivered, the reason code for that message displays as <b>Allowed</b> with a
          reason of <b>UI Delivered</b>.
        </li>
      </ul>
      <h3 id="help-column">Message Log Columns</h3>
      <ul>
        <li>
          <b>Action</b>
          <br />
          This column is color coded and displays text as follows:
          <ul>
            <li>
              Green: <i>Allowed</i>
            </li>
            <li>
              Red: <i>Blocked</i>
            </li>
            <li>
              Yellow: <i>Quarantined</i>
            </li>
            <li>
              Gray: <i>Deferred</i>
              <br />
              Messages may be deferred due to:
              <ul>
                <li>
                  <b>Rate Control</b> (see<a href="#help-reasons">Reasons for Actions Taken</a>)
                </li>
                <li>
                  <b>Quarantine</b>, but the message has been deferred because the quarantine notification interval on
                  your administrator&apos;s
                  <b>Settings &gt; Quarantine Notification</b> <b>Never</b>. In this case, quarantined messages are
                  deferred until the quarantine notification interval is changed to another setting so that the messages
                  appear in your Message Log.
                </li>
              </ul>
            </li>
            <li>
              Blue: <i>Spooled</i>
            </li>
            <li>
              Orange: <i>Allowed/Blocked/Quarantined</i> (for Some)
              <br />
              This action indicates that an email was received for multiple recipients. Due to configured policy or IP
              checks, the message was not handled the same way for all recipients.
            </li>
          </ul>
        </li>
        <li>
          <b>Delivery</b>
          <br />
          Delivered, Not Delivered, Deferred, Rejected, or Spooled.
        </li>
        <li>
          <b>From</b>
          <br />
          Sender email address (this may not match the address in the headers that mail clients display to an end-user).
          Click the <b>From</b> drop-down to select how to display the <b>From</b> field:
          <ul>
            <li>
              <b>Show name</b>
              <br />
              Show the Header From Pretty Name.
            </li>
            <li>
              <b>Show email address</b>
              <br />
              Shows the Header From Email Address.
            </li>
            <li>
              <b>Show both</b>
              <br />
              Shows the Header From Pretty Name plus the Header From Email Address.
            </li>
          </ul>
        </li>
        <li>
          <b>To</b>
          <br />
          Recipient email address(es). CC and BCC recipients show up in separate log entries in the Message Log in the
          &quot;To&quot; field.
        </li>
        <li>
          <b>Subject</b>
          <br />
          Message subject line.
        </li>
        <li>
          <b>Date</b>
          <br />
          Date and time the message was processed.
        </li>
        <li>
          <b>Size</b>
          <br />
          Message size in bytes.
          <br />
          <b>Note</b>: The size of the attachment file as indicated in the<b>Size</b> column and in the Message Preview
          Pane is the encoded size, which is generally larger than the actual file size.
        </li>
        <li>
          <b>Attachment</b>
          <br />
          This column shows a paper clip icon and indicates if there is an attachment with the message.
        </li>
        <li>
          <b>Reason</b>
          <br />A brief description of why the Action (Blocked, Deferred, etc.) was taken for that message. See{' '}
          <a href="#help-reasons">Reasons for Actions Taken</a> for a description of all Reasons.
        </li>
        <li>
          <b>Score</b>
          <br />
          Action was taken because the score for the message exceeded that set for the administrator&apos;s configured{' '}
          <b>Cloudscan Scoring</b>.
        </li>
      </ul>
      <br />
    </div>
  )
}

export default EndUserLog
