import React, { useMemo } from 'react'
import {
  Button,
  DataTable,
  DataTableColumn,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@barracuda-internal/bds-core'
import { CheckmarkCircle } from '@barracuda-internal/bds-core/dist/Icons/Core'
import { Warning } from '@barracuda-internal/bds-core/dist/Icons/Feedback'

import inboundSettingsStyles from 'components/pages/inboundSettings/inboundSettingsStyles'

import { Cell } from 'components/libs/grid/cell'
import { useFormatMessage } from 'lib/localization'

import {
  RECIPIENT_INPUT_NAMES,
  RECIPIENT_SELECT_NAMES
} from 'components/pages/inboundSettings/recipientPolicies/useRecipientPoliciesLogic'
import { RecipientPolicy, RecipientPolicyStatus } from 'types/Settings'
import WarningDialog from 'components/libs/dialog/warningDialog/WarningDialog'
import { UseRecipientPoliciesTableLogicProps, useRecipientPoliciesTableLogic } from './useRecipientPoliciesTableLogic'

const BASE_I18N_KEY = 'ess.inbound_settings.recipient_policies'
const BASE_DIALOG_I18N_KEY = 'ess.dialog'

const RecipientPoliciesTable: React.FC<UseRecipientPoliciesTableLogicProps> = (
  props: UseRecipientPoliciesTableLogicProps
) => {
  const inboundClasses = inboundSettingsStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatDialogMessage = useFormatMessage(BASE_DIALOG_I18N_KEY)
  const [state, eventHandlers] = useRecipientPoliciesTableLogic(props)

  return useMemo(
    () => (
      <Grid data-testid="ip-address-table-grid">
        <DataTable
          sortable={{
            allowUnsort: false,
            mode: 'single'
          }}
          sort={state.sort}
          onSortChange={eventHandlers.handleSortChange}
          data={state.tableData}
        >
          <DataTableColumn
            sortable
            field={RECIPIENT_INPUT_NAMES.NAME}
            title={formatMessage('recipient')}
            cell={({ dataItem }: { dataItem: RecipientPolicy }) => (
              <Cell>
                {!dataItem.id && (
                  <TextField
                    fullWidth
                    defaultValue={state.formState.name}
                    inputRef={state.form.fields.name.ref}
                    onChange={eventHandlers.handleOnInputChange}
                    name={RECIPIENT_INPUT_NAMES.NAME}
                    error={!!state.error}
                    helperText={state.error && formatMessage(state.error)}
                    inputProps={{ style: { padding: 8 } }}
                    data-testid="new-name-field"
                  />
                )}
                {dataItem.id && (
                  <Typography variant="body2" data-testid="name-field">
                    {dataItem.name}
                  </Typography>
                )}
              </Cell>
            )}
          />
          <DataTableColumn
            sortable
            field={RECIPIENT_SELECT_NAMES.POLICY}
            title={formatMessage('policy')}
            cell={({ dataItem }: { dataItem: RecipientPolicy }) => (
              <Cell>
                {dataItem.id === '' && (
                  <Select
                    variant="outlined"
                    className={inboundClasses.smallDropdown}
                    name={RECIPIENT_SELECT_NAMES.POLICY}
                    defaultValue={state.formState.policy}
                    onChange={eventHandlers.onPolicyChange}
                    SelectDisplayProps={{ style: { padding: 8 } }}
                    data-testid="new-policy-field"
                  >
                    {Object.keys(RecipientPolicyStatus).map(policyValue => (
                      <MenuItem key={policyValue} value={policyValue}>
                        {formatMessage(policyValue)}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                {dataItem.id !== '' && (
                  <Typography variant="body2" data-testid="policy-field" className={inboundClasses.flexWrapper}>
                    {dataItem.policy === RecipientPolicyStatus.exempt && (
                      <CheckmarkCircle className={inboundClasses.successColor} />
                    )}
                    {dataItem.policy === RecipientPolicyStatus.scan && (
                      <Warning className={inboundClasses.warningColor} />
                    )}
                    {formatMessage(dataItem.policy as string)}
                  </Typography>
                )}
              </Cell>
            )}
          />
          <DataTableColumn
            sortable
            field={RECIPIENT_INPUT_NAMES.COMMENT}
            title={formatMessage('comment')}
            cell={({ dataItem }: { dataItem: RecipientPolicy }) => (
              <Cell data-testid="comment-cell" className={inboundClasses.smallCell}>
                {!dataItem.id && (
                  <TextField
                    fullWidth
                    defaultValue={state.formState.comment}
                    inputRef={state.form.fields.comment.ref}
                    onChange={eventHandlers.handleOnInputChange}
                    name={RECIPIENT_INPUT_NAMES.COMMENT}
                    inputProps={{ style: { padding: 8 } }}
                    data-testid="new-comment-field"
                  />
                )}
                {dataItem.id && (
                  <Typography variant="body2" data-testid="comment-field">
                    {dataItem.comment}
                  </Typography>
                )}
              </Cell>
            )}
          />
          <DataTableColumn
            field="actions"
            title={formatMessage('actions')}
            cell={({ dataItem }: { dataItem: RecipientPolicy }) => (
              <Cell data-testid="action-cell" className={inboundClasses.smallCell}>
                {!dataItem.id && (
                  <Button
                    color="primary"
                    onClick={() => {
                      eventHandlers.onAdd()
                    }}
                    variant="text"
                    data-testid="add-button"
                  >
                    {formatMessage('add')}
                  </Button>
                )}
                {dataItem.id && (
                  <Button
                    color="primary"
                    onClick={() => {
                      eventHandlers.onRemove(dataItem.id)
                    }}
                    variant="text"
                    data-testid="remove-button"
                  >
                    {formatMessage('remove')}
                  </Button>
                )}
              </Cell>
            )}
          />
        </DataTable>
        {!!state.idToRemove && (
          <WarningDialog
            title={formatDialogMessage('confirm_removal')}
            onConfirm={eventHandlers.onConfirmRemove}
            onCancel={eventHandlers.onConfirmCancel}
          />
        )}
      </Grid>
    ),
    [formatMessage, formatDialogMessage, inboundClasses, state, eventHandlers]
  )
}

export default RecipientPoliciesTable
