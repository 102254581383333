import React, { useMemo } from 'react'
import {
  Button,
  DataTable,
  DataTableColumn,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@barracuda-internal/bds-core'
import { Block, RemoveCircle } from '@barracuda-internal/bds-core/dist/Icons/Core'
import { Warning } from '@barracuda-internal/bds-core/dist/Icons/Feedback'

import inboundSettingsStyles from 'components/pages/inboundSettings/inboundSettingsStyles'

import { Cell } from 'components/libs/grid/cell'
import { useFormatMessage } from 'lib/localization'

import WarningDialog from 'components/libs/dialog/warningDialog/WarningDialog'
import { CustomRblsParsed } from 'types/CustomRbls'
import {
  useAntiPhishingTableLogic,
  UseAntiPhishingTableLogicProps,
  INPUT_NAMES,
  SELECT_NAMES
} from 'components/pages/inboundSettings/antiPhishing/useAntiPhishingTableLogic'
import { IntentDomainPolicies, LinkedDomainPolicy } from 'types/AntiPhishing'

const BASE_I18N_KEY = 'ess.inbound_settings.anti_phishing'
const BASE_DIALOG_I18N_KEY = 'ess.dialog'

const AntiPhishingTable: React.FC<UseAntiPhishingTableLogicProps> = (props: UseAntiPhishingTableLogicProps) => {
  const inboundClasses = inboundSettingsStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatDialogMessage = useFormatMessage(BASE_DIALOG_I18N_KEY)
  const [state, eventHandlers] = useAntiPhishingTableLogic(props)

  return useMemo(
    () => (
      <Grid data-testid="ip-address-table-grid">
        <DataTable
          sortable={{
            allowUnsort: false,
            mode: 'single'
          }}
          sort={state.sort}
          onSortChange={eventHandlers.handleSortChange}
          data={state.tableData}
        >
          <DataTableColumn
            sortable
            field={INPUT_NAMES.DOMAIN}
            title={formatMessage('domain')}
            cell={({ dataItem }: { dataItem: LinkedDomainPolicy }) => (
              <Cell>
                {!dataItem.id && (
                  <TextField
                    fullWidth
                    defaultValue={state.formState.domain}
                    inputRef={state.form.fields.domain.ref}
                    onChange={eventHandlers.handleOnInputChange}
                    name={INPUT_NAMES.DOMAIN}
                    error={!!state.error}
                    helperText={state.error ? formatMessage(state.error) : ''}
                    inputProps={{ style: { padding: 8 } }}
                    data-testid="new-domain-field"
                  />
                )}
                {dataItem.id && (
                  <Typography variant="body2" data-testid="domain-field">
                    {dataItem.domain}
                  </Typography>
                )}
              </Cell>
            )}
          />
          <DataTableColumn
            sortable
            field={SELECT_NAMES.POLICY}
            title={formatMessage('policy')}
            cell={({ dataItem }: { dataItem: LinkedDomainPolicy }) => (
              <Cell>
                {dataItem.id === '' && (
                  <Select
                    variant="outlined"
                    className={inboundClasses.smallDropdown}
                    name={SELECT_NAMES.POLICY}
                    defaultValue={state.formState.policy}
                    onChange={eventHandlers.handleOnSelectChange}
                    SelectDisplayProps={{ style: { padding: 8 } }}
                    data-testid="new-policy-field"
                  >
                    {state.intentActions.map(policyValue => (
                      <MenuItem key={policyValue} value={policyValue}>
                        {formatMessage(policyValue)}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                {dataItem.id !== '' && (
                  <Typography variant="body2" data-testid="policy-field" className={inboundClasses.flexWrapper}>
                    {dataItem.policy === IntentDomainPolicies.IGNORE && (
                      <RemoveCircle className={inboundClasses.ignore} />
                    )}
                    {dataItem.policy === IntentDomainPolicies.BLOCK && <Block className={inboundClasses.block} />}
                    {dataItem.policy === IntentDomainPolicies.QUARANTINE && (
                      <Warning className={inboundClasses.warningColor} />
                    )}
                    {formatMessage(dataItem.policy as string)}
                  </Typography>
                )}
              </Cell>
            )}
          />
          <DataTableColumn
            sortable
            field={INPUT_NAMES.COMMENT}
            title={formatMessage('comment')}
            cell={({ dataItem }: { dataItem: CustomRblsParsed }) => (
              <Cell data-testid="comment-cell" className={inboundClasses.smallCell}>
                {!dataItem.id && (
                  <TextField
                    fullWidth
                    defaultValue={state.formState.comment}
                    inputRef={state.form.fields.comment.ref}
                    onChange={eventHandlers.handleOnInputChange}
                    name={INPUT_NAMES.COMMENT}
                    inputProps={{ style: { padding: 8 } }}
                    data-testid="new-comment-field"
                  />
                )}
                {dataItem.id && (
                  <Typography variant="body2" data-testid="comment-field">
                    {dataItem.comment}
                  </Typography>
                )}
              </Cell>
            )}
          />
          <DataTableColumn
            field="actions"
            title={formatMessage('actions')}
            sortable={false}
            cell={({ dataItem }: { dataItem: CustomRblsParsed }) => (
              <Cell data-testid="action-cell" className={inboundClasses.smallCell}>
                {!dataItem.id && (
                  <Button
                    color="primary"
                    onClick={() => {
                      eventHandlers.onAdd()
                    }}
                    variant="text"
                    data-testid="add-button"
                  >
                    {formatMessage('add')}
                  </Button>
                )}
                {dataItem.id && (
                  <Button
                    color="primary"
                    onClick={() => {
                      eventHandlers.onRemove(dataItem.id)
                    }}
                    variant="text"
                    data-testid="remove-button"
                  >
                    {formatMessage('remove')}
                  </Button>
                )}
              </Cell>
            )}
          />
        </DataTable>
        {!!state.idToRemove && (
          <WarningDialog
            title={formatDialogMessage('confirm_removal')}
            onConfirm={eventHandlers.onConfirmRemove}
            onCancel={eventHandlers.onConfirmCancel}
          />
        )}
      </Grid>
    ),
    [formatMessage, formatDialogMessage, inboundClasses, state, eventHandlers]
  )
}

export default AntiPhishingTable
