/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react'
import { Grid, Switch, SwitchLabel, Typography } from '@barracuda-internal/bds-core'
import { useUiSwitcherLogic } from 'components/libs/uiSwitcher/useUiSwitcherLogic'
import { useFormatMessage } from 'lib/localization'
import styles from 'components/libs/uiSwitcher/uiSwitcherStyles'

const BASE_I18N_KEY = 'ess.ui_switcher'

const UiSwitcher = () => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [state, eventHandlers] = useUiSwitcherLogic()

  return useMemo(() => {
    if (!state.show) {
      return null
    }

    return (
      <Grid className={classes.contentWrapper} container justifyContent="flex-end">
        <SwitchLabel
          labelPlacement="start"
          control={<Switch size="small" checked onChange={eventHandlers.onSwitchToOldUi} color="primary" />}
          label={
            <Typography variant="body1">
              <b>{formatMessage('new_interface')}</b>
            </Typography>
          }
        />
      </Grid>
    )
  }, [classes, eventHandlers, state, formatMessage])
}

export default UiSwitcher
