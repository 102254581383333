import { colors, makeStyles } from '@barracuda-internal/bds-core'

export default makeStyles(theme => ({
  modal: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: 800,
    maxHeight: '80vh',
    margin: 'auto'
  },
  modalTitle: {
    backgroundColor: colors.blue700,
    color: colors.white,
    fontSize: '20px',
    fontWeight: 'bold',
    padding: '0px 20px',
    borderRadius: '4px 4px 0px 0px'
  },
  closeIcon: {
    marginTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    color: colors.white
  },
  title: {
    backgroundColor: colors.white,
    color: colors.scaleGray800,
    fontSize: 20,
    height: 20,
    fontWeight: 400,
    lineHeight: '20px'
  },
  subtitle: {
    fontSize: 11,
    backgroundColor: colors.hoverGray,
    borderBottom: `1px solid ${colors.uiElement}`,
    padding: theme.spacing(0.75),
    display: 'flex',
    justifyContent: 'space-between'
  },
  icon: {
    marginBottom: -7
  },
  inlineImg: {
    width: 24,
    height: 24,
    marginBottom: -7
  },
  linkWrapper: {
    display: 'flex'
  },
  linkSeparator: {
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5)
  },
  link: {
    cursor: 'pointer'
  },
  redText: {
    color: colors.red600
  },
  blueText: {
    color: colors.blue700
  },
  quote: {
    backgroundColor: colors.scaleGray050,
    border: `1px solid ${colors.scaleGray100}`,
    color: colors.black,
    margin: '0 0 10px',
    padding: '5px 20px'
  },
  noListStyle: {
    listStyleType: 'none !important',
    '& li': {
      listStyle: 'none !important'
    }
  },
  warnNotice: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.75),
    backgroundColor: colors.yellow050,
    borderColor: colors.yellow100,
    whiteSpace: 'pre-wrap'
  },
  errorNotice: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.75),
    backgroundColor: colors.red100,
    borderColor: colors.red200,
    whiteSpace: 'pre-wrap'
  },
  noticeIcon: {
    marginRight: theme.spacing(1),
    width: '16px',
    height: '16px'
  },
  content: {
    // stylings for the page content
    padding: theme.spacing(1),
    color: colors.coastalSand700,
    '& h3': {
      fontSize: '1.3em',
      fontWeight: 'normal',
      color: colors.blue700,
      paddingBottom: theme.spacing(1.13),
      margin: 0
    },
    '& p': {
      fontSize: 12,
      color: colors.coastalSand700,
      padding: 0,
      lineHeight: '1.45em',
      margin: '0 0 10px'
    },
    '& ul': {
      margin: '0 0 10px',
      padding: '0 0 0 25px'
    },
    '& li': {
      fontSize: 12,
      margin: 0,
      padding: '0 0 5px'
    },
    '& ul li': {
      listStyle: 'disc'
    },
    '& a': {
      fontSize: 12,
      color: colors.blue700,
      outline: 0,
      textDecoration: 'none'
    },
    '& b': {
      fontWeight: 700
    }
  },
  modalCard: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  modalContent: {
    // stylings for the modal content
    overflow: 'auto',
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    paddingBottom: '0 !important',
    fontSize: 14,
    lineHeight: '1.45em',
    color: colors.coastalSand700,
    '& h3': {
      fontSize: 18,
      fontWeight: 700,
      color: colors.blue700,
      borderBottom: `1px solid ${colors.uiElement}`,
      margin: '0 0 2px',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1.13)
    },
    '& p': {
      fontSize: 14,
      color: colors.coastalSand700,
      padding: 0,
      lineHeight: '1.45em',
      margin: '0 0 10px'
    },
    '& ul': {
      margin: '0 0 10px',
      padding: '0 0 0 25px'
    },
    '& li': {
      fontSize: 14,
      margin: 0,
      padding: '0 0 5px'
    },
    '& ul li': {
      listStyle: 'disc'
    },
    '& a': {
      fontSize: 14,
      color: colors.blue700,
      outline: 0,
      textDecoration: 'none'
    },
    '& b': {
      fontWeight: 700
    }
  }
}))
