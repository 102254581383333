import React, { useMemo } from 'react'
import { useAppSelector } from 'redux/toolkit/hooks'
import routesConfig from 'lib/routesConfig'
import AppFooter from 'components/libs/layout/footer/appFooter/AppFooter'
import LoginFooter from 'components/libs/layout/footer/loginFooter/LoginFooter'

function Footer() {
  const { activePath } = useAppSelector(_store => ({
    activePath: _store.app.activePath
  }))

  const isLoginFooter = useMemo(
    () => [routesConfig.LOGIN?.id, routesConfig.RESET_PASSWORD?.id].includes(activePath.id || ''),
    [activePath.id]
  )

  // TODO: fix typing of routesConfig as in different app types the route may be undefined
  return useMemo(() => (isLoginFooter ? <LoginFooter /> : <AppFooter />), [isLoginFooter])
}

export default Footer
