import React, { useMemo } from 'react'

import { Box, Grid, Grow, IconButton, MenuItem, Paper, Popper, Select, TextField } from '@barracuda-internal/bds-core'
import { Close, Search } from '@barracuda-internal/bds-core/dist/Icons/Core'
import { Tune } from '@material-ui/icons'

import { useFormatMessage } from 'lib/localization'
import { onKeyDown } from 'lib/keyEvents'
import { presetDateItems } from 'config/presetMenuItems'

import OutlinedDiv from 'components/libs/outlinedDiv/OutlinedDiv'
import AdvancedSearch from 'components/pages/messageLog/messageLogSearchBar/advancedSearch/AdvancedSearch'
import styles from 'components/pages/messageLog/messageLogSearchBar/messageLogSearchBarStyles'
import { useMessageLogSearchBarLogic } from 'components/pages/messageLog/messageLogSearchBar/useMessageLogSearchBarLogic'
import { ActionTaken, MessageDirection } from 'types/Messages'

const BASE_I18N_KEY = 'ess.message_log'

const MessageLogSearchbar = () => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const {
    searchTerm,
    searchField,
    advancedSearch,
    dateField,
    actionTakenField,
    statusField,
    reasonField,
    handleSearch,
    handleFilterChange,
    disableSearch,
    domainsField,
    permissions,
    messageDirectionField,
    viewConfig
  } = useMessageLogSearchBarLogic()

  return useMemo(
    () => (
      <Grid
        container
        className={classes.outerWrapper + (disableSearch ? ` ${classes.disableBtn}` : '')}
        data-testid="root-grid"
      >
        <Grid item className={classes.searchToolWrapper}>
          <TextField
            className={classes.searchTextField}
            label={formatMessage('search_bar.search')}
            placeholder={formatMessage('search_bar.find_hint')}
            variant="outlined"
            value={searchTerm.search_str}
            onChange={searchField.handleInputChange}
            innerRef={searchField.inputElement}
            onKeyDown={onKeyDown(['Enter'], () => handleSearch())}
            disabled={advancedSearch.openAdvancedSearch}
            InputProps={{
              startAdornment: (
                <IconButton
                  size="small"
                  edge="start"
                  data-id="search-button"
                  data-testid="search-button"
                  onClick={() => handleSearch()}
                >
                  <Search />
                </IconButton>
              ),
              endAdornment: (
                <>
                  {searchTerm.search_str.length > 0 && (
                    <IconButton
                      size="small"
                      edge="end"
                      data-id="search-bar-clear-button"
                      data-testid="clear-button"
                      onClick={searchField.handleClearTerm}
                    >
                      <Close />
                    </IconButton>
                  )}
                  {!advancedSearch.openAdvancedSearch && (
                    <IconButton
                      size="small"
                      edge="end"
                      data-id="adv-search-start-button"
                      data-testid="advanced-button"
                      onClick={() => advancedSearch.setOpenAdvancedSearch(!advancedSearch.openAdvancedSearch)}
                    >
                      <Tune />
                    </IconButton>
                  )}
                </>
              )
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{ style: { paddingTop: 15, paddingBottom: 15, paddingRight: 4, paddingLeft: 4 } }}
            data-testid="search-input"
          />
          <Popper
            className={classes.advancedSearch}
            open={advancedSearch.openAdvancedSearch}
            anchorEl={searchField.inputElement.current}
            transition
            modifiers={{
              offset: {
                enabled: true,
                offset: '0, 8'
              }
            }}
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'left top' : 'center bottom'
                }}
              >
                <Paper>
                  <AdvancedSearch onClose={() => advancedSearch.setOpenAdvancedSearch(false)} />
                </Paper>
              </Grow>
            )}
          </Popper>
          {permissions.canFilterAboutDomains && (
            <OutlinedDiv label={formatMessage('domain_menu.label')}>
              <Select
                className={`${classes.selectMenus} ${classes.selectMenusWide}`}
                disabled={domainsField.disabled}
                name="domain_id"
                data-id="domains-dropdown"
                value={domainsField.selectedItem || ''}
                onChange={domainsField.handleDomainChange}
                data-testid="domain-select"
                displayEmpty
              >
                {domainsField.searchAllEnabled && (
                  <MenuItem key="all" className={`${classes.menuItem}`} value="">
                    All domains
                  </MenuItem>
                )}
                {domainsField.menuItems.map(({ domainId, domainName }) => (
                  <MenuItem key={domainId} className={`${classes.menuItem}`} value={domainId}>
                    {domainName}
                  </MenuItem>
                ))}
              </Select>
            </OutlinedDiv>
          )}
          {viewConfig.filters.showMessageDirection && permissions.canFilterAboutDirections && (
            <OutlinedDiv label={formatMessage('message_direction_menu.label')}>
              <Select
                className={`${classes.selectMenus} ${classes.selectMenusWide}`}
                name="outbound"
                data-id="message-direction-dropdown"
                value={messageDirectionField.selectedItem}
                onChange={messageDirectionField.handleMessageDirectionChange}
                data-testid="message-direction-select"
                displayEmpty
              >
                <MenuItem className={`${classes.menuItem}`} value={MessageDirection.INBOUND}>
                  {formatMessage('message_direction_menu.inbound')}
                </MenuItem>
                <MenuItem className={`${classes.menuItem}`} value={MessageDirection.OUTBOUND}>
                  {formatMessage('message_direction_menu.outbound')}
                </MenuItem>
              </Select>
            </OutlinedDiv>
          )}
        </Grid>
        <Grid item className={classes.searchToolWrapper}>
          <Box className={classes.filtersWrapper}>
            {viewConfig.filters.showDatePresets && (
              <OutlinedDiv label={formatMessage('preset_menu.label')}>
                <Select
                  className={classes.selectMenus}
                  name="presets"
                  data-id="date-present-dropdown"
                  value={searchTerm.dateRange ? searchTerm.dateRange : 0}
                  onChange={dateField.handleDateChange}
                  data-testid="date-select"
                >
                  {presetDateItems.map(({ value, label }) => (
                    <MenuItem
                      key={label}
                      className={`${classes.menuItem} ${value === 0 ? 'custom' : ''}`}
                      value={value}
                    >
                      {formatMessage(`preset_menu.${label}`)}
                    </MenuItem>
                  ))}
                </Select>
              </OutlinedDiv>
            )}
            {viewConfig.filters.showAction && (
              <OutlinedDiv label={formatMessage('action.label')}>
                <Select
                  className={`${classes.selectMenus} ${classes.selectMenusWide}`}
                  name="action"
                  data-id="action-taken-dropdown"
                  value={actionTakenField.selectedItem}
                  onChange={handleFilterChange}
                  data-testid="action-select"
                >
                  {actionTakenField.menuItems.map((label: string, idx: number) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <MenuItem value={label} key={idx}>
                      {formatMessage(`action.${label}`)}
                    </MenuItem>
                  ))}
                </Select>
              </OutlinedDiv>
            )}
            {viewConfig.filters.showStatus && (
              <OutlinedDiv label={formatMessage('status.label')}>
                <Select
                  disabled={actionTakenField.selectedItem === ActionTaken.emailContinuity}
                  className={`${classes.selectMenus} ${classes.selectMenusWide}`}
                  name="delivery_status"
                  data-id="delivery-status-dropdown"
                  value={statusField.selectedItem}
                  onChange={handleFilterChange}
                  data-testid="delivery-select"
                >
                  {statusField.menuItems.map((label: string, idx: number) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <MenuItem value={label} key={idx}>
                      {formatMessage(`status.${label}`)}
                    </MenuItem>
                  ))}
                </Select>
              </OutlinedDiv>
            )}
            {viewConfig.filters.showReason && (
              <OutlinedDiv label={formatMessage('reason.label')}>
                <Select
                  disabled={actionTakenField.selectedItem === ActionTaken.emailContinuity}
                  className={`${classes.selectMenus} ${classes.selectMenusWide}`}
                  name="reason"
                  data-id="reason-dropdown"
                  value={reasonField.selectedItem}
                  onChange={handleFilterChange}
                  data-testid="reason-select"
                >
                  {reasonField.menuItems.map((label: string, idx: number) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <MenuItem value={label} key={idx}>
                      {formatMessage(`reason.${label}`)}
                    </MenuItem>
                  ))}
                </Select>
              </OutlinedDiv>
            )}
          </Box>
        </Grid>
      </Grid>
    ),
    [
      classes,
      disableSearch,
      formatMessage,
      searchTerm,
      searchField,
      advancedSearch,
      permissions,
      domainsField,
      dateField,
      actionTakenField,
      handleFilterChange,
      statusField,
      reasonField,
      handleSearch,
      messageDirectionField,
      viewConfig
    ]
  )
}

export default MessageLogSearchbar
