import React, { PropsWithChildren, ReactElement, useMemo } from 'react'

import { Grid } from '@barracuda-internal/bds-core'
import { GridSize } from '@material-ui/core'

import { FormText } from 'components/libs/layout/pages/FormText'

export type FormRowProps = {
  rowTitle: string | ReactElement
  alignTitleToTop?: boolean
  contentIndent?: GridSize
}

export const FormRow = ({
  alignTitleToTop = false,
  rowTitle,
  contentIndent = 3,
  children
}: PropsWithChildren<FormRowProps>) =>
  useMemo(
    () => (
      <Grid container alignItems={alignTitleToTop ? 'flex-start' : 'center'}>
        <Grid item xs={contentIndent}>
          <FormText>{rowTitle}</FormText>
        </Grid>
        <Grid item xs>
          {children}
        </Grid>
      </Grid>
    ),
    [rowTitle, contentIndent, children, alignTitleToTop]
  )
