import { useCallback, useEffect, useMemo, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { BCCAccount } from 'types/auth'
import { switchAccount } from 'redux/features/auth/authSlice'
import { isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import { setErrorSnackBar } from 'redux/features/app/appSlice'

export interface State {
  accounts?: BCCAccount[]
  accountSwitchingInProgress: boolean
  initCCAccountId?: string
  selectedAccount?: BCCAccount
}

export interface EventHandlers {
  onSelectNewAccount: (newSelectedAccoount: BCCAccount) => void
}

export const useAccountSwitcherLogic = (): [State, EventHandlers] => {
  const dispatch = useAppDispatch()
  const {
    accounts,
    selectedBCCAccountId,
    accountSwitchingInProgress,
    accountSwitchIsSuccess,
    accountSwitchIsFailed,
    switchAccountResponse
  } = useAppSelector(_store => ({
    accounts: _store.auth.accessTokenObject?.userInfo?.accounts,
    selectedBCCAccountId: _store.auth.accessTokenObject?.bccAccountId,
    accountSwitchingInProgress: isPending(_store.auth.api.switchAccountApiStatus),
    accountSwitchIsSuccess: isSuccess(_store.auth.api.switchAccountApiStatus),
    accountSwitchIsFailed: isFailed(_store.auth.api.switchAccountApiStatus),
    switchAccountResponse: _store.auth.switchAccount
  }))
  const [selectedAccount, setSelectedAccount] = useState<BCCAccount | undefined>()

  useEffect(() => {
    if (selectedAccount) {
      dispatch(switchAccount(selectedAccount.id))
    }
  }, [selectedAccount, dispatch])

  // account switch is success
  useEffect(() => {
    if (accountSwitchIsSuccess && selectedAccount && !!switchAccountResponse?.success) {
      if (switchAccountResponse.newRegionUrl) {
        // Switch to the url provided for the selected account
        window.location.href = switchAccountResponse.newRegionUrl
      } else {
        window.location.reload()
      }
    }
  }, [dispatch, accountSwitchIsSuccess, selectedAccount, switchAccountResponse])

  // account switch is failed
  useEffect(() => {
    if (selectedAccount && (accountSwitchIsFailed || (accountSwitchIsSuccess && !switchAccountResponse?.success))) {
      dispatch(setErrorSnackBar({ message: 'account_switch_failed', params: [selectedAccount.name] }))
      setSelectedAccount(undefined)
    }
  }, [dispatch, accountSwitchIsFailed, accountSwitchIsSuccess, selectedAccount, switchAccountResponse])

  const onSelectNewAccount = useCallback(
    (newSelectedAccoount: BCCAccount) => {
      const newAccount = accounts?.find(account => account.id === newSelectedAccoount.id)

      if (newAccount && selectedAccount?.id !== newAccount.id) {
        setSelectedAccount(newAccount)
      }
    },
    [accounts, selectedAccount]
  )

  return useMemo(
    () => [
      {
        accounts,
        initCCAccountId: selectedBCCAccountId,
        accountSwitchingInProgress: accountSwitchingInProgress || accountSwitchIsSuccess,
        selectedAccount
      },
      {
        onSelectNewAccount
      }
    ],
    [
      accounts,
      selectedBCCAccountId,
      onSelectNewAccount,
      accountSwitchingInProgress,
      accountSwitchIsSuccess,
      selectedAccount
    ]
  )
}
