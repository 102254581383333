import { useMemo } from 'react'
import { useAppSelector } from 'redux/toolkit/hooks'
import appFeatures from 'config/appFeatures'
import { allRoutesConfig, UiRoute } from 'lib/routesConfig'
import { AppTypes } from 'types/AppTypes'

export interface UseAppTypeEntryPaths {
  appTypeEntryPath: UiRoute
  appTypeLoginEntryPath: UiRoute
}

export const ENTRY_PATHS = {
  [AppTypes.enduser]: allRoutesConfig.MESSAGE_LOG,
  [AppTypes.admin]: allRoutesConfig.OVERVIEW_DASHBOARD,
  [AppTypes.wizard]: allRoutesConfig.ONBOARD_WIZARD,
  [AppTypes.cudaspt]: allRoutesConfig.CUDASPT_ACCOUNTS,
  [AppTypes.helpdesk]: allRoutesConfig.MESSAGE_LOG,
  [AppTypes.domain]: allRoutesConfig.MESSAGE_LOG,
  [AppTypes.pdDomainId]: allRoutesConfig.OVERVIEW_DASHBOARD,
  [AppTypes.domainWithPdDomainId]: allRoutesConfig.OVERVIEW_DASHBOARD,
  [AppTypes.helpdeskWithPdDomainId]: allRoutesConfig.OVERVIEW_DASHBOARD,
  [AppTypes.cplAccount]: allRoutesConfig.OVERVIEW_DASHBOARD
} as { [key: string]: UiRoute }

export const LOGIN_ENTRY_PATHS = {
  [AppTypes.enduser]: allRoutesConfig.LOGIN,
  [AppTypes.admin]: allRoutesConfig.BCC_LOGIN,
  [AppTypes.wizard]: appFeatures.EnabledWizardBccLogin ? allRoutesConfig.BCC_LOGIN : allRoutesConfig.LOGIN,
  [AppTypes.cudaspt]: allRoutesConfig.CUDASPT_LOGIN,
  [AppTypes.helpdesk]: allRoutesConfig.LOGIN,
  [AppTypes.domain]: allRoutesConfig.LOGIN,
  [AppTypes.pdDomainId]: allRoutesConfig.LOGIN,
  [AppTypes.domainWithPdDomainId]: allRoutesConfig.LOGIN,
  [AppTypes.helpdeskWithPdDomainId]: allRoutesConfig.LOGIN,
  [AppTypes.cplAccount]: allRoutesConfig.BCC_LOGIN
} as { [key: string]: UiRoute }

export default function useAppTypeEntryPaths(): UseAppTypeEntryPaths {
  const { appType } = useAppSelector(_store => ({
    appType: _store.app.appType
  }))

  return useMemo(
    () => ({
      appTypeEntryPath: ENTRY_PATHS[appType],
      appTypeLoginEntryPath: LOGIN_ENTRY_PATHS[appType]
    }),
    [appType]
  )
}
