import React, { useMemo } from 'react'

import {
  Alert,
  Button,
  CircularProgress,
  DataTable,
  DataTableColumn,
  Grid,
  LinearProgress,
  Select,
  SelectLabel,
  SelectMenuItem,
  SelectWrapper,
  Typography
} from '@barracuda-internal/bds-core'
import { RemoveCircle } from '@barracuda-internal/bds-core/dist/Icons/Core'
import {
  Checkbox as CheckboxIcon,
  CheckboxSelected as CheckboxSelectedIcon
} from '@barracuda-internal/bds-core/dist/Icons/Controls'
import { Success as SuccessCheckmark } from '@barracuda-internal/bds-core/dist/Icons/Feedback'
import { GridNoRecords } from '@progress/kendo-react-grid'

import { useFormatMessage } from 'lib/localization'
import { AccountUserRole, QuarantineStatus } from 'types/users'
import { Cell } from 'components/libs/grid/cell'
import useContentFitToBottom from 'lib/useContentFitToBottom'

import UsersListSupport from 'components/pages/support/help/usersList/UsersList'
import { GROUP_TITLE, TOPICS, useSupportModalLogic } from 'components/pages/support/useSupportModalLogic'

import EditUserDialog from 'components/pages/users/usersList/editUserDialog/EditUserDialog'
import DeleteUserDialog from 'components/pages/users/usersList/deleteUserDialog/DeleteUserDialog'
import { ModifiedAccountUser, UserActions, useUsersListLogic } from 'components/pages/users/usersList/useUsersListLogic'
import styles from 'components/pages/users/usersList/usersListStyles'
import { ALL_DOMAINS } from 'components/pages/overview/dashboard/useDashboardLogic'
import MaskLoader from 'components/libs/contentLoader/MaskLoader'
import routesConfig from 'lib/routesConfig'
import { PageContainer, PageTitle, SearchField } from 'components/libs/layout/pages/pageLayout'

const BASE_I18N_KEY = 'ess.users.users_list'

const UsersList: React.FC = () => {
  const [state, eventHandlers] = useUsersListLogic()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()
  const [tableRef, tableHeight] = useContentFitToBottom({ startingHeight: 700, bottomMargin: 24 })
  const [onOpenSupport, HelpModal] = useSupportModalLogic({
    content: UsersListSupport,
    title: TOPICS.usersList,
    groupTitle: GROUP_TITLE.userTopics
  })

  return useMemo(
    () => (
      <>
        <MaskLoader
          open={!!state.selectedUserForLoginAs}
          fullPage
          message={`${formatMessage('logging_you_as', { user: state.selectedUserForLoginAs?.userId })}`}
        />
        <Grid container direction="column">
          {state.formAlert && (
            <Alert severity={state.formAlert.severity} onClose={() => eventHandlers.resetAlert(undefined)}>
              {formatMessage(`alerts.${state.formAlert.message}`, {
                ...state.formAlert.params,
                b: (txt: string) => <b>{txt}</b>
              })}
            </Alert>
          )}

          <PageContainer>
            <HelpModal />
            <PageTitle title={formatMessage('title')} onOpenSupport={onOpenSupport}>
              {!state.isInitialLoad && (
                <>
                  <Button
                    color="primary"
                    disabled={state.isAddUpdateUsersButtonDisabled}
                    className={classes.addUpdateUsersButton}
                    variant="contained"
                    onClick={() => routesConfig.USERS_ADD_UPDATE_USERS.goto()}
                  >
                    {formatMessage('add_update_users')}
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={!state.isBulkDeleteUsersButtonEnabled}
                    onClick={eventHandlers.onDeleteSelectedUsers}
                  >
                    {formatMessage('delete_users')}
                  </Button>
                </>
              )}
            </PageTitle>
          </PageContainer>
          {state.isInitialLoad && <LinearProgress data-testid="loading-state" />}

          {!state.isInitialLoad && (
            <Grid container data-testid="users-list-content" direction="column">
              <Grid className={classes.selectorsWrapper} container direction="row" alignItems="center">
                <Grid className={classes.searchFieldWrapper} item xs>
                  <SearchField
                    value={state.search}
                    onChange={eventHandlers.onChangeSearch}
                    onSearch={eventHandlers.onSearch}
                    onReset={eventHandlers.onResetSearch}
                    placeholder={formatMessage('search_placeholder')}
                  />
                </Grid>

                <Grid item>
                  <SelectWrapper className={classes.userRoleSelector} variant="outlined">
                    <SelectLabel>{formatMessage('user_role')}</SelectLabel>
                    <Select
                      data-testid="user-role-selector-action"
                      label={formatMessage('user_role')}
                      onChange={eventHandlers.onChangeTableConfig('userRoleFilter')}
                      value={state.tableConfig.userRoleFilter}
                    >
                      {Object.values(AccountUserRole).map(userRole => (
                        <SelectMenuItem data-testid={userRole} key={userRole} value={userRole}>
                          {formatMessage(`user_roles.${userRole}`)}
                        </SelectMenuItem>
                      ))}
                    </Select>
                  </SelectWrapper>
                </Grid>

                <Grid data-testid="domain-selector" item>
                  <SelectWrapper className={classes.domainSelector} variant="outlined">
                    <SelectLabel>{formatMessage('domains')}</SelectLabel>
                    <Select
                      data-testid="domain-selector-action"
                      label={formatMessage('domains')}
                      disabled={state.isDomainsSelectDisabled}
                      onChange={eventHandlers.onChangeTableConfig('domainFilter')}
                      value={state.tableConfig.domainFilter}
                    >
                      <SelectMenuItem data-testid="select-all-domains" value={ALL_DOMAINS}>
                        {formatMessage('all')}
                      </SelectMenuItem>
                      {state.domains?.map(domain => (
                        <SelectMenuItem data-testid={domain.domainId} key={domain.domainId} value={domain.domainName}>
                          {domain.domainName}
                        </SelectMenuItem>
                      ))}
                    </Select>
                  </SelectWrapper>
                </Grid>
              </Grid>

              <Grid ref={tableRef} item className={classes.tableWrapper}>
                {state.isGetUsersListInProgress && (
                  <LinearProgress className={classes.loadingIndicator} data-testid="linear-progress" />
                )}
                <DataTable
                  style={{ height: tableHeight }}
                  pageConfig={{
                    pageable: { pageSizes: [50] },
                    skip: state.tableConfig.skip,
                    take: state.tableConfig.take,
                    total: state.accountUsers?.count || 0
                  }}
                  onPageChange={eventHandlers.onPageChange}
                  onSortChange={eventHandlers.onSortChange}
                  data={state.tableData}
                  selectedField="selected"
                  resizable
                  sortable={{ allowUnsort: false, mode: 'single' }}
                  sort={state.tableConfig.sort}
                >
                  <GridNoRecords>
                    {!state.isGetUsersListInProgress && !state.accountUsers?.users.length ? (
                      <Typography className={classes.noRecords} variant="subtitle1">
                        {formatMessage('no_records_available')}
                      </Typography>
                    ) : (
                      <Typography className={classes.noRecords} variant="subtitle1">
                        {formatMessage('loading')}
                      </Typography>
                    )}
                  </GridNoRecords>
                  <DataTableColumn
                    width={42}
                    field={state.tableConfig.GRID_COLUMNS.SELECT}
                    {...state.tableConfig.columnsConfig[state.tableConfig.GRID_COLUMNS.SELECT]}
                    title={' '}
                    headerCell={() => (
                      <Grid
                        className={classes.checkboxHeaderCell}
                        onClick={() => {
                          if (state.canDeleteAccountUser) {
                            eventHandlers.onSelectAllUserIds()
                          }
                        }}
                        role="button"
                      >
                        {state.isAllUserIdSelected ? (
                          <CheckboxSelectedIcon className={classes.checkedRow} />
                        ) : (
                          <CheckboxIcon />
                        )}
                      </Grid>
                    )}
                    cell={({ dataItem }: { dataItem: ModifiedAccountUser }) => (
                      <Cell
                        onClick={() => {
                          if (state.canDeleteAccountUser) {
                            eventHandlers.onSelectUserId(dataItem.userId)
                          }
                        }}
                        className={classes.selectColumn}
                        data-testid="user-id-cell"
                      >
                        {state.isUserIdSelected(dataItem.userId) ? (
                          <CheckboxSelectedIcon className={classes.checkedRow} />
                        ) : (
                          <CheckboxIcon />
                        )}
                      </Cell>
                    )}
                  />
                  <DataTableColumn
                    field={state.tableConfig.GRID_COLUMNS.USER_ID}
                    {...state.tableConfig.columnsConfig[state.tableConfig.GRID_COLUMNS.USER_ID]}
                    title={formatMessage(`table.${state.tableConfig.GRID_COLUMNS.USER_ID}`)}
                    cell={({ dataItem }: { dataItem: ModifiedAccountUser }) => (
                      <Cell
                        className={classes.interactiveCell}
                        onClick={() => {
                          if (state.canDeleteAccountUser) {
                            eventHandlers.onSelectUserId(dataItem.userId)
                          }
                        }}
                        data-testid="user-id-cell"
                      >
                        <Typography className={classes.ellipsisText} variant="body2">
                          {dataItem.userId}
                        </Typography>
                      </Cell>
                    )}
                  />
                  <DataTableColumn
                    field={state.tableConfig.GRID_COLUMNS.LINKED_ACCOUNTS}
                    {...state.tableConfig.columnsConfig[state.tableConfig.GRID_COLUMNS.LINKED_ACCOUNTS]}
                    title={formatMessage(`table.${state.tableConfig.GRID_COLUMNS.LINKED_ACCOUNTS}`)}
                    cell={({ dataItem }: { dataItem: ModifiedAccountUser }) => (
                      <Cell
                        onClick={() => eventHandlers.onToggleRowExpand(dataItem.userId)}
                        data-testid="linked-accounts--cell"
                      >
                        <Typography
                          className={
                            classes[state.expandedRows.includes(dataItem.userId) ? 'expandedRow' : 'nonExpandedRow']
                          }
                          variant="body2"
                        >
                          {dataItem.linkedAccountsString.length ? dataItem.linkedAccountsString : '-'}
                        </Typography>
                      </Cell>
                    )}
                  />
                  <DataTableColumn
                    width={100}
                    field={state.tableConfig.GRID_COLUMNS.SOURCE}
                    {...state.tableConfig.columnsConfig[state.tableConfig.GRID_COLUMNS.SOURCE]}
                    title={formatMessage(`table.${state.tableConfig.GRID_COLUMNS.SOURCE}`)}
                    cell={({ dataItem }: { dataItem: ModifiedAccountUser }) => (
                      <Cell data-testid="source-cell">
                        <Typography className={classes.ellipsisText} variant="body2">
                          {formatMessage(`sources.${dataItem.source}`)}
                        </Typography>
                      </Cell>
                    )}
                  />
                  <DataTableColumn
                    width={180}
                    field={state.tableConfig.GRID_COLUMNS.ROLE}
                    {...state.tableConfig.columnsConfig[state.tableConfig.GRID_COLUMNS.ROLE]}
                    title={formatMessage(`table.${state.tableConfig.GRID_COLUMNS.ROLE}`)}
                    cell={({ dataItem }: { dataItem: ModifiedAccountUser }) => (
                      <Cell className={classes.ellipsisCell} data-testid="role-cell">
                        <Typography className={classes.ellipsisText} variant="body2">
                          {formatMessage(`user_roles.${dataItem.role}`)}
                        </Typography>
                      </Cell>
                    )}
                  />
                  <DataTableColumn
                    width={150}
                    field={state.tableConfig.GRID_COLUMNS.QUARANTINE}
                    {...state.tableConfig.columnsConfig[state.tableConfig.GRID_COLUMNS.QUARANTINE]}
                    title={formatMessage(`table.${state.tableConfig.GRID_COLUMNS.QUARANTINE}`)}
                    cell={({ dataItem }: { dataItem: ModifiedAccountUser }) => (
                      <Cell data-testid="quarantine-cell">
                        {dataItem.quarantine === QuarantineStatus.loading && (
                          <CircularProgress className={classes.loadingState} />
                        )}
                        {dataItem.quarantine !== QuarantineStatus.loading && (
                          <Typography className={classes.ellipsisText} variant="body2">
                            {dataItem.quarantine === QuarantineStatus.enabled && (
                              <SuccessCheckmark className={classes.enabledIcon} />
                            )}
                            {dataItem.quarantine === QuarantineStatus.disabled && (
                              <RemoveCircle className={classes.disabledIcon} />
                            )}
                            &nbsp;
                            {formatMessage(
                              `quarantine.${dataItem.quarantine === QuarantineStatus.enabled ? 'enabled' : 'disabled'}`
                            )}
                          </Typography>
                        )}
                      </Cell>
                    )}
                  />
                  <DataTableColumn
                    width={420}
                    field={state.tableConfig.GRID_COLUMNS.ACTIONS}
                    {...state.tableConfig.columnsConfig[state.tableConfig.GRID_COLUMNS.ACTIONS]}
                    title={formatMessage(`table.${state.tableConfig.GRID_COLUMNS.ACTIONS}`)}
                    cell={({ dataItem }: { dataItem: ModifiedAccountUser }) => (
                      <Cell className={classes.inlineCell} data-testid="quarantine-cell">
                        <Button
                          disabled={!state.userActionStates[UserActions.edit]}
                          onClick={() => eventHandlers.onSelectedUserForEdit(dataItem)}
                          className={classes.actionButton}
                          color="primary"
                        >
                          {formatMessage('actions.edit')}
                        </Button>
                        <Button
                          disabled={!state.userActionStates[UserActions.resetPassword](dataItem.source)}
                          isLoading={
                            dataItem.userId === state.requestedResetPasswordUserId &&
                            state.isRequestResetPasswordInProgress
                          }
                          onClick={() => eventHandlers.onResetPassword(dataItem)}
                          className={classes.actionButton}
                          color="primary"
                        >
                          {formatMessage('actions.reset_password')}
                        </Button>
                        <Button
                          disabled={!state.userActionStates[UserActions.logInAs]}
                          onClick={() => eventHandlers.onLoginAsUser(dataItem)}
                          className={classes.actionButton}
                          color="primary"
                        >
                          {formatMessage('actions.log_in_as')}
                        </Button>
                        <Button
                          disabled={!state.userActionStates[UserActions.delete]}
                          className={classes.actionButton}
                          onClick={() => eventHandlers.onSelectedUserForDelete(dataItem)}
                          color="primary"
                        >
                          {formatMessage('actions.delete')}
                        </Button>
                      </Cell>
                    )}
                  />
                </DataTable>
              </Grid>
            </Grid>
          )}
          {!!state.selectedUserForEdit && (
            <EditUserDialog onClose={() => eventHandlers.onSelectedUserForEdit()} user={state.selectedUserForEdit} />
          )}
          {!!state.selectedUsersForDelete && (
            <DeleteUserDialog
              onClose={() => eventHandlers.onSelectedUserForDelete()}
              userIds={state.selectedUsersForDelete}
            />
          )}
        </Grid>
      </>
    ),
    [eventHandlers, classes, formatMessage, state, tableHeight, tableRef, onOpenSupport, HelpModal]
  )
}

export default UsersList
