import React, { useMemo } from 'react'

import { TextField } from '@barracuda-internal/bds-core'

import { HelperText } from 'components/libs/layout/pages/HelperText'
import styles from 'components/libs/layout/pages/pagesLayoutStyles'

const BASE_I18N_KEY = 'ess.layout'

export type InlineInputFieldProps = {
  name: string
  inputClassName?: string
  helperText?: string
  value: any
  onChange: (newValue: any) => void
  disabled?: boolean
  placeholder?: string
  error?: boolean
  rows?: number
}

export const InlineInputField = ({
  name,
  inputClassName,
  helperText,
  value,
  onChange,
  disabled,
  placeholder,
  error,
  rows = 1
}: InlineInputFieldProps) => {
  const classes = styles({ disabled })

  return useMemo(
    () => (
      <>
        <TextField
          className={`${inputClassName}`}
          value={value}
          onChange={onChange}
          disabled={disabled}
          name={name}
          error={error}
          size="small"
          data-testid={`input:${name}`}
          placeholder={placeholder}
          minRows={rows}
          maxRows={rows}
          {...(rows > 1 ? { multiline: true } : { fullWidth: true })}
        />
        {!!helperText && <HelperText>{helperText}</HelperText>}
      </>
    ),
    [inputClassName, value, onChange, disabled, name, error, placeholder, rows, helperText]
  )
}
