import React, { useMemo } from 'react'

import {
  Button,
  DataTable,
  DataTableColumn,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  SelectWrapper,
  TextField,
  Typography
} from '@barracuda-internal/bds-core'
import { Block } from '@barracuda-internal/bds-core/dist/Icons/Core'
import { Warning } from '@barracuda-internal/bds-core/dist/Icons/Feedback'

import inboundSettingsStyles from 'components/pages/inboundSettings/inboundSettingsStyles'
import { useFormatMessage } from 'lib/localization'
import {
  GEO_SELECT_NAMES,
  useGeoPoliciesTableLogic,
  UseGeoPoliciesTableLogicProps
} from 'components/pages/inboundSettings/regionalPolicies/useGeoPoliciesTableLogic'
import { GeoPolicy, GeoPolicyStatus } from 'types/Settings'
import { Cell } from 'components/libs/grid/cell'
import WarningDialog from 'components/libs/dialog/warningDialog/WarningDialog'
import { getCode3CountryName, getCountryListCode3 } from 'lib/isoCountries'
import { FormSpacer, PageSubtitle } from 'components/libs/layout/pages/pageLayout'

const BASE_I18N_KEY = 'ess.inbound_settings.regional_policies'
const DIALOG_I18N_KEY = 'ess.dialog'

const GeoPoliciesTable = (props: UseGeoPoliciesTableLogicProps) => {
  const inboundClasses = inboundSettingsStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatDialogMessage = useFormatMessage(DIALOG_I18N_KEY)

  const [state, eventHandlers] = useGeoPoliciesTableLogic(props)

  return useMemo(
    () => (
      <Grid container>
        <PageSubtitle>{formatMessage('sender_policies')}</PageSubtitle>
        <FormSpacer />
        <Grid container>
          <Typography variant="h3">{formatMessage('geo_policies')}</Typography>
          <Button
            className={inboundClasses.bulkEdit}
            onClick={eventHandlers.onBulkEdit}
            variant="contained"
            size="small"
            data-testid="bulk-button"
          >
            Bulk Edit
          </Button>
        </Grid>
        <DataTable
          sortable={{
            allowUnsort: false,
            mode: 'single'
          }}
          sort={state.sort}
          onSortChange={eventHandlers.handleSortChange}
          data={state.tableData}
        >
          <DataTableColumn
            sortable
            field="country"
            title={formatMessage('country')}
            cell={({ dataItem }: { dataItem: GeoPolicy }) => (
              <Cell>
                {dataItem.id === '' && (
                  <SelectWrapper variant="outlined" error={!!state.error}>
                    <Select
                      variant="outlined"
                      className={inboundClasses.mediumDropdown}
                      name={GEO_SELECT_NAMES.COUNTRY}
                      defaultValue={state.formState.country}
                      onChange={eventHandlers.handleOnSelectChange}
                      SelectDisplayProps={{ style: { padding: 8 } }}
                      data-testid="new-country-field"
                      error={!!state.error}
                    >
                      {getCountryListCode3().map(countryData => (
                        <MenuItem key={countryData.code} value={countryData.code}>
                          {countryData.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{state.error && formatMessage(state.error)}</FormHelperText>
                  </SelectWrapper>
                )}
                {dataItem.id !== '' && (
                  <Typography variant="body2" data-testid="country-field">
                    {getCode3CountryName(dataItem.country)}
                  </Typography>
                )}
              </Cell>
            )}
          />
          <DataTableColumn
            sortable
            field="policy"
            title={formatMessage('policy')}
            cell={({ dataItem }: { dataItem: GeoPolicy }) => (
              <Cell>
                {dataItem.id === '' && (
                  <Select
                    variant="outlined"
                    className={inboundClasses.smallDropdown}
                    name={GEO_SELECT_NAMES.POLICY}
                    defaultValue={state.formState.policy}
                    onChange={eventHandlers.handleOnSelectChange}
                    SelectDisplayProps={{ style: { padding: 8 } }}
                    data-testid="new-policy-field"
                  >
                    {state.policyOptions.map(policyValue => (
                      <MenuItem key={policyValue} value={policyValue}>
                        {formatMessage(policyValue)}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                {dataItem.id !== '' && (
                  <Typography variant="body2" data-testid="policy-field" className={inboundClasses.flexWrapper}>
                    {dataItem.policy === GeoPolicyStatus.block && <Block className={inboundClasses.block} />}
                    {dataItem.policy === GeoPolicyStatus.quarantine && (
                      <Warning className={inboundClasses.quarantine} />
                    )}
                    {formatMessage(dataItem.policy as string)}
                  </Typography>
                )}
              </Cell>
            )}
          />
          <DataTableColumn
            sortable
            field="comment"
            title={formatMessage('comment')}
            cell={({ dataItem }: { dataItem: GeoPolicy }) => (
              <Cell data-testid="comment-cell" className={inboundClasses.smallCell}>
                {!dataItem.id && (
                  <TextField
                    fullWidth
                    name="comment"
                    defaultValue={state.formState.comment}
                    inputRef={state.form.fields.comment.ref}
                    onChange={eventHandlers.handleOnInputChange}
                    inputProps={{ style: { padding: 8 } }}
                    data-testid="new-comment-field"
                  />
                )}
                {dataItem.id && (
                  <Typography variant="body2" data-testid="comment-field">
                    {dataItem.comment}
                  </Typography>
                )}
              </Cell>
            )}
          />
          <DataTableColumn
            field="actions"
            title={formatMessage('actions')}
            cell={({ dataItem }: { dataItem: GeoPolicy }) => (
              <Cell data-testid="action-cell" className={inboundClasses.smallCell}>
                {!dataItem.id && (
                  <Button
                    color="primary"
                    onClick={() => {
                      eventHandlers.onAdd()
                    }}
                    variant="text"
                    data-testid="add-button"
                  >
                    {formatMessage('add')}
                  </Button>
                )}
                {dataItem.id && (
                  <Button
                    color="primary"
                    onClick={() => {
                      eventHandlers.onRemove(dataItem.id)
                    }}
                    variant="text"
                    data-testid="remove-button"
                  >
                    {formatMessage('remove')}
                  </Button>
                )}
              </Cell>
            )}
          />
        </DataTable>
        {state.isConfirmDialogOpen && (
          <WarningDialog
            title={formatDialogMessage('confirm_removal')}
            onCancel={eventHandlers.onRemoveCancel}
            onConfirm={eventHandlers.onRemoveConfirm}
          />
        )}
      </Grid>
    ),
    [inboundClasses, formatMessage, formatDialogMessage, state, eventHandlers]
  )
}

export default GeoPoliciesTable
