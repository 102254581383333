import React, { useMemo } from 'react'

import {
  Button,
  DataTable,
  DataTableColumn,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@barracuda-internal/bds-core'
import { Block, RemoveCircle } from '@barracuda-internal/bds-core/dist/Icons/Core'
import { Warning } from '@barracuda-internal/bds-core/dist/Icons/Feedback'

import outboundSettingsStyles from 'components/pages/outboundSettings/outboundSettingsStyles'
import { useFormatMessage } from 'lib/localization'
import { AttachmentFilter, AttachmentFilterAction, AttachmentFilterCheck, AttachmentFilterType } from 'types/Settings'
import { Cell } from 'components/libs/grid/cell'
import WarningDialog from 'components/libs/dialog/warningDialog/WarningDialog'
import {
  useAttachmentTableLogic,
  SELECT_NAMES,
  UseAttachmentTableLogicProps
} from 'components/pages/outboundSettings/contentPolicies/useAttachmentTableLogic'

const BASE_I18N_KEY = 'ess.outbound_settings.content_policies'
const BASE_INBOUND_I18N_KEY = 'ess.inbound_settings'
const DIALOG_I18N_KEY = 'ess.dialog'

const AttachmentTable = (props: UseAttachmentTableLogicProps) => {
  const outboundClasses = outboundSettingsStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatDialogMessage = useFormatMessage(DIALOG_I18N_KEY)
  const formatInboundMessage = useFormatMessage(BASE_INBOUND_I18N_KEY)

  const [state, eventHandlers] = useAttachmentTableLogic(props)

  return useMemo(
    () => (
      <Grid container>
        <Grid container>
          <Typography variant="h4">{formatMessage('attachment_filter')}</Typography>
          <Button
            className={outboundClasses.bulkEdit}
            onClick={eventHandlers.onBulkEdit}
            variant="contained"
            size="small"
            data-testid="bulk-button"
          >
            {formatInboundMessage('bulk_edit')}
          </Button>
        </Grid>
        <DataTable
          sortable={{
            allowUnsort: false,
            mode: 'single'
          }}
          sort={state.sort}
          onSortChange={eventHandlers.handleSortChange}
          data={state.tableData}
        >
          <DataTableColumn
            sortable
            field="type"
            title={formatMessage('filter_type')}
            cell={({ dataItem }: { dataItem: AttachmentFilter }) => (
              <Cell>
                {dataItem.id === '' && (
                  <Select
                    variant="outlined"
                    className={outboundClasses.mediumDropdown}
                    name={SELECT_NAMES.TYPE}
                    defaultValue={state.formState.type}
                    onChange={eventHandlers.handleOnSelectChange}
                    SelectDisplayProps={{ style: { padding: 8 } }}
                    data-testid="new-type-field"
                    error={!!state.error}
                  >
                    {Object.values(AttachmentFilterType).map(type => (
                      <MenuItem key={type} value={type}>
                        {formatMessage(type)}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                {dataItem.id !== '' && (
                  <Typography variant="body2" data-testid="type-field">
                    {dataItem.type}
                  </Typography>
                )}
              </Cell>
            )}
          />
          <DataTableColumn
            sortable
            field="pattern"
            title={formatMessage('pattern')}
            cell={({ dataItem }: { dataItem: AttachmentFilter }) => (
              <Cell data-testid="pattern-cell" className={outboundClasses.smallCell}>
                {!dataItem.id && (
                  <TextField
                    fullWidth
                    name="pattern"
                    defaultValue={state.formState.pattern}
                    inputRef={state.form.fields.pattern.ref}
                    onChange={eventHandlers.handleOnInputChange}
                    inputProps={{ style: { padding: 8 } }}
                    data-testid="new-pattern-field"
                    error={!!state.error}
                    helperText={state.error ? formatMessage(state.error) : ''}
                  />
                )}
                {dataItem.id && (
                  <Typography variant="body2" data-testid="pattern-field">
                    {dataItem.pattern}
                  </Typography>
                )}
              </Cell>
            )}
          />
          <DataTableColumn
            sortable
            field={SELECT_NAMES.SCAN}
            title={formatMessage('check_archives')}
            cell={({ dataItem }: { dataItem: AttachmentFilter }) => (
              <Cell>
                {dataItem.id === '' && (
                  <Select
                    variant="outlined"
                    className={outboundClasses.smallDropdown}
                    name={SELECT_NAMES.SCAN}
                    defaultValue={state.formState.scanArchive}
                    onChange={eventHandlers.handleOnSelectChange}
                    SelectDisplayProps={{ style: { padding: 8 } }}
                    data-testid="new-scan-field"
                    disabled={state.formState.type === AttachmentFilterType.MIME}
                  >
                    {AttachmentFilterCheck.map(check => (
                      <MenuItem key={check.value} value={check.value}>
                        {formatMessage(check.label)}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                {dataItem.id !== '' && (
                  <Typography variant="body2" data-testid="scan-field" className={outboundClasses.flexWrapper}>
                    {dataItem.type === AttachmentFilterType.MIME && '-'}
                    {dataItem.type === AttachmentFilterType.FILENAME && !!dataItem.scanArchive && formatMessage('yes')}
                    {dataItem.type === AttachmentFilterType.FILENAME && !dataItem.scanArchive && formatMessage('no')}
                  </Typography>
                )}
              </Cell>
            )}
          />
          <DataTableColumn
            sortable
            field={SELECT_NAMES.ACTION}
            title={formatMessage('action')}
            cell={({ dataItem }: { dataItem: AttachmentFilter }) => (
              <Cell>
                {dataItem.id === '' && (
                  <Select
                    variant="outlined"
                    className={outboundClasses.smallDropdown}
                    name={SELECT_NAMES.ACTION}
                    defaultValue={state.formState.action}
                    onChange={eventHandlers.handleOnSelectChange}
                    SelectDisplayProps={{ style: { padding: 8 } }}
                    data-testid="new-action-field"
                  >
                    {state.actionOptions.map(type => (
                      <MenuItem key={type} value={type}>
                        {formatMessage(type)}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                {dataItem.id !== '' && (
                  <Typography variant="body2" data-testid="action-field" className={outboundClasses.flexWrapper}>
                    {dataItem.action === AttachmentFilterAction.BLOCK && <Block className={outboundClasses.block} />}
                    {dataItem.action === AttachmentFilterAction.IGNORE && (
                      <RemoveCircle className={outboundClasses.ignoreColor} />
                    )}
                    {dataItem.action === AttachmentFilterAction.QUARANTINE && (
                      <Warning className={outboundClasses.quarantine} />
                    )}
                    {formatMessage(dataItem.action)}
                  </Typography>
                )}
              </Cell>
            )}
          />
          <DataTableColumn
            sortable
            field="comment"
            title={formatMessage('comment')}
            cell={({ dataItem }: { dataItem: AttachmentFilter }) => (
              <Cell data-testid="comment-cell" className={outboundClasses.smallCell}>
                {!dataItem.id && (
                  <TextField
                    fullWidth
                    name="comment"
                    defaultValue={state.formState.comment}
                    inputRef={state.form.fields.comment.ref}
                    onChange={eventHandlers.handleOnInputChange}
                    inputProps={{ style: { padding: 8 } }}
                    data-testid="new-comment-field"
                  />
                )}
                {dataItem.id && (
                  <Typography variant="body2" data-testid="comment-field">
                    {dataItem.comment}
                  </Typography>
                )}
              </Cell>
            )}
          />
          <DataTableColumn
            field="actions"
            title={formatMessage('actions')}
            width={200}
            cell={({ dataItem }: { dataItem: AttachmentFilter }) => (
              <Cell data-testid="action-cell" className={outboundClasses.smallCell}>
                {!dataItem.id && (
                  <Button color="primary" onClick={eventHandlers.onAdd} variant="text" data-testid="add-button">
                    {formatMessage('add')}
                  </Button>
                )}
                {dataItem.id && (
                  <Button
                    color="primary"
                    onClick={() => {
                      eventHandlers.onRemove(dataItem.id)
                    }}
                    variant="text"
                    data-testid="remove-button"
                  >
                    {formatMessage('remove')}
                  </Button>
                )}
              </Cell>
            )}
          />
        </DataTable>
        {state.isConfirmDialogOpen && (
          <WarningDialog
            title={formatDialogMessage('confirm_removal')}
            onCancel={eventHandlers.onRemoveCancel}
            onConfirm={eventHandlers.onRemoveConfirm}
          />
        )}
      </Grid>
    ),
    [outboundClasses, formatMessage, formatDialogMessage, formatInboundMessage, state, eventHandlers]
  )
}

export default AttachmentTable
