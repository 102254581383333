import appConfig from 'config/appConfig'

export const isEmailValid = (email: string) => {
  const re =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g

  return re.test(email.toLowerCase())
}

export const isDomainValid = (domain: string) => {
  const re = /^[a-z0-9\-._]{1,255}$/i

  return re.test(domain.toLowerCase())
}

export const isEmailOrDomainValid = (emailOrDomain: string) => {
  if (emailOrDomain.includes('@')) {
    return isEmailValid(emailOrDomain)
  }
  return isDomainValid(emailOrDomain)
}

export const isPolicyNameValid = (domainOrEmail: string) => {
  if (domainOrEmail.indexOf('@') !== -1) {
    return isEmailValid(domainOrEmail)
  }
  return isDomainValid(domainOrEmail)
}

export const isPolicyValid = (policy: string) => {
  const pol = policy.toLowerCase()
  return pol === 'block' || pol === 'exempt' || pol === 'quarantine'
}

export const isValidIpV4Address = (ipAddress: string) => {
  const ipAddressRegex =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
  return ipAddressRegex.test(ipAddress)
}

export const hasTld = (domainName: string) => {
  const re = /\.[a-z]{2,63}$/gi
  return re.test(domainName.toLowerCase())
}

export const isUsNumber = (number: string) => {
  const re = /^\(?\d{3}\)?[.\-\s]?\d{3}[.\-\s]?\d{4}$/

  return re.test(number)
}

export const isValidHostName = (hostName: string) => {
  const hostNameRegex = /^([a-z0-9\-_.]+(:[0-9]+)?)$/i
  return hostNameRegex.test(hostName)
}
