import React, { useMemo } from 'react'

import {
  Box,
  DataTable,
  DataTableColumn,
  Grid,
  LinearProgress,
  MenuItem,
  Select,
  Typography
} from '@barracuda-internal/bds-core'

import { GridNoRecords } from '@progress/kendo-react-grid'
import { Cell } from 'components/libs/grid/cell'
import { formatDate } from 'lib/datetime'
import appConfig from 'config/appConfig'

import { useFormatMessage } from 'lib/localization'
import styles from './tableWithDropdownStyles'
import { TableWithDropdownConfig } from './config'

export interface TableWithDropdownProps {
  data: any[] | undefined
  config: TableWithDropdownConfig
  onSelect: (value: unknown) => void
  selectedValue: string
  inProgress: boolean
  maxRecords?: number
  rankColumn?: boolean
}

const DEFAULT_MAX_RECORDS = 10
const BASE_I18N_KEY = 'ess.overview.dashboard.dropdown_table'
const BASE_I18N_TABLE_KEY = 'ess.overview.dashboard.dropdown_table.table'

const TableWithDropdown: React.FC<TableWithDropdownProps> = (props: TableWithDropdownProps) => {
  const classes = styles()

  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const tableFormatMessage = useFormatMessage(BASE_I18N_TABLE_KEY)

  return useMemo(
    () => (
      <Grid className={classes.container} container data-testid="container">
        <Typography className={classes.subtitle} variant="subtitle1" data-testid="title">
          {formatMessage(props.config.title)}:{' '}
          <Select
            className={classes.dropdown}
            onChange={e => props.onSelect(e.target.value)}
            value={props.selectedValue}
            renderValue={(selected: any): React.ReactElement => (
              <span>{formatMessage(`selected_value.${selected}`)}</span>
            )}
            data-testid="select-menu"
          >
            {props.config.dropdownItems.map(item => (
              <MenuItem key={item.value} value={item.value}>
                {formatMessage(item.label)}
              </MenuItem>
            ))}
          </Select>
        </Typography>
        <Grid id={props.config.title} className={classes.tableWrapper} item>
          <Box className={classes.logTableProgress}>
            {props.inProgress && <LinearProgress data-testid="linear-loader" />}
          </Box>
          <DataTable className={classes.table} data={props.data?.slice(0, props.maxRecords || DEFAULT_MAX_RECORDS)}>
            <GridNoRecords>
              {!props.inProgress && !props.data?.length ? (
                <Typography className={classes.noDataFound} variant="body2" data-testid="no-data">
                  {formatMessage('no_data_found')}
                </Typography>
              ) : (
                ' '
              )}
            </GridNoRecords>
            {props.rankColumn && (
              <DataTableColumn
                width={40}
                title={tableFormatMessage('RANK')}
                headerClassName={classes.alignRight}
                cell={({ dataIndex }: { dataIndex: number }) => (
                  <Cell data-testid="rank-cell">
                    <Typography align="center" variant="body2">
                      {dataIndex + 1}
                    </Typography>
                  </Cell>
                )}
              />
            )}
            {
              Object.entries(props.config.columns).map(
                ([columnKey, columnValue]) => (
                  <DataTableColumn
                    key={columnKey}
                    field={columnKey}
                    width={columnValue.width}
                    title={tableFormatMessage(columnKey)}
                    headerClassName={columnValue.alignRight ? classes.alignRight : ''}
                    cell={({ dataItem }: { dataItem: any }) => (
                      <Cell
                        data-testid={`${columnKey}-cell`}
                        className={columnValue.alignRight ? classes.alignRight : ''}
                      >
                        <Typography variant="body2">
                          {columnValue.formatDate
                            ? formatDate(
                                dataItem[columnValue.mapToValue as string],
                                appConfig.DATETIME.DEFAULT_DATE_FORMAT
                              )
                            : dataItem[columnValue.mapToValue as string]}
                        </Typography>
                      </Cell>
                    )}
                  />
                )
                // rendering multiple nodes it is not allowed by the type declarations for React,
                // but it is supported in react >= 16
              ) as any
            }
          </DataTable>
        </Grid>
        <Grid className={classes.circleWrapper} item>
          {props.config.dropdownItems.map(item => (
            <Box
              key={item.value}
              className={`${classes.circle} ${item.value === props.selectedValue ? 'selected' : ''}`}
              onClick={() => props.onSelect(item.value)}
              data-testid={`circle-item-${item.value}`}
            />
          ))}
        </Grid>
      </Grid>
    ),
    [classes, formatMessage, tableFormatMessage, props]
  )
}

export default TableWithDropdown
