import { AppNames } from 'config/appConfig'
import { AppTypes } from 'types/AppTypes'

import LOGIN_PATHS from 'config/uipaths/loginPaths'
import OVERVIEW_UI_PATHS from 'config/uipaths/overviewPaths'
import DOMAINS_UI_PATHS from 'config/uipaths/domainsPaths'
import INBOUND_SETTINGS_UI_PATHS from 'config/uipaths/inboundSettingsPaths'
import OUTBOUND_SETTINGS_UI_PATHS from 'config/uipaths/outboundSettingsPaths'
import USERS_UI_PATHS from 'config/uipaths/usersPaths'
import REPORTS_UI_PATHS from 'config/uipaths/reportsPaths'
import SUPPORT_UI_PATHS from 'config/uipaths/supportPaths'
import CUDASPT_UI_PATHS from 'config/uipaths/cudasptPaths'
import SETTINGS_UI_PATHS from 'config/uipaths/setttingsPaths'

const UI_PATHS = {
  // cudapst
  ...CUDASPT_UI_PATHS,

  // login
  ...LOGIN_PATHS,

  // admin
  ...OVERVIEW_UI_PATHS,
  ...DOMAINS_UI_PATHS,
  ...INBOUND_SETTINGS_UI_PATHS,
  ...OUTBOUND_SETTINGS_UI_PATHS,
  ...USERS_UI_PATHS,
  ...REPORTS_UI_PATHS,
  ...SUPPORT_UI_PATHS,

  // ATP settings
  ATP_SETTINGS: {
    path: 'atp-settings',
    legacyPath: 'settings/atd',
    appAccess: [AppNames.admin],
    authorization: {
      appTypeAccess: [AppTypes.admin, AppTypes.cplAccount]
    },
    metadata: {
      sideMenuId: 'ATP_SETTINGS'
    }
  },

  // Account management
  ACCOUNT_MANAGEMENT: {
    path: 'account-management',
    legacyPath: 'accountmanagement',
    appAccess: [AppNames.admin],
    authorization: {
      appTypeAccess: [AppTypes.admin]
    },
    metadata: {
      sideMenuId: 'ACCOUNT_MANAGEMENT'
    }
  },

  // wizard
  ONBOARD_WIZARD: {
    path: 'setup',
    legacyPath: 'wizard',
    appAccess: [AppNames.wizard],
    authorization: {
      appTypeAccess: [AppTypes.wizard]
    }
  },

  // additional paths
  QN_DIGEST_LINKS_HANDLER: {
    path: 'qn-digest-links-handler',
    legacyPath: 'qn-digest-links',
    appAccess: [AppNames.enduser, AppNames.admin],
    authorization: {
      appTypeAccess: [AppTypes.admin, AppTypes.enduser]
    }
  },

  // enduser paths
  ...SETTINGS_UI_PATHS,
  MESSAGE_LOG: {
    path: 'message-log',
    legacyPath: 'enduser_log',
    appAccess: [AppNames.enduser],
    authorization: {
      appTypeAccess: [AppTypes.enduser, AppTypes.domain, AppTypes.helpdesk]
    },
    metadata: {
      sideMenuId: 'MESSAGE_LOG'
    }
  },
  MESSAGE_DETAIL: {
    path: 'domains/:domainId/message-detail/:messageId',
    legacyPath: 'log/read/:domainId/:messageId',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.enduser,
        AppTypes.admin,
        AppTypes.domain,
        AppTypes.helpdesk,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId
      ]
    },
    metadata: {
      sideMenuId: 'MESSAGE_LOG'
    }
  },

  // additional paths
  VERIFY_LINK_ACCOUNT: {
    path: 'actions/verify-link-account',
    // This is an action so we can't link it to TDF
    legacyPath: '',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.enduser,
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.domain,
        AppTypes.helpdesk,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId
      ]
    }
  },
  RESET_PASSWORD: {
    path: 'actions/reset-password',
    // This is an action so we can't link it to TDF and we are moving over to the login page where the reset password action is
    legacyPath: 'user/auth/login',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.enduser,
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.domain,
        AppTypes.helpdesk,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId
      ]
    }
  }
} as const

export interface AppTypeRelatedPaths {
  [key: string]: string
}

const initialAppTypeRelatedPaths: AppTypeRelatedPaths = Object.values(AppTypes).reduce(
  (collectedPaths: any, appType: string) => ({
    ...collectedPaths,
    [appType]: []
  }),
  {}
)
const appTypeRelatedPaths = Object.keys(UI_PATHS).reduce((collectedPaths: AppTypeRelatedPaths, uiPath: string) => {
  const { authorization } = (UI_PATHS as any)[uiPath] || {}

  if (authorization && authorization.appTypeAccess) {
    return authorization.appTypeAccess.reduce(
      (updatedCollectedPaths: AppTypeRelatedPaths, selectedAppType: string) => ({
        ...updatedCollectedPaths,
        [selectedAppType]: [...(collectedPaths[selectedAppType] || []), uiPath]
      }),
      collectedPaths
    )
  }

  return collectedPaths
}, initialAppTypeRelatedPaths)

export { appTypeRelatedPaths }

export default UI_PATHS
