import React, { useMemo } from 'react'

import { Button, Grid, Typography } from '@barracuda-internal/bds-core'

import styles from 'components/pages/inboundSettings/inboundSettingsStyles'
import { useFormatMessage } from 'lib/localization'
import { AvailableSettings, SettingValue } from 'types/Settings'
import { GROUP_TITLE, TOPICS } from 'components/pages/support/config'
import { useAntiPhishingLogic } from 'components/pages/inboundSettings/antiPhishing/useAntiPhishingLogic'
import InboundAntiPhishing from 'components/pages/support/help/inboundAntiPhishing/InboundAntiPhishing'
import {
  PageTitle,
  FormSpacer,
  FormRow,
  InlineRadioGroup,
  PageSubtitle,
  InlineInputField
} from 'components/libs/layout/pages/pageLayout'
import routesConfig from 'lib/routesConfig'
import { useSettingsStateLogic } from 'components/libs/useSettingsState'
import { SettingsPageContainer } from 'components/libs/layout/pages/SettingsPageContainer'
import AntiPhishingTable from 'components/pages/inboundSettings/antiPhishing/AntiPhishingTable'
import { EmailBannersActions } from 'types/AntiPhishing'

const BASE_INBOUND_I18N_KEY = 'ess.inbound_settings'
const BASE_I18N_KEY = 'ess.inbound_settings.anti_phishing'

const EMAIL_BANNER_OPTIONS: { label: string; value: EmailBannersActions }[] = [
  {
    label: 'on',
    value: EmailBannersActions.ON
  },
  {
    label: 'testing_mode',
    value: EmailBannersActions.TESTING_MODE
  },
  {
    label: 'off',
    value: EmailBannersActions.OFF
  }
]

const AntiPhishing: React.FC = () => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatInboundMessage = useFormatMessage(BASE_INBOUND_I18N_KEY)

  const [state, eventHandlers] = useAntiPhishingLogic()
  const [UseGetSettingsStateLogic] = useSettingsStateLogic()

  return useMemo(
    () => (
      <SettingsPageContainer dirtyCheck isDirtyForm={state.hasPageChanges}>
        <PageTitle
          title={formatMessage('title')}
          onOpenSupport={eventHandlers.helpConfig.onHelpClick}
          onCancel={() => routesConfig.INBOUND_SETTINGS_ANTI_PHISHING.goto()}
          onSubmit={eventHandlers.onSave}
          submitInProgress={UseGetSettingsStateLogic.isLoading}
          isDisabledSubmit={!state.hasPageChanges}
          help={{
            content: InboundAntiPhishing,
            onClose: eventHandlers.helpConfig.onCloseHelp,
            title: TOPICS.antiPhishing,
            group: GROUP_TITLE.inboundSettings,
            isHelpOpen: eventHandlers.helpConfig.isOpen
          }}
        />
        <FormSpacer />
        <FormRow rowTitle={formatMessage('bafi_label')}>
          <InlineRadioGroup
            name={AvailableSettings.BAFI}
            value={state.form[AvailableSettings.BAFI]}
            onChange={eventHandlers.onFormChange(AvailableSettings.BAFI)}
            disabled={UseGetSettingsStateLogic.isLoading}
            labels={state.bafiOptions.map(action => formatMessage(action.label))}
            values={state.bafiOptions.map(action => action.value)}
          />
        </FormRow>
        <FormSpacer />
        <FormRow rowTitle={formatMessage('sender_label')}>
          <InlineRadioGroup
            name={AvailableSettings.EXT_SENDER_WARNING}
            value={state.form[AvailableSettings.EXT_SENDER_WARNING]}
            values={[SettingValue.ENABLED, SettingValue.DISABLED]}
            onChange={eventHandlers.onFormChange(AvailableSettings.EXT_SENDER_WARNING)}
            disabled={UseGetSettingsStateLogic.isLoading}
            labels={[formatMessage('on'), formatMessage('off')]}
          />
        </FormRow>
        <FormSpacer />
        {state.isEmailBannerEnabled && (
          <>
            <PageSubtitle>{formatMessage('email_banners')}</PageSubtitle>
            <FormSpacer />
            <FormRow rowTitle={formatMessage('insert_email_banners')}>
              <InlineRadioGroup
                name={AvailableSettings.EMAIL_BANNERS}
                value={state.form[AvailableSettings.EMAIL_BANNERS]}
                onChange={eventHandlers.onFormChange(AvailableSettings.EMAIL_BANNERS)}
                disabled={UseGetSettingsStateLogic.isLoading}
                labels={EMAIL_BANNER_OPTIONS.map(action => formatMessage(action.label))}
                values={EMAIL_BANNER_OPTIONS.map(action => action.value)}
              />
              <Typography variant="body2">
                <i>{formatMessage('email_banners_testing_mode_text')}</i>
              </Typography>
              <br />
              <Typography variant="body2">{formatMessage('testing_mode_enable')}</Typography>
              <InlineInputField
                inputClassName={classes.mediumArea}
                name={AvailableSettings.EMAIL_BANNERS_TESTING_USERS}
                onChange={eventHandlers.onInputFormChange}
                value={
                  state.form[AvailableSettings.EMAIL_BANNERS] === EmailBannersActions.TESTING_MODE
                    ? state.form[AvailableSettings.EMAIL_BANNERS_TESTING_USERS]
                    : ''
                }
                placeholder={formatMessage('testing_mode_placeholder')}
                disabled={!(state.form[AvailableSettings.EMAIL_BANNERS] === EmailBannersActions.TESTING_MODE)}
                rows={7}
              />
              <br />
              <Typography className={classes.errorText} variant="body2">
                {state.error && state.error}
              </Typography>
            </FormRow>
            <FormSpacer />
          </>
        )}
        <PageSubtitle>{formatMessage('intent')}</PageSubtitle>
        <FormSpacer />
        <FormRow rowTitle={formatMessage('intent_label')}>
          <InlineRadioGroup
            name={AvailableSettings.INTENT}
            value={state.form[AvailableSettings.INTENT]}
            values={[SettingValue.ENABLED, SettingValue.DISABLED]}
            onChange={eventHandlers.onFormChange(AvailableSettings.INTENT)}
            disabled={UseGetSettingsStateLogic.isLoading}
            labels={[formatMessage('on'), formatMessage('off')]}
          />
        </FormRow>
        <FormSpacer />
        <FormRow rowTitle={formatMessage('content_label')}>
          <InlineRadioGroup
            name={AvailableSettings.REDIRECTOR_CONTENT_ACTION}
            onChange={eventHandlers.onFormChange(AvailableSettings.REDIRECTOR_CONTENT_ACTION)}
            value={state.form[AvailableSettings.REDIRECTOR_CONTENT_ACTION]}
            values={state.contentIntentActions.map(action => action)}
            labels={state.contentIntentActions.map(action => formatMessage(action))}
            disabled={
              state.form[AvailableSettings.INTENT] === SettingValue.DISABLED || UseGetSettingsStateLogic.isLoading
            }
          />
        </FormRow>
        <FormSpacer />
        {state.isCplAccount && (
          <FormRow rowTitle={formatMessage('intent_realtime_label')}>
            <InlineRadioGroup
              name={AvailableSettings.INTENT_REALTIME}
              onChange={eventHandlers.onFormChange(AvailableSettings.INTENT_REALTIME)}
              labels={[formatMessage('yes'), formatMessage('no')]}
              value={state.form[AvailableSettings.INTENT_REALTIME]}
              values={[SettingValue.ENABLED, SettingValue.DISABLED]}
              disabled={UseGetSettingsStateLogic.isLoading}
            />
          </FormRow>
        )}
        <FormSpacer />
        <FormRow rowTitle={formatMessage('link_label')}>
          <InlineRadioGroup
            name={AvailableSettings.LINKPROTECT}
            onChange={eventHandlers.onFormChange(AvailableSettings.LINKPROTECT)}
            labels={[formatMessage('on'), formatMessage('off')]}
            value={state.form[AvailableSettings.LINKPROTECT]}
            values={[SettingValue.ENABLED, SettingValue.DISABLED]}
            disabled={UseGetSettingsStateLogic.isLoading}
          />
        </FormRow>
        <FormSpacer />
        <FormRow rowTitle={formatMessage('typosquat_label')}>
          <InlineRadioGroup
            name={AvailableSettings.TYPOSQUAT}
            onChange={eventHandlers.onFormChange(AvailableSettings.TYPOSQUAT)}
            labels={[formatMessage('on'), formatMessage('off')]}
            value={state.form[AvailableSettings.TYPOSQUAT]}
            values={[SettingValue.ENABLED, SettingValue.DISABLED]}
            disabled={
              state.form[AvailableSettings.LINKPROTECT] === SettingValue.DISABLED || UseGetSettingsStateLogic.isLoading
            }
          />
        </FormRow>
        <FormSpacer />
        <FormSpacer />
        <PageSubtitle>{formatMessage('intent_domain_table')}</PageSubtitle>
        <Grid container direction="row" className={classes.flexWrapper}>
          <Button
            className={classes.bulkEdit}
            onClick={eventHandlers.onBulkEdit}
            variant="contained"
            size="small"
            data-testid="bulk-button"
          >
            {formatInboundMessage('bulk_edit')}
          </Button>
        </Grid>
        <AntiPhishingTable
          data-testid="intent-domain-table"
          delegateDirtyFormState={eventHandlers.setIsAntiPhishingTableDirty}
        />
      </SettingsPageContainer>
    ),
    [classes, formatMessage, formatInboundMessage, state, eventHandlers, UseGetSettingsStateLogic]
  )
}

export default AntiPhishing
