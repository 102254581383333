import React, { useMemo } from 'react'
import {
  Button,
  DataTable,
  DataTableColumn,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@barracuda-internal/bds-core'
import { CheckmarkCircle, Block } from '@barracuda-internal/bds-core/dist/Icons/Core'
import { Warning } from '@barracuda-internal/bds-core/dist/Icons/Feedback'

import inboundSettingsStyles from 'components/pages/inboundSettings/inboundSettingsStyles'

import { Cell } from 'components/libs/grid/cell'
import { useFormatMessage } from 'lib/localization'
import { formatTimeToFullLocaleString } from 'lib/datetime'

import { SenderPolicy, SenderPolicyStatus } from 'types/Settings'
import WarningDialog from 'components/libs/dialog/warningDialog/WarningDialog'
import {
  POLICIES_INPUT_NAMES,
  UseSenderPoliciesTableLogicProps,
  useSenderPoliciesTableLogic
} from 'components/pages/inboundSettings/senderPolicies/useSenderPoliciesTableLogic'

const BASE_I18N_KEY = 'ess.inbound_settings.sender_policies'
const BASE_DIALOG_I18N_KEY = 'ess.dialog'

const SenderPoliciesTable: React.FC<UseSenderPoliciesTableLogicProps> = (props: UseSenderPoliciesTableLogicProps) => {
  const inboundClasses = inboundSettingsStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatDialogMessage = useFormatMessage(BASE_DIALOG_I18N_KEY)
  const [state, eventHandlers] = useSenderPoliciesTableLogic(props)

  return useMemo(() => {
    const formState = state.form.getState()
    return (
      <Grid data-testid="ip-address-table-grid">
        <DataTable
          sortable={{
            allowUnsort: false,
            mode: 'single'
          }}
          sort={state.sort}
          onSortChange={eventHandlers.handleSortChange}
          data={state.tableData}
        >
          <DataTableColumn
            sortable
            field={POLICIES_INPUT_NAMES.NAME}
            title={formatMessage('sender')}
            cell={({ dataItem }: { dataItem: SenderPolicy }) => (
              <Cell>
                {!dataItem.id && (
                  <TextField
                    fullWidth
                    defaultValue={formState.name}
                    inputRef={state.form.fields.name.ref}
                    onChange={eventHandlers.handleOnInputChange}
                    name={POLICIES_INPUT_NAMES.NAME}
                    error={!!state.form.error}
                    helperText={state.form.error && formatMessage(state.form.error)}
                    inputProps={{ style: { padding: 8 } }}
                    data-testid="new-name-field"
                  />
                )}
                {dataItem.id && (
                  <Typography variant="body2" data-testid="name-field">
                    {dataItem.name}
                  </Typography>
                )}
              </Cell>
            )}
          />
          <DataTableColumn
            sortable
            field={POLICIES_INPUT_NAMES.POLICY}
            title={formatMessage('policy')}
            cell={({ dataItem }: { dataItem: SenderPolicy }) => (
              <Cell>
                {dataItem.id === '' && (
                  <Select
                    variant="outlined"
                    className={inboundClasses.smallDropdown}
                    name={POLICIES_INPUT_NAMES.POLICY}
                    defaultValue={formState.policy}
                    onChange={eventHandlers.onPolicyChange}
                    SelectDisplayProps={{ style: { padding: 8 } }}
                    data-testid="new-policy-field"
                  >
                    {props.availablePolicies.map(policyValue => (
                      <MenuItem key={policyValue} value={policyValue}>
                        {formatMessage(policyValue)}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                {dataItem.id !== '' && (
                  <Typography variant="body2" data-testid="policy-field" className={inboundClasses.flexWrapper}>
                    {dataItem.policy === SenderPolicyStatus.exempt && (
                      <CheckmarkCircle className={inboundClasses.successColor} />
                    )}
                    {dataItem.policy === SenderPolicyStatus.quarantine && (
                      <Warning className={inboundClasses.warningColor} />
                    )}
                    {dataItem.policy === SenderPolicyStatus.block && <Block className={inboundClasses.block} />}
                    {formatMessage(dataItem.policy as string)}
                  </Typography>
                )}
              </Cell>
            )}
          />
          <DataTableColumn
            sortable
            field={POLICIES_INPUT_NAMES.COMMENT}
            title={formatMessage('comment')}
            cell={({ dataItem }: { dataItem: SenderPolicy }) => (
              <Cell data-testid="comment-cell" className={inboundClasses.smallCell}>
                {!dataItem.id && (
                  <TextField
                    fullWidth
                    defaultValue={formState.comment}
                    inputRef={state.form.fields.comment.ref}
                    onChange={eventHandlers.handleOnInputChange}
                    name={POLICIES_INPUT_NAMES.COMMENT}
                    inputProps={{ style: { padding: 8 } }}
                    data-testid="new-comment-field"
                  />
                )}
                {dataItem.id && (
                  <Typography variant="body2" data-testid="comment-field">
                    {dataItem.comment}
                  </Typography>
                )}
              </Cell>
            )}
          />
          <DataTableColumn
            sortable
            field="modified"
            title={formatMessage('modified')}
            cell={({ dataItem }: { dataItem: SenderPolicy }) => (
              <Cell data-testid="modified-cell" className={inboundClasses.smallCell}>
                {dataItem.id && (
                  <Typography variant="body2" data-testid="modified-field">
                    {formatTimeToFullLocaleString(dataItem.modified)}
                  </Typography>
                )}
              </Cell>
            )}
          />
          <DataTableColumn
            field="actions"
            title={formatMessage('actions')}
            cell={({ dataItem }: { dataItem: SenderPolicy }) => (
              <Cell data-testid="action-cell" className={inboundClasses.smallCell}>
                {!dataItem.id && (
                  <Button
                    color="primary"
                    onClick={() => {
                      eventHandlers.onAdd()
                    }}
                    variant="text"
                    data-testid="add-button"
                  >
                    {formatMessage('add')}
                  </Button>
                )}
                {dataItem.id && (
                  <Button
                    color="primary"
                    onClick={() => {
                      eventHandlers.onRemove(dataItem.id)
                    }}
                    variant="text"
                    data-testid="remove-button"
                  >
                    {formatMessage('remove')}
                  </Button>
                )}
              </Cell>
            )}
          />
        </DataTable>
        {!!state.idToRemove && (
          <WarningDialog
            title={formatDialogMessage('confirm_removal')}
            onConfirm={eventHandlers.onConfirmRemove}
            onCancel={eventHandlers.onConfirmCancel}
          />
        )}
      </Grid>
    )
  }, [
    state.form,
    state.sort,
    state.tableData,
    state.idToRemove,
    eventHandlers,
    formatMessage,
    formatDialogMessage,
    inboundClasses,
    props.availablePolicies
  ])
}

export default SenderPoliciesTable
