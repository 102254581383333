import { colors, makeStyles } from '@barracuda-internal/bds-core'
import { SIDE_MENU_WIDTH } from 'components/libs/layout/layoutStyles'

export default makeStyles(theme => ({
  container: {
    position: 'fixed',
    bottom: '0',
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0px 15px 0px 15px',
    height: theme.layout.footerHeight,
    width: '100%',
    backgroundColor: colors.scaleGray050,
    zIndex: 1200,
    '& #ot-sdk-btn.ot-sdk-show-settings': {
      padding: '0 5px 0 5px',
      border: 'none',
      fontSize: 11.2
    },
    marginLeft: SIDE_MENU_WIDTH
  },
  components: {
    display: 'flex',
    marginRight: 'auto',
    marginLeft: 0,
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  copyright: {
    marginLeft: 5,
    marginRight: 5,
    color: theme.palette.secondary.light,
    fontSize: 14,
    lineHeight: '24px',
    letterSpacing: '0.01071em'
  },
  privacy: {
    fontSize: '14px',
    textDecoration: 'none',
    marginLeft: 5,
    marginRight: 5,
    color: colors.barracudaBlue,
    letterSpacing: '0.01071em'
  },
  terms: {
    fontSize: '14px',
    textDecoration: 'none',
    marginLeft: 5,
    marginRight: 5,
    color: colors.barracudaBlue,
    letterSpacing: '0.01071em'
  }
}))
