import React, { useMemo } from 'react'

import {
  Button,
  Checkbox,
  CheckboxGroup,
  CheckboxLabel,
  DataTable,
  DataTableColumn,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@barracuda-internal/bds-core'
import { Block, CheckmarkCircle } from '@barracuda-internal/bds-core/dist/Icons/Core'
import { Warning } from '@barracuda-internal/bds-core/dist/Icons/Feedback'
import { NoEncryption, EnhancedEncryption } from '@material-ui/icons'

import outboundSettingsStyles from 'components/pages/outboundSettings/outboundSettingsStyles'
import { useFormatMessage } from 'lib/localization'
import { MessageContentFilter, OutboundMessageContentFilterAction, MessageContentFilterMatches } from 'types/Settings'
import { Cell } from 'components/libs/grid/cell'
import WarningDialog from 'components/libs/dialog/warningDialog/WarningDialog'
import { useMessageTableLogic, UseMessageTableLogicProps } from './useMessageTableLogic'

const BASE_I18N_KEY = 'ess.outbound_settings.content_policies'
const BASE_INBOUND_I18N_KEY = 'ess.inbound_settings'
const DIALOG_I18N_KEY = 'ess.dialog'

const MessageTable = (props: UseMessageTableLogicProps) => {
  const outboundClasses = outboundSettingsStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatDialogMessage = useFormatMessage(DIALOG_I18N_KEY)
  const formatInboundMessage = useFormatMessage(BASE_INBOUND_I18N_KEY)

  const [state, eventHandlers] = useMessageTableLogic(props)

  return useMemo(
    () => (
      <Grid container>
        <Grid container>
          <Typography variant="h4">{formatMessage('message_content_filter')}</Typography>
          <Button
            className={outboundClasses.bulkEdit}
            onClick={eventHandlers.onBulkEdit}
            variant="contained"
            size="small"
            data-testid="bulk-button"
          >
            {formatInboundMessage('bulk_edit')}
          </Button>
        </Grid>
        <DataTable
          sortable={{
            allowUnsort: false,
            mode: 'single'
          }}
          sort={state.sort}
          onSortChange={eventHandlers.handleSortChange}
          data={state.tableData}
        >
          <DataTableColumn
            sortable
            field="pattern"
            title={formatMessage('pattern')}
            width={300}
            cell={({ dataItem }: { dataItem: MessageContentFilter }) => (
              <Cell data-testid="pattern-cell" className={outboundClasses.smallCell}>
                {!dataItem.id && (
                  <TextField
                    fullWidth
                    name="pattern"
                    defaultValue={state.formState.pattern}
                    inputRef={state.form.fields.pattern.ref}
                    onChange={eventHandlers.handleOnInputChange}
                    inputProps={{ style: { padding: 8 } }}
                    data-testid="new-pattern-field"
                    error={!!state.errors.pattern}
                    helperText={state.errors.pattern ? formatMessage(state.errors.pattern) : ''}
                  />
                )}
                {dataItem.id && (
                  <Typography variant="body2" data-testid="pattern-field">
                    {dataItem.pattern}
                  </Typography>
                )}
              </Cell>
            )}
          />
          <DataTableColumn
            sortable
            field="action"
            title={formatMessage('action')}
            width={200}
            cell={({ dataItem }: { dataItem: MessageContentFilter }) => (
              <Cell>
                {dataItem.id === '' && (
                  <Select
                    variant="outlined"
                    className={outboundClasses.smallDropdown}
                    name="action"
                    defaultValue={state.formState.action}
                    onChange={eventHandlers.handleOnSelectChange}
                    SelectDisplayProps={{ style: { padding: 8 } }}
                    data-testid="new-action-field"
                  >
                    {state.actionOptions.map(type => (
                      <MenuItem key={type} value={type}>
                        {formatMessage(type)}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                {dataItem.id !== '' && (
                  <Typography variant="body2" data-testid="action-field" className={outboundClasses.flexWrapper}>
                    {dataItem.action === OutboundMessageContentFilterAction.BLOCK && (
                      <Block className={outboundClasses.block} />
                    )}
                    {dataItem.action === OutboundMessageContentFilterAction.ALLOW && (
                      <CheckmarkCircle className={outboundClasses.successColor} />
                    )}
                    {dataItem.action === OutboundMessageContentFilterAction.QUARANTINE && (
                      <Warning className={outboundClasses.quarantine} />
                    )}
                    {dataItem.action === OutboundMessageContentFilterAction.ENCRYPT && (
                      <EnhancedEncryption className={outboundClasses.ignoreColor} />
                    )}
                    {dataItem.action === OutboundMessageContentFilterAction.NOT_ENCRYPT && (
                      <NoEncryption className={outboundClasses.ignoreColor} />
                    )}
                    {formatMessage(dataItem.action)}
                  </Typography>
                )}
              </Cell>
            )}
          />
          <DataTableColumn
            sortable
            field="match"
            title={formatMessage('match')}
            cell={({ dataItem }: { dataItem: MessageContentFilter }) => (
              <Cell className={outboundClasses.smallCell}>
                {!dataItem.id && (
                  <Grid>
                    <CheckboxGroup row>
                      {Object.values(MessageContentFilterMatches).map(value => (
                        <CheckboxLabel
                          control={
                            <Checkbox
                              size="small"
                              name={value}
                              checked={!!state.formState.match[value]}
                              onChange={eventHandlers.handleOnCheckboxChange}
                            />
                          }
                          label={formatMessage(value)}
                          key={value}
                          data-testid={`checkbox-${value}`}
                        />
                      ))}
                    </CheckboxGroup>
                    <FormHelperText className={outboundClasses.errorColor}>
                      {state.errors.match && formatMessage(state.errors.match)}
                    </FormHelperText>
                  </Grid>
                )}
                {dataItem.id && (
                  <Typography variant="body2" data-testid="match-field">
                    {Object.keys(dataItem.match)
                      .map(key => ` ${formatMessage(key)}`)
                      .toString()}
                  </Typography>
                )}
              </Cell>
            )}
          />
          <DataTableColumn
            field="actions"
            title={formatMessage('actions')}
            width={200}
            cell={({ dataItem }: { dataItem: MessageContentFilter }) => (
              <Cell data-testid="action-cell" className={outboundClasses.smallCell}>
                {!dataItem.id && (
                  <Button
                    color="primary"
                    onClick={() => {
                      eventHandlers.onAdd()
                    }}
                    variant="text"
                    data-testid="add-button"
                  >
                    {formatMessage('add')}
                  </Button>
                )}
                {dataItem.id && (
                  <Button
                    color="primary"
                    onClick={() => {
                      eventHandlers.onRemove(dataItem.id)
                    }}
                    variant="text"
                    data-testid="remove-button"
                  >
                    {formatMessage('remove')}
                  </Button>
                )}
              </Cell>
            )}
          />
        </DataTable>
        {state.isConfirmDialogOpen && (
          <WarningDialog
            title={formatDialogMessage('confirm_removal')}
            onCancel={eventHandlers.onRemoveCancel}
            onConfirm={eventHandlers.onRemoveConfirm}
          />
        )}
      </Grid>
    ),
    [outboundClasses, formatMessage, formatDialogMessage, formatInboundMessage, state, eventHandlers]
  )
}

export default MessageTable
