import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import type { ValueOf } from 'type-fest'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import {
  AvailableSettings,
  DomainEntry,
  IpAddressEntry,
  SettingActionValue,
  SettingActionValueString,
  SettingsObject,
  SettingValue
} from 'types/Settings'

import {
  getAccountSettings,
  getDomainSettings,
  resetAccountAndDomainSettings,
  updateAccountSettings,
  updateDomainSettings
} from 'redux/features/settings/settingsSlice'
import { isSuccess } from 'redux/toolkit/api'
import { SenderAuthSettings } from 'types/SenderAuth'
import { UiRoute } from 'lib/routesConfig'
import { RadioOptions } from 'components/libs/settings/SettingsRowRadio'
import { useDirtyFormCheck } from 'lib/useDirtyFormCheck'
import { useInboundSettingsRights } from 'components/libs/userRights/pages/useInboundSettingsRights'
import logger from 'lib/logger'

export interface State {
  cplActionNumbers: RadioOptions[]
  cplActionStrings: RadioOptions[]
  form: SenderAuthSettings
  hasPageChanges: boolean
  dmarcExemptEntries: DomainEntry[]
  dkimExemptEntries: DomainEntry[]
  spfExemptIpAddressEntries: IpAddressEntry[]
  spfExemptDomainEntries: DomainEntry[]
  missingSpfExemptIpAddressEntries: IpAddressEntry[]
  missingPtrExemptIpAddressEntries: IpAddressEntry[]
}

export interface EventHandlers {
  onAddDmarcExemptItem: (item: DomainEntry) => void
  onRemoveDmarcExemptItem: (id: string) => void
  onAddDkimExemptItem: (item: DomainEntry) => void
  onRemoveDkimExemptItem: (id: string) => void
  onAddSpfExemptIpAddressItem: (item: IpAddressEntry) => void
  onRemoveSpfExemptIpAddressItem: (id: string) => void
  onAddSpfExemptDomainItem: (item: DomainEntry) => void
  onRemoveSpfExemptDomainItem: (id: string) => void
  onAddMissingSpfExemptIpAddressItem: (item: IpAddressEntry) => void
  onRemoveMissingSpfExemptIpAddressItem: (id: string) => void
  onAddMissingPtrExemptIpAddressItem: (item: IpAddressEntry) => void
  onRemoveMissingPtrExemptIpAddressItem: (id: string) => void
  onBulkEdit: (route: UiRoute) => void
  onFormChange: (name: AvailableSettings) => (NewValue: any) => void
  onSave: () => void
  onCancelConfirm: () => void
  helpConfig: {
    isOpen: boolean
    onHelpClick: () => void
    onCloseHelp: () => void
  }
  setIsIpAddressTableDirty: Dispatch<SetStateAction<boolean>>
  setIsDmarcExemptTableDirty: Dispatch<SetStateAction<boolean>>
  setIsDkimExemptTableDirty: Dispatch<SetStateAction<boolean>>
  setIsSpfDomainExemptTableDirty: Dispatch<SetStateAction<boolean>>
}

export type UseSenderAuthLogic = [State, EventHandlers]

const SETTINGS_LIST = [
  AvailableSettings.DMARC,
  AvailableSettings.DMARC_EXEMPT,
  AvailableSettings.DKIM,
  AvailableSettings.DKIM_EXEMPT,
  AvailableSettings.SPF_HARDFAIL,
  AvailableSettings.SPF_SOFTFAIL,
  AvailableSettings.SPF_EXEMPT,
  AvailableSettings.SPF_DOMAIN_EXEMPT,
  AvailableSettings.SPF_MISSING,
  AvailableSettings.SPF_MISSING_EXEMPT,
  AvailableSettings.PTR,
  AvailableSettings.PTR_EXEMPT
]

// export const useSenderAuthLogic = ({ delegateDirtyFormState }: UseSenderAuthLogicProps): UseSenderAuthLogic => {
export const useSenderAuthLogic = (): UseSenderAuthLogic => {
  const dispatch = useAppDispatch()
  const [form, setForm] = useState<SenderAuthSettings>({
    [AvailableSettings.DMARC]: '',
    [AvailableSettings.DKIM]: '',
    [AvailableSettings.SPF_HARDFAIL]: '',
    [AvailableSettings.SPF_SOFTFAIL]: '',
    [AvailableSettings.SPF_MISSING]: '',
    [AvailableSettings.PTR]: ''
  })
  const [isHelpDialogOpened, setIsHelpDialogOpened] = useState<boolean>(false)
  const [isDmarcExemptTableDirty, setIsDmarcExemptTableDirty] = useState<boolean>(false)
  const [isDkimExemptTableDirty, setIsDkimExemptTableDirty] = useState<boolean>(false)
  const [isSpfDomainExemptTableDirty, setIsSpfDomainExemptTableDirty] = useState<boolean>(false)
  const [shouldUpdateInitialForm, setShouldUpdateInitialForm] = useState<boolean>(false)

  const {
    pdDomainId,
    isUpdateAccountSettingsSuccess,
    isUpdateDomainSettingsSuccess,
    accountSettings,
    domainSettings,
    isGetAccountSettingsSuccess,
    isGetDomainSettingsSuccess
  } = useAppSelector(_stores => ({
    pdDomainId: _stores.auth.accessTokenObject?.pdDomainId,
    isGetAccountSettingsSuccess: isSuccess(_stores.settings.getAccountSettingsApiStatus),
    isGetDomainSettingsSuccess: isSuccess(_stores.settings.getDomainSettingsApiStatus),
    isUpdateAccountSettingsSuccess: isSuccess(_stores.settings.updateAccountSettingsApiStatus),
    isUpdateDomainSettingsSuccess: isSuccess(_stores.settings.updateDomainSettingsApiStatus),
    accountSettings: _stores.settings.accountSettings,
    domainSettings: _stores.settings.domainSettings
  }))

  const safeJsonArrayParse = useCallback(
    (rawValue: ValueOf<SettingsObject>, fallback: Array<DomainEntry | IpAddressEntry>) => {
      try {
        // Parse empty setting value to empty array
        if (typeof rawValue === 'undefined' || rawValue === null) {
          return []
        }

        // When setting is not empty it must be a string
        if (typeof rawValue !== 'string') {
          logger.error(`Unexpected type ${typeof rawValue} received for JSON parse: ${rawValue}`)
          return fallback
        }

        const parsed = JSON.parse(rawValue)
        // Once parsed, the value must be an array
        if (!Array.isArray(parsed)) {
          logger.error(`Parsed value is not an array: ${rawValue}`)
          return fallback
        }

        return parsed
      } catch (e) {
        logger.error(`Unexpected JSON parse error: ${rawValue}`)
        return fallback
      }
    },
    []
  )

  const {
    dmarcExemptEntries,
    dkimExemptEntries,
    spfExemptIpAddressEntries,
    spfExemptDomainEntries,
    missingSpfExemptIpAddressEntries,
    missingPtrExemptIpAddressEntries
  } = useMemo(() => {
    const settings = pdDomainId ? domainSettings : accountSettings
    return {
      dmarcExemptEntries: safeJsonArrayParse(settings.dmarc_exempt, []),
      dkimExemptEntries: safeJsonArrayParse(settings.dkim_exempt, []),
      spfExemptIpAddressEntries: safeJsonArrayParse(settings.spf_exempt, []),
      spfExemptDomainEntries: safeJsonArrayParse(settings.spf_env_exempt, []),
      missingSpfExemptIpAddressEntries: safeJsonArrayParse(settings.spf_missing_exempt, []),
      missingPtrExemptIpAddressEntries: safeJsonArrayParse(settings.ptr_exempt, [])
    }
  }, [pdDomainId, domainSettings, accountSettings, safeJsonArrayParse])

  const { canQuarantineSettings } = useInboundSettingsRights()

  const [isIpAddressTableDirty, setIsIpAddressTableDirty] = useState<boolean>(false)
  const [isDirtyForm, resetInitialForm] = useDirtyFormCheck([form])

  // init
  useEffect(() => {
    if (pdDomainId) {
      dispatch(getDomainSettings({ domainId: pdDomainId, settings: SETTINGS_LIST }))
    } else {
      dispatch(getAccountSettings(SETTINGS_LIST))
    }
    // eslint-disable-next-line
  }, [])

  const onHelpClick = useCallback(() => {
    setIsHelpDialogOpened(true)
  }, [])

  const onCloseHelp = useCallback(() => {
    setIsHelpDialogOpened(false)
  }, [])

  const onFormChange = useCallback(
    (name: AvailableSettings) => (newValue: any) => {
      setForm({ ...form, [name]: newValue })
    },
    [form]
  )

  const updateSettings = useCallback(
    (data: any[], type: AvailableSettings) => {
      if (pdDomainId) {
        dispatch(
          updateDomainSettings({
            domainId: pdDomainId,
            settings: { [type]: data }
          })
        )
      } else {
        dispatch(updateAccountSettings({ settings: { [type]: data } }))
      }
    },
    [dispatch, pdDomainId]
  )

  const onAddItem = useCallback(
    (settingName: AvailableSettings) =>
      (entries: Array<DomainEntry | IpAddressEntry>) =>
      (item: DomainEntry | IpAddressEntry) => {
        updateSettings([...entries, item], settingName)
      },
    [updateSettings]
  )

  const onRemoveItem = useCallback(
    (settingName: AvailableSettings) => (entries: Array<DomainEntry | IpAddressEntry>) => (id: string) => {
      const nextEntries = entries.filter(entry => entry.id !== id)
      updateSettings(nextEntries, settingName)
    },
    [updateSettings]
  )

  // Memoizing event handlers to prevent (unnecessarily) updating data table components that are depend on these
  // The data tables use the useDirtyFormCheck hook that changes isDirty from false to true when the user
  // starts typing in the table input. That flag change triggers a render cycle in the parent. If in this
  // render cycle new props are provided, react re-renders the data table component too, resulting in
  // losing the focus in the data table's input field
  const dataTableEventHandlers = useMemo(
    () => ({
      onAddDmarcExemptItem: onAddItem(AvailableSettings.DMARC_EXEMPT)(dmarcExemptEntries),
      onRemoveDmarcExemptItem: onRemoveItem(AvailableSettings.DMARC_EXEMPT)(dmarcExemptEntries),
      onAddDkimExemptItem: onAddItem(AvailableSettings.DKIM_EXEMPT)(dkimExemptEntries),
      onRemoveDkimExemptItem: onRemoveItem(AvailableSettings.DKIM_EXEMPT)(dkimExemptEntries),
      onAddSpfExemptIpAddressItem: onAddItem(AvailableSettings.SPF_EXEMPT)(spfExemptIpAddressEntries),
      onRemoveSpfExemptIpAddressItem: onRemoveItem(AvailableSettings.SPF_EXEMPT)(spfExemptIpAddressEntries),
      onAddSpfExemptDomainItem: onAddItem(AvailableSettings.SPF_DOMAIN_EXEMPT)(spfExemptDomainEntries),
      onRemoveSpfExemptDomainItem: onRemoveItem(AvailableSettings.SPF_DOMAIN_EXEMPT)(spfExemptDomainEntries),
      onAddMissingSpfExemptIpAddressItem: onAddItem(AvailableSettings.SPF_MISSING_EXEMPT)(
        missingSpfExemptIpAddressEntries
      ),
      onRemoveMissingSpfExemptIpAddressItem: onRemoveItem(AvailableSettings.SPF_MISSING_EXEMPT)(
        missingSpfExemptIpAddressEntries
      ),
      onAddMissingPtrExemptIpAddressItem: onAddItem(AvailableSettings.PTR_EXEMPT)(missingPtrExemptIpAddressEntries),
      onRemoveMissingPtrExemptIpAddressItem: onRemoveItem(AvailableSettings.PTR_EXEMPT)(
        missingPtrExemptIpAddressEntries
      )
    }),
    [
      dkimExemptEntries,
      dmarcExemptEntries,
      missingPtrExemptIpAddressEntries,
      missingSpfExemptIpAddressEntries,
      onAddItem,
      onRemoveItem,
      spfExemptDomainEntries,
      spfExemptIpAddressEntries
    ]
  )

  const onBulkEdit = useCallback((route: UiRoute) => {
    route.goto()
  }, [])

  const onSave = useCallback(() => {
    if (pdDomainId) {
      dispatch(
        updateDomainSettings({
          domainId: pdDomainId,
          settings: form
        })
      )
    } else {
      dispatch(updateAccountSettings({ settings: form }))
    }
  }, [dispatch, form, pdDomainId])

  const onCancelConfirm = useCallback(() => {
    const settings = pdDomainId ? domainSettings : accountSettings
    setForm({ ...form, [AvailableSettings.DMARC]: (settings.dmarc as SettingValue) || '' })
  }, [accountSettings, domainSettings, pdDomainId, form])

  const cplActionNumbers = useMemo(() => {
    if (canQuarantineSettings) {
      return [
        {
          label: 'block',
          value: SettingActionValue.BLOCK
        },
        {
          label: 'quarantine',
          value: SettingActionValue.QUARANTINE
        },
        {
          label: 'off',
          value: SettingActionValue.OFF
        }
      ]
    }
    return [
      {
        label: 'block',
        value: SettingActionValue.BLOCK
      },
      {
        label: 'off',
        value: SettingActionValue.OFF
      }
    ]
  }, [canQuarantineSettings])

  const cplActionStrings = useMemo(() => {
    if (canQuarantineSettings) {
      return [
        {
          label: 'block',
          value: SettingActionValueString.BLOCK
        },
        {
          label: 'quarantine',
          value: SettingActionValueString.QUARANTINE
        },
        {
          label: 'off',
          value: SettingActionValueString.OFF
        }
      ]
    }
    return [
      {
        label: 'block',
        value: SettingActionValueString.BLOCK
      },
      {
        label: 'off',
        value: SettingActionValueString.OFF
      }
    ]
  }, [canQuarantineSettings])

  // unmount
  useEffect(
    () => () => {
      dispatch(resetAccountAndDomainSettings())
    },
    [dispatch]
  )

  // set initialForm values
  useEffect(() => {
    if (shouldUpdateInitialForm) {
      setShouldUpdateInitialForm(false)
      resetInitialForm()
    }
  }, [shouldUpdateInitialForm, resetInitialForm])

  useEffect(() => {
    if (isGetAccountSettingsSuccess || isGetDomainSettingsSuccess) {
      const settings = pdDomainId ? domainSettings : accountSettings
      setForm({
        [AvailableSettings.DMARC]: (settings.dmarc as SettingValue) || SettingValue.DISABLED,
        [AvailableSettings.DKIM]: (settings.dkim as SettingActionValue) || SettingActionValue.OFF,
        [AvailableSettings.SPF_HARDFAIL]:
          (settings.spf_hardfail as SettingActionValueString) || SettingActionValueString.OFF,
        [AvailableSettings.SPF_SOFTFAIL]:
          (settings.spf_softfail as SettingActionValueString) || SettingActionValueString.OFF,
        [AvailableSettings.SPF_MISSING]: (settings.spf_missing as SettingActionValue) || SettingActionValue.OFF,
        [AvailableSettings.PTR]: (settings.ptr as SettingValue) || SettingValue.DISABLED
      })
      setShouldUpdateInitialForm(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetAccountSettingsSuccess, isGetDomainSettingsSuccess])

  // update state on add/remove
  useEffect(() => {
    if (isUpdateAccountSettingsSuccess || isUpdateDomainSettingsSuccess) {
      if (pdDomainId) {
        dispatch(getDomainSettings({ domainId: pdDomainId, settings: SETTINGS_LIST }))
      } else {
        dispatch(getAccountSettings(SETTINGS_LIST))
      }
      setShouldUpdateInitialForm(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateAccountSettingsSuccess, isUpdateDomainSettingsSuccess])

  return useMemo(
    () => [
      {
        cplActionNumbers,
        cplActionStrings,
        form,
        hasPageChanges:
          isDirtyForm ||
          isIpAddressTableDirty ||
          isDmarcExemptTableDirty ||
          isDkimExemptTableDirty ||
          isSpfDomainExemptTableDirty,
        dmarcExemptEntries,
        dkimExemptEntries,
        spfExemptIpAddressEntries,
        spfExemptDomainEntries,
        missingSpfExemptIpAddressEntries,
        missingPtrExemptIpAddressEntries
      },
      {
        ...dataTableEventHandlers,
        onBulkEdit,
        onFormChange,
        onSave,
        onCancelConfirm,
        helpConfig: {
          isOpen: isHelpDialogOpened,
          onHelpClick,
          onCloseHelp
        },
        setIsIpAddressTableDirty,
        setIsDmarcExemptTableDirty,
        setIsDkimExemptTableDirty,
        setIsSpfDomainExemptTableDirty
      }
    ],
    [
      cplActionNumbers,
      cplActionStrings,
      form,
      isDirtyForm,
      isIpAddressTableDirty,
      isDmarcExemptTableDirty,
      isDkimExemptTableDirty,
      isSpfDomainExemptTableDirty,
      dmarcExemptEntries,
      dkimExemptEntries,
      spfExemptIpAddressEntries,
      spfExemptDomainEntries,
      missingSpfExemptIpAddressEntries,
      missingPtrExemptIpAddressEntries,
      dataTableEventHandlers,
      onBulkEdit,
      onFormChange,
      onSave,
      onCancelConfirm,
      isHelpDialogOpened,
      onHelpClick,
      onCloseHelp
    ]
  )
}
