import React, { useMemo } from 'react'

import { Button, DataTable, DataTableColumn, Grid, Radio, TextField, Typography } from '@barracuda-internal/bds-core'

import InboundAtp from 'components/pages/support/help/inboundAtp/InboundAtp'
import { GROUP_TITLE, TOPICS } from 'components/pages/support/config'
import { useFormatMessage } from 'lib/localization'
import { AvailableSettings } from 'types/Settings'
import styles from 'components/pages/atpSettings/atpSettingsStyles'
import pageStyle from 'components/libs/layout/pages/pagesLayoutStyles'
import { AtpExemptEmail, AtpExemptionType, AtpExemptIp, AtpScanMode } from 'types/atpSettings'
import SettingsBlock from 'components/libs/settings/SettingsBlock'
import { useAtpSettingsLogic } from 'components/pages/atpSettings/useAtpSettingsLogic'
import { Cell } from 'components/libs/grid/cell'
import IpTextField from 'components/libs/IpTextField/IpTextField'
import {
  FormRow,
  FormSpacer,
  InlineRadioGroup,
  PageContainer,
  PageSubtitle,
  PageTitle
} from 'components/libs/layout/pages/pageLayout'
import routesConfig from 'lib/routesConfig'
import SettingsRowHelper from 'components/libs/settings/SettingsRowHelper'
import ContentLoaderMessage from 'components/libs/contentLoader/ContentLoaderMessage'

const BASE_I18N_KEY = 'ess.atp_settings'

const AtpSettingsPage: React.FC = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const { page, form } = useAtpSettingsLogic()
  const { ConfirmDeleteEmailDialog } = form.emailsTable
  const { ConfirmDeleteIpDialog } = form.ipsTable
  const classes = styles()
  const pageClasses = pageStyle()

  return useMemo(
    () => (
      <>
        {!form.isGetSettingsPending && !form.isAntiVirusEnabled && (
          <Grid>
            <ContentLoaderMessage
              withIcon={false}
              message={formatMessage('anti_virus_disabled', {
                br: () => <br />
              })}
            />
          </Grid>
        )}
        <PageContainer
          isLoading={form.isGetSettingsPending}
          isFailedToLoad={form.isGetSettingsFailed}
          dirtyCheck
          isDirtyForm={form.hasChanges || form.emailsTable.hasChanges || form.ipsTable.hasChanges}
        >
          <PageTitle
            title={formatMessage('title')}
            onOpenSupport={form.helpConfig.onHelpClick}
            onCancel={() => routesConfig.ATP_SETTINGS.goto()}
            onSubmit={form.onSave}
            submitInProgress={form.isUpdatePending}
            isDisabledSubmit={!(form.hasChanges || form.emailsTable.hasChanges || form.ipsTable.hasChanges)}
            help={{
              content: InboundAtp,
              onClose: form.helpConfig.onCloseHelp,
              title: TOPICS.atpSettings,
              group: GROUP_TITLE.inboundSettings,
              isHelpOpen: form.helpConfig.isOpen
            }}
          />
          <FormSpacer />
          {!form.isGetSettingsPending && form.isAntiVirusEnabled && (
            <>
              <FormRow contentIndent={4} rowTitle={formatMessage('enable_advanced_threat_protection')}>
                <InlineRadioGroup
                  name={AvailableSettings.ATD}
                  value={form.settings[AvailableSettings.ATD]}
                  onChange={form.onFormChange(AvailableSettings.ATD)}
                  disabled={form.isUpdatePending}
                  values={[AtpScanMode.DELIVER_SCAN, AtpScanMode.SCAN_DELIVER, AtpScanMode.DISABLE]}
                  labels={[formatMessage('deliver_scan'), formatMessage('scan_deliver'), formatMessage('no')]}
                />
              </FormRow>
              <SettingsRowHelper
                className={classes.atpScanHelper}
                variant="danger"
                labelColumnSize={4}
                text={formatMessage('disabling_antivirus')}
              />
              <FormSpacer />
              <FormRow contentIndent={4} rowTitle={formatMessage('notify_admin')}>
                <InlineRadioGroup
                  name={AvailableSettings.ATD_NOTIFY}
                  value={form.isAtpNotify}
                  onChange={form.onFormChange(AvailableSettings.ATD_NOTIFY)}
                  disabled={page.isNotifyAdminDisabled}
                  labels={[formatMessage('yes'), formatMessage('no')]}
                />
              </FormRow>
              <FormSpacer />
              <FormRow contentIndent={4} rowTitle={formatMessage('admin_emails')}>
                <TextField
                  className={classes.adminEmailRow}
                  name={AvailableSettings.ATD_ADMIN_EMAILS}
                  label={formatMessage('admin_emails')}
                  onChange={form.onFormChange(AvailableSettings.ATD_ADMIN_EMAILS)}
                  value={form.settings[AvailableSettings.ATD_ADMIN_EMAILS]}
                  disabled={page.isAdminEmailDisabled}
                  error={!!form.errors.adminEmailsError}
                  helperText={
                    form.errors.adminEmailsError
                      ? formatMessage(form.errors.adminEmailsError, { email: form.errors.firstInvalidAdminEmail })
                      : ''
                  }
                  fullWidth
                  size="small"
                />
              </FormRow>
              <FormSpacer />
              <FormSpacer />
              <PageSubtitle>{formatMessage('exemptions_by_email_addresses_domains')}</PageSubtitle>
              <Grid className={pageClasses.pageContainer}>
                <SettingsBlock disabled={page.isExemptionsDisabled}>
                  <Grid container className={classes.sectionNote}>
                    <Grid item xs={12}>
                      <Typography className={classes.italic} variant="body2">
                        {formatMessage('atp_exemptions_note')}
                      </Typography>
                    </Grid>
                  </Grid>

                  {/* EMAIL ADDRESS OR DOMAIN EXEMPTIONS */}
                  <Grid container className={classes.tableTitle}>
                    <Grid item xs={12}>
                      <Typography variant="h4">{formatMessage('exemptions_by_email_addresses_domains')}</Typography>
                    </Grid>
                  </Grid>
                  <DataTable data={form.emailsTable.tableData.data} sort={form.emailsTable.sort}>
                    <DataTableColumn
                      sortable
                      field="email"
                      title={formatMessage('exemptions_by_email_addresses_domains_short')}
                      cell={({ dataItem }: { dataItem: AtpExemptEmail }) => {
                        if (dataItem.id === '') {
                          return (
                            <Cell>
                              <TextField
                                defaultValue={form.emailsTable.newEmail.email.current?.value || ''}
                                inputRef={form.emailsTable.newEmail.email}
                                onChange={form.emailsTable.onNewEmailChange}
                                size="small"
                                name="email"
                                error={!!form.errors.newEmailOrDomainError}
                                helperText={
                                  form.errors.newEmailOrDomainError
                                    ? formatMessage(form.errors.newEmailOrDomainError, {
                                        email: form.emailsTable.newEmail.email.current?.value
                                      })
                                    : ''
                                }
                                fullWidth
                              />
                            </Cell>
                          )
                        }
                        return (
                          <Cell>
                            <Typography variant="body2">{dataItem.email}</Typography>
                          </Cell>
                        )
                      }}
                    />
                    <DataTableColumn
                      title="Sender"
                      cell={({ dataItem }: { dataItem: AtpExemptEmail }) => {
                        if (dataItem.id === '') {
                          return <Cell>{form.emailsTable.newEmailTypeRadios.get(AtpExemptionType.SENDER)}</Cell>
                        }
                        return (
                          <Cell>
                            {dataItem.type === AtpExemptionType.SENDER && (
                              <Radio
                                checked
                                defaultValue=""
                                size="small"
                                style={{ pointerEvents: 'none' }}
                                color="primary"
                              />
                            )}
                            {dataItem.type === AtpExemptionType.RECIPIENT && (
                              <Radio disabled defaultValue="" size="small" style={{ pointerEvents: 'none' }} />
                            )}
                          </Cell>
                        )
                      }}
                    />
                    <DataTableColumn
                      title="Recipient"
                      cell={({ dataItem }: { dataItem: AtpExemptEmail }) => {
                        if (dataItem.id === '') {
                          return <Cell>{form.emailsTable.newEmailTypeRadios.get(AtpExemptionType.RECIPIENT)}</Cell>
                        }
                        return (
                          <Cell>
                            {dataItem.type === AtpExemptionType.RECIPIENT && (
                              <Radio
                                checked
                                defaultValue=""
                                size="small"
                                style={{ pointerEvents: 'none' }}
                                color="primary"
                              />
                            )}
                            {dataItem.type === AtpExemptionType.SENDER && (
                              <Radio disabled defaultValue="" size="small" style={{ pointerEvents: 'none' }} />
                            )}
                          </Cell>
                        )
                      }}
                    />
                    <DataTableColumn
                      sortable
                      field="comment"
                      title="Comment"
                      cell={({ dataItem }: { dataItem: AtpExemptEmail }) => {
                        if (dataItem.id === '') {
                          return (
                            <Cell>
                              <TextField
                                defaultValue={form.emailsTable.newEmail.comment.current?.value || ''}
                                inputRef={form.emailsTable.newEmail.comment}
                                onChange={form.emailsTable.onNewEmailChange}
                                size="small"
                                name="comment"
                              />
                            </Cell>
                          )
                        }
                        return (
                          <Cell>
                            <Typography variant="body2">{dataItem.comment}</Typography>
                          </Cell>
                        )
                      }}
                    />
                    <DataTableColumn
                      title="Actions"
                      cell={({ dataItem }: { dataItem: AtpExemptEmail }) => (
                        <Cell>
                          {dataItem.id === '' && (
                            <Button onClick={form.emailsTable.onAddEmail} variant="text" color="primary" size="small">
                              Add
                            </Button>
                          )}
                          {dataItem.id !== '' && (
                            <Button
                              onClick={() => form.emailsTable.onDeleteEmail(dataItem.id)}
                              variant="text"
                              color="primary"
                              size="small"
                            >
                              Delete
                            </Button>
                          )}
                        </Cell>
                      )}
                    />
                  </DataTable>

                  {/* IP EXEMPTIONS */}
                  <Grid container className={`${classes.tableTitle} ${classes.tableSpacing}`}>
                    <Grid item xs={12}>
                      <Typography variant="h4">{formatMessage('exemptions_by_ip_addresses')}</Typography>
                    </Grid>
                  </Grid>
                  <DataTable data={form.ipsTable.tableData.data} sort={form.ipsTable.sort}>
                    <DataTableColumn
                      sortable
                      field="address"
                      title={formatMessage('exemptions_by_ip_addresses_short')}
                      cell={({ dataItem }: { dataItem: AtpExemptIp }) => {
                        if (dataItem.id === '') {
                          return (
                            <Cell>
                              <IpTextField
                                defaultValue={form.ipsTable.newIp.address.current?.value || ''}
                                inputRef={form.ipsTable.newIp.address}
                                onChange={form.ipsTable.onNewIpChange}
                                size="small"
                                name="address"
                                error={!!form.errors.newIpAddressError}
                                helperText={
                                  form.errors.newIpAddressError ? formatMessage(form.errors.newIpAddressError) : ''
                                }
                                fullWidth
                              />
                            </Cell>
                          )
                        }
                        return (
                          <Cell>
                            <Typography variant="body2">{dataItem.address}</Typography>
                          </Cell>
                        )
                      }}
                    />
                    <DataTableColumn
                      sortable
                      field="netmask"
                      title={formatMessage('netmask')}
                      cell={({ dataItem }: { dataItem: AtpExemptIp }) => {
                        if (dataItem.id === '') {
                          return (
                            <Cell>
                              <IpTextField
                                defaultValue={form.ipsTable.newIp.netmask.current?.value || ''}
                                inputRef={form.ipsTable.newIp.netmask}
                                onChange={form.ipsTable.onNewIpChange}
                                size="small"
                                name="netmask"
                                error={!!form.errors.newIpNetmaskError}
                                helperText={
                                  form.errors.newIpNetmaskError ? formatMessage(form.errors.newIpNetmaskError) : ''
                                }
                                fullWidth
                              />
                            </Cell>
                          )
                        }
                        return (
                          <Cell>
                            <Typography variant="body2">{dataItem.netmask}</Typography>
                          </Cell>
                        )
                      }}
                    />
                    <DataTableColumn
                      sortable
                      field="comment"
                      title="Comment"
                      cell={({ dataItem }: { dataItem: AtpExemptIp }) => {
                        if (dataItem.id === '') {
                          return (
                            <Cell>
                              <TextField
                                defaultValue={form.ipsTable.newIp.comment.current?.value || ''}
                                inputRef={form.ipsTable.newIp.comment}
                                onChange={form.ipsTable.onNewIpChange}
                                size="small"
                                name="comment"
                                fullWidth
                              />
                            </Cell>
                          )
                        }
                        return (
                          <Cell>
                            <Typography variant="body2">{dataItem.comment}</Typography>
                          </Cell>
                        )
                      }}
                    />
                    <DataTableColumn
                      title="Actions"
                      cell={({ dataItem }: { dataItem: AtpExemptIp }) => (
                        <Cell>
                          {dataItem.id === '' && (
                            <Button onClick={form.ipsTable.onAddIp} variant="text" color="primary" size="small">
                              Add
                            </Button>
                          )}
                          {dataItem.id !== '' && (
                            <Button
                              onClick={() => form.ipsTable.onDeleteIp(dataItem.id)}
                              variant="text"
                              color="primary"
                              size="small"
                            >
                              Delete
                            </Button>
                          )}
                        </Cell>
                      )}
                    />
                  </DataTable>
                </SettingsBlock>
                <Grid container className={classes.tableSpacing} />
              </Grid>
            </>
          )}
        </PageContainer>
        <ConfirmDeleteEmailDialog />
        <ConfirmDeleteIpDialog />
      </>
    ),
    [form, formatMessage, page, classes, ConfirmDeleteEmailDialog, ConfirmDeleteIpDialog, pageClasses]
  )
}

export default AtpSettingsPage
