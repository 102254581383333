/* eslint-disable quotes */
const outbound_settings = {
  tabs: {
    sender_ip: 'Sender IP Address Ranges',
    tagline: 'Tagline/Footer',
    encryption: 'DLP/Encryption',
    content_policies: 'Content Policies',
    notifications: 'Notifications'
  },
  save: 'Save Changes',
  cancel: 'Cancel',
  bulk_edit: 'Bulk Edit',
  sender_ip: {
    title: 'Sender IP Address Ranges',
    subtitle: 'Specify the IP address ranges that are allowed to send outgoing emails from your domains.',
    domain_column: 'Logging & Policy Domain',
    ip_column: 'IP Address',
    netmask: 'Netmask',
    comment: 'Comment',
    actions: 'Actions',
    add: 'Add',
    remove: 'Remove',
    bulk_edit: 'Bulk Edit',
    confirm_removal: 'Confirm removal?',
    empty_domain: 'Please specify a domain name',
    invalid_ip_address: 'Please enter a valid IP address'
  },
  tagline: {
    title: 'Tagline/Footer',
    append_label: 'Append Tagline',
    append_description: 'Append the following text to outgoing emails',
    tagline_label: 'Tagline/Footer text',
    characters_left: 'Characters left: {charactersLeft}'
  },
  encryption: {
    title: 'DLP/Encryption',
    all_messages: 'Require TLS connection for all outbound messages',
    selected_domains: 'Require TLS connection for select recipient domains',
    table_description_all:
      'To force Email Gateway Defense to require a secure TLS connection to all domains <b>except</b> a certain recipient domain, please enter the exempted recipient domain name and click Add.',
    table_description_select:
      'To force Email Gateway Defense to require a secure TLS connection to a recipient domain, enter the recipient domain name and click Add.',
    table_sub_description: 'If a TLS connection cannot be established, mail is not sent to the recipient domain.',
    save_changes_helper: 'Save changes in order to enable bulk edit'
  },
  content_policies: {
    title: 'Content Policies',
    attachment_filter: 'Attachment Filter',
    filter_type: 'Filter Type',
    pattern: 'Pattern',
    check_archives: 'Check Archives',
    action: 'Action',
    comment: 'Comment',
    actions: 'Actions',
    add: 'Add',
    remove: 'Remove',
    no: 'No',
    yes: 'Yes',
    scan: 'Scan',
    do_not_scan: 'Do not scan',
    block: 'Block',
    ignore: 'Ignore',
    allow: 'Allow',
    quarantine: 'Quarantine',
    encrypt: 'Encrypt',
    not_encrypt: 'Do not encrypt',
    filename: 'File Name',
    mime: 'MIME Type',
    message_content_filter: 'Message Content Filter',
    subject: 'Subject',
    headers: 'Headers',
    body: 'Body',
    attachments: 'Attachments',
    sender: 'Sender',
    recipient: 'Recipient',
    match: 'Match',
    one_match_filter: 'At least one match filter must be selected.',
    duplicate_value: 'Duplicate entry exists.',
    archive_filtering: 'Password Protected Archive Filtering:',
    msoffice_filtering: 'Password Protected Microsoft Office Documents:',
    pdf_filtering: 'Password Protected PDF Filtering:',
    predefined_filters: 'Predefined Filters',
    predefined_filters_description:
      'Filter outbound messages for pre-defined data leakage patterns (specific to U.S.) in subject, body and attachments. Recommended Action: Encrypt',
    creditcard: 'Credit Cards',
    ssn: 'Social Security Numbers',
    privacy: 'Privacy',
    hipaa: 'HIPAA',
    predefined_filter_exceptions: 'Predefined Filter Exceptions',
    predefined_filter_exceptions_description: 'Add exemptions to predefined HIPAA or Privacy content filters.',
    type: 'Type',
    phone_number: 'Phone Number',
    address: 'Address',
    missing_filtering_pattern: 'Missing filtering pattern.',
    error_invalid_phone_number: 'Invalid phone number',
    save: 'Save'
  },
  notifications: {
    title: 'Notifications',
    admin_quarantine_notification: 'Admin Quarantine Notification',
    admin_quarantine_notification_description:
      'Configure the frequency of notification reports the administrator receives summarizing outbound quarantine information.',
    notification_interval: 'Notification Interval',
    never: 'Never',
    immediately: 'Immediately',
    scheduled: 'Scheduled',
    schedule_notification_intervals: 'Schedule notification intervals',
    matrix_description:
      'Notifications will be sent at any time within the hour block chosen. <br></br> Click and drag to select. Hold Ctrl while dragging to unselect.',
    notification_address: 'Notification Address',
    sender_quarantine_notification: 'Sender Quarantine Notification',
    sender_quarantine_notification_description:
      'Configure the Non-Delivery Report (NDR) that, if enabled, goes to the sender of an outbound message that was quarantined for any reason. This NDR indicates that the message awaits evaluation and action by the administrator.',
    quarantine_sender_notification: 'Quarantine Sender Notification',
    yes: 'Yes',
    no: 'No',
    quarantine_notification_address: 'Quarantine Notification Address',
    quarantine_notification_subject: 'Quarantine Notification Subject',
    quarantine_notification_subject_placeholder: '**Message you sent violated policy**',
    quarantine_notification_template: 'Quarantine Notification Template',
    quarantine_notification_template_placeholder:
      "Your message to: %R was quarantined by Barracuda Networks' Email Gateway Defense. The email you sent with the following subject has NOT BEEN DELIVERED and is awaiting evaluation by the account or domain administrator.\n\nSubject: %j",
    notification_to_sender: 'Notification to Sender for Rejected Message',
    notification_to_sender_description:
      'Configure the Non-Delivery Report (NDR) that is emailed to the sender of a quarantined outbound message if that message is rejected by the administrator.',
    reject_notification_address: 'Reject Notification Address',
    reject_notification_subject: 'Reject Notification Subject',
    reject_notification_subject_placeholder: '**Message you sent violated policy**',
    reject_notification_template: 'Reject Notification Template',
    reject_notification_template_placeholder:
      'Your message to: %R was rejected by the domain administrator and will NOT BE DELIVERED. Please contact your email administrator.\n\nSubject: %j',
    admin_spam_notification: 'Admin Spam Notification',
    admin_spam_notification_description:
      'Configure the email address that receives notifications when users are suspected of sending outbound spam.',
    spam_notification_address: 'Spam Notification Address',
    invalid_email: '{email} is not a valid email address. Please make sure there are no spaces after the comma.',
    email_belong_to_manage_domain: 'The email address must belong to a domain you manage.'
  }
}
/* eslint-enable quotes */

export default outbound_settings
