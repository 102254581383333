import { Action, ReportMessage } from 'types/Messages'
import IconEncryption from 'assets/images/icons/icon_encryption.svg'
import IconEncryptionInbound from 'assets/images/icons/icon_encryption_inbound.svg'
import IconEncryptionOutbound from 'assets/images/icons/icon_encryption_outbound.svg'
import IconDelivered from 'assets/images/icons/icon_status_delivered.svg'
import IconSpooled from 'assets/images/icons/icon_status_spooled.svg'
import IconDeferred from 'assets/images/icons/icon_status_deferred.svg'
import IconRejected from 'assets/images/icons/icon_status_rejected.svg'
import IconDeliveredSome from 'assets/images/icons/icon_status_delivered_some.svg'

const BASE_I18N_KEY = 'ess.message_log'

export const mapFrom = (item: ReportMessage, fromColumnDisplay: string) => {
  const from = item.headerFrom || item.envelopeFrom || ''
  if (fromColumnDisplay === 'both') {
    return from
  }
  if (fromColumnDisplay === 'email') {
    const parts = from.split('<')
    return parts.length > 1 ? parts[1].replace('>', '') : from
  }
  if (from) {
    const parts = from.split('<')
    if (parts.length === 0) {
      return from
    }
    if (parts.length > 1 && parts[0].trim() === '') {
      return parts[1].replace('>', '').trim()
    }
    return parts[0].replace(/"/g, '').trim()
  }
  return undefined
}

export const mapSize = (bytes: number) => {
  if (bytes === 0) {
    return '0 B'
  }

  const e = Math.floor(Math.log(bytes) / Math.log(1024))
  return `${(bytes / 1024 ** e).toFixed(0)} ${' KMGTP'.charAt(e)}B`
}

export const mapAction = (action: string) => {
  if (!action) {
    return { text: '', tooltip: '', suffix: '' }
  }

  if (action.includes(':')) {
    const partial = action.split(':')[0]
    return {
      text: `action.${partial}`,
      suffix: '...',
      tooltip: `action_for_some.${partial}`
    }
  }

  return {
    text: `action.${action}`,
    suffix: '',
    tooltip: `action.${action}`
  }
}

export const mapReasons = (reasons: string[], formatMessage: any) => {
  if (!reasons || !reasons.length) {
    return ''
  }

  const translatedReasons: string[] = []

  reasons.map((reason: string) => {
    translatedReasons.push(formatMessage(`reason.${reason}`))
    return reason
  })

  return translatedReasons.join(', ')
}

export const mapDeliveryStatus = (status: string, intl: any) => {
  if (!status) {
    return ''
  }

  return intl.formatMessage({ id: `${BASE_I18N_KEY}.status.${status}` })
}

export const getStartDate = (daysAgo = 30, roundOffHours = false) => {
  // Get the start date that is daysAgo ago
  const startDate = new Date(Date.now() - daysAgo * 24 * 3600 * 1000)

  // Round off the hours
  if (roundOffHours) {
    startDate.setHours(0, 0, 0, 0)
  }

  return Math.floor(startDate.getTime() / 1000)
}

export const getEndDate = (roundOffHours = false) => {
  const endDate = new Date(Date.now())

  // Round off the hours
  if (roundOffHours) {
    endDate.setHours(24, 0, 0, 0)
  }

  return Math.floor(endDate.getTime() / 1000)
}

export const encryptionInfo = (item: ReportMessage) => {
  if (!item.tlsIn && item.tlsOut === 'none') {
    return {
      text: '',
      icon: null
    }
  }

  if (item.tlsIn && item.tlsOut === 'all') {
    return {
      text: 'encryption.inbound_outbound_encrypted',
      icon: IconEncryption
    }
  }
  if (item.tlsIn && item.tlsOut === 'none') {
    return {
      text: 'encryption.inbound_encrypted',
      icon: IconEncryptionInbound
    }
  }
  if (item.tlsIn && item.tlsOut === 'some') {
    return {
      text: 'encryption.encrypted_for_some',
      icon: IconEncryption
    }
  }
  return {
    text: 'encryption.outbound_encrypted',
    icon: IconEncryptionOutbound
  }
}

export const mapDeliveryInfo = (deliveryStatus: string, status = '') => {
  const info = {
    text: 'status.delivered',
    icon: IconDelivered
  }

  if (deliveryStatus === 'delivered') {
    info.text = 'status.delivered'
    info.icon = IconDelivered
  } else if (deliveryStatus === 'delivered_partial' || status === Action.blockedPartial) {
    info.text = 'status.delivered_partial'
    info.icon = IconDeliveredSome
  } else if (deliveryStatus === 'not_delivered') {
    info.text = 'status.not_delivered'
    info.icon = IconRejected
  } else if (deliveryStatus === 'rejected') {
    info.text = 'status.rejected'
    info.icon = IconRejected
  } else if (deliveryStatus === 'spooled') {
    info.text = 'status.spooled'
    info.icon = IconSpooled
  } else if (deliveryStatus === 'deferred') {
    info.text = 'status.deferred'
    info.icon = IconDeferred
  }

  return info
}

export const mapDeliveryColor = (status: string, remediated: boolean) => {
  switch (true) {
    case remediated:
      return 'purple100'
    case status === Action.allowedPartial:
      return 'redOrange200'
    case status.startsWith(Action.allowed):
      return 'green050'
    case status === Action.blockedPartial:
      return 'redOrange200'
    case status.startsWith(Action.blocked):
      return 'red200'
    case status.startsWith(Action.quarantined):
      return 'orange100'
    case status.startsWith(Action.encrypted):
      return 'indigo100'
    case status === Action.deferredPartial:
      return 'redOrange200'
    case status.startsWith(Action.deferred):
      return 'scaleGray050'
    default:
      return 'scaleGray050'
  }
}
