/* eslint-disable quotes */
export default {
  support: 'Ayuda',
  previous_page: 'Volver a la página anterior',
  print: 'Vista imprimible',
  search_box_title: 'Buscar temas de apoyo',
  enter_keywords: 'Introducir palabras clave',
  search_box_example: 'Ejemplo: añadir servidor de correo, configuración de entrada',
  no_search_results: 'Su búsqueda no ha dado ningún resultado.',
  documentation: 'Documentación',
  documentation_content:
    'Toda la documentación del administrador para Barracuda Email Gateway Defense, incluida la guía de introducción, se puede encontrar en Barracuda TechLibrary con enlace aquí:',
  support_access: 'Apoyo Acceso',
  support_access_content: 'Support Access ofrece a Barracuda Networks la posibilidad de ver su cuenta de forma remota.',
  documentation_link1: 'Campus Barracuda',
  documentation_link2: 'Notas de publicación',
  linked_accounts: 'Cuentas vinculadas',
  sender_policy: 'Política de remitentes',
  quarantine_notification: 'Notificación de cuarentena',
  message_log: 'Mensaje registro',
  search_result: 'Resultado de la búsqueda',
  cpl_product_name: 'Barracuda Cloud Protection Layer',
  no_cpl_product_name: 'Email Gateway Defense',
  status: 'Estado',
  enabled: 'Activado',
  disabled: 'Desactivado',
  enable_support_access: 'Habilitar el acceso al soporte',
  disable_support_access: 'Desactivar el acceso al soporte',
  cancel: 'Cancelar',
  message_access: 'Acceso de los socios a los mensajes',
  message_access_content:
    'El acceso de los socios a los mensajes permite a los socios de Barracuda Networks ver el contenido (cuerpo del mensaje) de los mensajes de correo electrónico de las cuentas que gestionan.',
  enable_message_access: 'Activar el acceso al cuerpo del mensaje',
  disable_message_access: 'Desactivar el acceso al cuerpo del mensaje',
  account_configuration: 'Guardar y aplicar los ajustes de configuración de la cuenta.',
  account_configuration_content:
    'Guarde los ajustes actuales de configuración global de la cuenta y aplíquelos más adelante a ésta u otras cuentas.',
  save: 'Guardar',
  apply: 'Solicitar',
  topics: {
    domains: 'Dominios',
    inbound_settings: 'Ajustes de entrada',
    outbound_settings: 'Ajustes de salida',
    user_topics: 'Usuarios',
    end_user: 'Usuario final',
    self_service_portal_topics: 'Portal de autoservicio',
    general_topics: 'General',
    report_topics: 'Informes',
    account_management_topics: 'Gestión de cuentas',

    seen_recipients: 'Destinatarios vistos',
    dashboard: 'Cuadro de mandos',
    staying_in_compliance: 'Cumplir la normativa',
    message_log: 'Mensaje registro',
    atp_log: 'ATP registro',
    audit_log: 'Registro de auditoría',
    domain_verification: 'Verificación de dominios',
    mail_server_test: 'Prueba del servidor de correo',
    domain_manager: 'Administrador de dominios',
    domain_settings: 'Configuración del dominio',
    recipients_list: 'Lista de destinatarios',
    anti_spam_antivirus: 'Anti-Spam/Antivirus',
    custom_rbls: 'RBL personalizados',
    ip_address_policies: 'Políticas de direcciones IP',
    regional_policies: 'Políticas regionales',
    recipient_policies: 'Políticas de los beneficiarios',
    sender_policies: 'Políticas del remitente',
    sender_authentication: 'Autenticación del remitente',
    content_policies: 'Políticas de contenidos',
    rate_control: 'Control de tarifas',
    anti_phishing: 'Anti Phishing',
    atp_settings: 'ATP Ajustes',
    sender_ip_address_ranges: 'Rangos de direcciones IP del remitente',
    anti_virus: 'Anti-Virus',
    tagline_footer: 'Lema/Pie de página',
    dlp_encryption: 'DLP/Encriptación',
    notifications: 'Notificaciones',
    outbound_quarantine: 'Cuarentena de salida',
    default_policy: 'Política por defecto',
    add_update_users: 'Añadir/actualizar usuarios',
    email_continuity: 'Continuidad del correo electrónico',
    quarantine_notification: 'Notificación de cuarentena',
    users_list: 'Lista de usuarios',
    linked_accounts: 'Cuentas vinculadas',
    sender_policy: 'Política de remitentes',
    specify_primary_email_domain: 'Paso 1 - Especifique el dominio de correo electrónico principal',
    specify_email_servers: 'Paso 2 - Especifique los servidores de correo electrónico',
    configure_settings: 'Paso 3 - Configurar los ajustes',
    configure_outbound_settings_for_office_365: 'Paso 4 - Configurar los ajustes de salida para Office365',
    route_email_through_barracuda_networks: 'Paso 5 - Enrutar el correo electrónico a través de la red Barracudas',
    regular_expressions: 'Expresiones regulares',
    generate_report_this_domain: 'Generar informe - Este dominio',
    generate_report_all_domains: 'Generar informe - Todos los dominios',
    syslog_integration: 'Integración de Syslog',
    configure_mx_records: 'Configurar registros MX',
    machine_learning: 'Aprendizaje automático'
  },
  dialog: {
    support_access: {
      following_permission:
        'Habilitar el acceso de soporte dará a Barracuda Networks los siguientes permisos para su cuenta',
      make_changes: 'Realizar Cambios',
      make_change_text: 'Barracuda Networks puede realizar cambios en esta cuenta en su nombre',
      email_information: 'Ver información de correo electrónico',
      email_information_text: 'Barracuda Networks puede ver las cabeceras de los mensajes en esta cuenta',
      email_content: 'Ver contenido del correo electrónico',
      email_content_text: 'Barracuda Networks puede ver el cuerpo de los mensajes en esta cuenta'
    },
    message_access: {
      following_permission: 'Activar el acceso de socios a los mensajes permite a su socio/MSP:',
      email_content: 'Ver contenido del correo electrónico',
      email_content_text: 'El cuerpo del mensaje estará disponible',
      email_attachment: 'Ver archivos adjuntos de correo electrónico',
      email_attachment_text: 'Poder ver archivos adjuntos de correo electrónico en esta cuenta'
    },
    account_configuration: {
      save_config_title: 'Guardar ajustes de configuración',
      save_config_content_saved: 'Se han guardado los ajustes actuales de configuración de su cuenta global.',
      save_config_content_apply:
        'Guarde este ID de ajustes de configuración para aplicar estos ajustes a esta u otras cuentas.',
      save_config_content_valid: 'Tenga en cuenta que esta identificación es válida durante 30 días.',
      save_config_content_id: 'Ajustes de configuración ID:',
      cancel: 'Cancelar',
      copy_to_clipboard: 'Copiar al portapapeles',
      copied_to_clipboard: 'Ficha copiada!',
      apply_config_title: 'Aplicar los ajustes de configuración',
      apply_config_content_recommend:
        'Está a punto de sobrescribir la configuración actual. Barracuda Networks recomienda hacer una copia de seguridad de la configuración actual antes de continuar. ',
      apply_config_content_more: 'Más información',
      apply_config_content_enter: 'Introduzca el ID de los ajustes de configuración que desea aplicar a esta cuenta:'
    }
  }
}
/* eslint-enable quotes */
