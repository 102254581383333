import reactHtmlParser from 'react-html-parser'

export default function highlightText(text: string, searchString: string) {
  if (!searchString.length) {
    return text
  }

  const replace = new RegExp(searchString, 'gi')
  const highlightedText = text.replace(replace, '<mark>$&</mark>')
  text.replace(' ', '&nbsp;')
  // needs to convert spaces to HTML spaces to avoid invalid HTML parser issues
  return reactHtmlParser(highlightedText.replace(' ', '&nbsp;'))
}
