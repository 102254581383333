import React, { useMemo } from 'react'
import {
  Button,
  DataTable,
  DataTableColumn,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@barracuda-internal/bds-core'
import { Block, CheckmarkCircle } from '@barracuda-internal/bds-core/dist/Icons/Core'

import { Cell } from 'components/libs/grid/cell'
import { useFormatMessage } from 'lib/localization'
import IpTextField from 'components/libs/IpTextField/IpTextField'
import { AVAILABLE_IP_EXEMPTION_POLICIES, IpAddressEntry, IpPolicyStatus } from 'types/Settings'
import {
  IP_ADDRESS_INPUT_NAMES,
  UseIpAddressTableLogicProps,
  useIpAddressTableLogic
} from 'components/libs/ipAddressTable/useIpAddressTableLogic'
import styles from 'components/libs/ipAddressTable/ipAddressTableStyles'
import WarningDialog from '../dialog/warningDialog/WarningDialog'

const BASE_I18N_KEY = 'ess.settings.ip_address_table'

const IpAddressTable: React.FC<UseIpAddressTableLogicProps> = (props: UseIpAddressTableLogicProps) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()
  const [state, eventHandlers] = useIpAddressTableLogic(props)

  return useMemo(
    () => (
      <Grid data-testid="ip-address-table-grid">
        <DataTable
          data={state.tableData}
          sortable={{
            allowUnsort: false,
            mode: 'single'
          }}
          sort={state.sort}
          onSortChange={eventHandlers.handleSortChange}
        >
          <DataTableColumn
            sortable
            field={IP_ADDRESS_INPUT_NAMES.ADDRESS}
            title={formatMessage('ip_address')}
            cell={({ dataItem }: { dataItem: IpAddressEntry }) => (
              <Cell>
                {dataItem.id === '' && (
                  <IpTextField
                    defaultValue={state.formState.address}
                    inputRef={state.form.fields.address.ref}
                    onChange={eventHandlers.handleOnInputChange}
                    size="small"
                    name={IP_ADDRESS_INPUT_NAMES.ADDRESS}
                    error={!!(state.errors.newIpAddressError || state.errors.newIpDuplicateError)}
                    helperText={state.errors.newIpAddressError || state.errors.newIpDuplicateError || ''}
                    inputProps={{ style: { padding: 8 } }}
                    data-testid="new-ip-field"
                  />
                )}
                {dataItem.id !== '' && (
                  <Typography variant="body2" data-testid="ip-field">
                    {dataItem.address}
                  </Typography>
                )}
              </Cell>
            )}
          />

          <DataTableColumn
            sortable
            field={IP_ADDRESS_INPUT_NAMES.NETMASK}
            title={formatMessage('netmask')}
            cell={({ dataItem }: { dataItem: IpAddressEntry }) => (
              <Cell>
                {dataItem.id === '' && (
                  <IpTextField
                    defaultValue={state.formState.netmask}
                    inputRef={state.form.fields.netmask.ref}
                    onChange={eventHandlers.handleOnInputChange}
                    size="small"
                    name={IP_ADDRESS_INPUT_NAMES.NETMASK}
                    error={!!state.errors.newIpNetmaskError}
                    helperText={state.errors.newIpNetmaskError || ''}
                    inputProps={{ style: { padding: 8 } }}
                    data-testid="new-netmask-field"
                  />
                )}
                {dataItem.id !== '' && (
                  <Typography variant="body2" data-testid="netmask-field">
                    {dataItem.netmask}
                  </Typography>
                )}
              </Cell>
            )}
          />

          {props.shouldShowPolicy &&
            ((
              <DataTableColumn
                sortable
                field={IP_ADDRESS_INPUT_NAMES.POLICY}
                title={formatMessage('policy')}
                cell={({ dataItem }: { dataItem: IpAddressEntry }) => (
                  <Cell>
                    {dataItem.id === '' && (
                      <Select
                        variant="outlined"
                        className={classes.dropDownList}
                        name={IP_ADDRESS_INPUT_NAMES.POLICY}
                        defaultValue={state.formState.policy}
                        onChange={eventHandlers.onPolicyChange}
                        SelectDisplayProps={{ style: { padding: 8 } }}
                        data-testid="new-policy-field"
                      >
                        {AVAILABLE_IP_EXEMPTION_POLICIES.map(policyValue => (
                          <MenuItem key={policyValue} value={policyValue}>
                            {formatMessage(policyValue)}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                    {dataItem.id !== '' && (
                      <Typography variant="body2" data-testid="policy-field" className={classes.policyWrapper}>
                        {dataItem.policy === IpPolicyStatus.block && <Block className={classes.block} />}
                        {dataItem.policy === IpPolicyStatus.exempt && <CheckmarkCircle className={classes.check} />}
                        {formatMessage(dataItem.policy as string)}
                      </Typography>
                    )}
                  </Cell>
                )}
              />
            ) as any)}

          <DataTableColumn
            sortable
            field={IP_ADDRESS_INPUT_NAMES.COMMENT}
            title={formatMessage('comment')}
            cell={({ dataItem }: { dataItem: IpAddressEntry }) => (
              <Cell>
                {dataItem.id === '' && (
                  <TextField
                    defaultValue={state.formState.comment}
                    inputRef={state.form.fields.comment.ref}
                    onChange={eventHandlers.handleOnInputChange}
                    size="small"
                    name={IP_ADDRESS_INPUT_NAMES.COMMENT}
                    inputProps={{ style: { padding: 8 } }}
                    data-testid="new-comment-field"
                  />
                )}
                {dataItem.id !== '' && (
                  <Typography variant="body2" data-testid="comment-field">
                    {dataItem.comment}
                  </Typography>
                )}
              </Cell>
            )}
          />
          <DataTableColumn
            sortable={false}
            field="actions"
            title={formatMessage('actions')}
            cell={({ dataItem }: { dataItem: IpAddressEntry }) => (
              <Cell>
                {dataItem.id === '' && (
                  <Button
                    onClick={eventHandlers.onAdd}
                    variant="text"
                    color="primary"
                    size="small"
                    data-testid="add-button"
                  >
                    {formatMessage('add')}
                  </Button>
                )}
                {dataItem.id !== '' && (
                  <Button
                    onClick={() => eventHandlers.onRemove(dataItem.id)}
                    variant="text"
                    color="primary"
                    size="small"
                    data-testid="remove-button"
                  >
                    {formatMessage('remove')}
                  </Button>
                )}
              </Cell>
            )}
          />
        </DataTable>
        {!!state.idToRemove && (
          <WarningDialog
            title={formatMessage('confirm_removal')}
            onConfirm={eventHandlers.onConfirmRemove}
            onCancel={eventHandlers.onConfirmCancel}
          />
        )}
      </Grid>
    ),
    [formatMessage, props.shouldShowPolicy, classes, state, eventHandlers]
  )
}

export default IpAddressTable
