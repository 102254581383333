import { UiRoutes } from 'lib/routesConfig'
import { AppDispatch } from 'redux/toolkit/store'
import { postAllowSender, postBlockSender, postRedeliverMessage } from 'redux/features/mstore/mstoreApiThunks'

export enum PostAuthCommandType {
  REDIRECT = 'REDIRECT',
  DELIVER = 'DELIVER',
  BLOCK = 'BLOCK',
  WHITELIST = 'WHITELIST',
  VIEW_QUARANTINE_SETTINGS = 'VIEW_QUARANTINE_SETTINGS',
  VIEW_QUARANTINED_MESSAGES = 'VIEW_QUARANTINED_MESSAGES',
  VIEW_SENDER_POLICES = 'VIEW_SENDER_POLICIES'
}

export interface PostAuthRedirectCommand {
  type: PostAuthCommandType.REDIRECT
  redirectTo: string
}

export interface PostAuthViewQuarantineSettingsCommand {
  type: PostAuthCommandType.VIEW_QUARANTINE_SETTINGS
}

export interface PostAuthViewQuarantinedMessagesCommand {
  type: PostAuthCommandType.VIEW_QUARANTINED_MESSAGES
}

export interface PostAuthViewSenderPoliciesCommand {
  type: PostAuthCommandType.VIEW_SENDER_POLICES
}

export interface PostAuthDeliverCommand {
  type: PostAuthCommandType.DELIVER
  userId: string
  messageId: string
}

export interface PostAuthBlockCommand {
  type: PostAuthCommandType.BLOCK
  userId: string
  messageId: string
}

export interface PostAuthWhitelistCommand {
  type: PostAuthCommandType.WHITELIST
  userId: string
  messageId: string
}

export type PostAuthCommand =
  | PostAuthRedirectCommand
  | PostAuthDeliverCommand
  | PostAuthBlockCommand
  | PostAuthWhitelistCommand
  | PostAuthViewQuarantineSettingsCommand
  | PostAuthViewQuarantinedMessagesCommand
  | PostAuthViewSenderPoliciesCommand

const isPostAuthCommand = (maybeCommand: unknown): maybeCommand is PostAuthCommand => {
  if (typeof maybeCommand !== 'object' || maybeCommand === null || !Object.keys(maybeCommand).includes('type')) {
    return false
  }
  return Object.values(PostAuthCommandType).includes((maybeCommand as any).type)
}

export const isPostAuthRedirectCommand = (maybeCommand: unknown): maybeCommand is PostAuthRedirectCommand => {
  if (!isPostAuthCommand(maybeCommand)) {
    return false
  }
  return maybeCommand.type === PostAuthCommandType.REDIRECT
}

export const isPostAuthViewQuarantinedMessagesCommand = (
  maybeCommand: unknown
): maybeCommand is PostAuthViewQuarantinedMessagesCommand => {
  if (!isPostAuthCommand(maybeCommand)) {
    return false
  }
  return maybeCommand.type === PostAuthCommandType.VIEW_QUARANTINED_MESSAGES
}

export const makePostAuthCommandFactory = (currentUserId: string) => {
  const isCurrentUserParams = (searchParams: URLSearchParams) => {
    const userId = searchParams.get('user')
    return userId === null || userId === currentUserId
  }

  const isQuarantinedMessagesView = (searchParams: URLSearchParams) => {
    const action = searchParams.get('action')
    if (!action) {
      return false
    }
    return action.toLowerCase() === 'view_quarantine'
  }

  const isQuarantineSettingsView = (searchParams: URLSearchParams) => {
    const returnUrl = searchParams.get('return_url')
    if (!returnUrl) {
      return false
    }
    return returnUrl.includes('/user/settings/quarantine_notification')
  }

  const isSenderPoliciesView = (searchParams: URLSearchParams) => {
    const returnUrl = searchParams.get('return_url')
    if (!returnUrl) {
      return false
    }
    return returnUrl.includes('/user/settings/sender_policy')
  }

  const getMessageLogActionParams = (searchParams: URLSearchParams) => {
    const url = searchParams.get('url')
    const userId = searchParams.get('user')
    if (!url || !userId) {
      return null
    }
    const [, , action, , messageId] = url.split('/')
    return { action, userId, messageId }
  }

  const create = (queryString: string): PostAuthCommand => {
    const searchParams = new URLSearchParams(queryString)

    if (!isCurrentUserParams(searchParams)) {
      return { type: PostAuthCommandType.REDIRECT, redirectTo: '/webui/message-log' }
    }

    if (isQuarantinedMessagesView(searchParams)) {
      return { type: PostAuthCommandType.VIEW_QUARANTINED_MESSAGES }
    }

    if (isSenderPoliciesView(searchParams)) {
      return { type: PostAuthCommandType.VIEW_SENDER_POLICES }
    }

    if (isQuarantineSettingsView(searchParams)) {
      return { type: PostAuthCommandType.VIEW_QUARANTINE_SETTINGS }
    }

    const messageLogActionParams = getMessageLogActionParams(searchParams)
    if (!messageLogActionParams) {
      return { type: PostAuthCommandType.REDIRECT, redirectTo: '/webui/message-log' }
    }

    const { action, userId, messageId } = messageLogActionParams

    if (action.toLowerCase() === 'deliver') {
      return { type: PostAuthCommandType.DELIVER, messageId, userId }
    }

    if (action.toLowerCase() === 'block') {
      return { type: PostAuthCommandType.BLOCK, messageId, userId }
    }

    if (action.toLowerCase() === 'whitelist') {
      return { type: PostAuthCommandType.WHITELIST, messageId, userId }
    }

    return { type: PostAuthCommandType.REDIRECT, redirectTo: '/webui/message-log' }
  }

  return Object.freeze({
    create
  })
}

export const makePostAuthCommandHandler = (uiRoutes: UiRoutes, dispatch: AppDispatch, userDomainId: number) => {
  const handle = (command: PostAuthCommand) => {
    const { type } = command
    switch (type) {
      case PostAuthCommandType.VIEW_QUARANTINE_SETTINGS: {
        uiRoutes.SETTINGS_QUARANTINE_NOTIFICATION.goto()
        break
      }
      case PostAuthCommandType.VIEW_SENDER_POLICES: {
        uiRoutes.SETTINGS_SENDER_POLICY.goto()
        break
      }
      case PostAuthCommandType.DELIVER: {
        dispatch(
          postRedeliverMessage({
            items: [{ messageId: command.messageId, domainId: userDomainId }],
            atpBypass: false
          })
        )
        uiRoutes.MESSAGE_LOG.goto()
        break
      }
      case PostAuthCommandType.BLOCK: {
        dispatch(postBlockSender([{ messageId: command.messageId, domainId: userDomainId }]))
        uiRoutes.MESSAGE_LOG.goto()
        break
      }
      case PostAuthCommandType.WHITELIST: {
        dispatch(
          postAllowSender({
            items: [{ messageId: command.messageId, domainId: userDomainId }],
            atpBypass: false
          })
        )
        uiRoutes.MESSAGE_LOG.goto()
        break
      }
      case PostAuthCommandType.VIEW_QUARANTINED_MESSAGES: // fallthrough
      case PostAuthCommandType.REDIRECT: // fallthrough
      default: {
        // TODO: map redirectTo URLs if needed, for now just sending user to message log
        uiRoutes.MESSAGE_LOG.goto()
      }
    }
  }

  return Object.freeze({
    handle
  })
}
