import { colors, makeStyles } from '@barracuda-internal/bds-core'
import usersStyles from 'components/pages/users/usersStyles'

const usersListStyles = makeStyles(theme => ({
  userRoleSelector: {
    backgroundColor: colors.white,
    minWidth: 250,
    marginRight: theme.spacing(2)
  },
  domainSelector: {
    backgroundColor: colors.white,
    minWidth: 300
  },
  searchFieldWrapper: {
    marginRight: theme.spacing(2)
  },
  searchField: {
    backgroundColor: colors.white,
    width: '100%'
  },
  tableWrapper: {
    position: 'relative',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  loadingIndicator: {
    top: -3,
    left: 0,
    right: 0,
    position: 'absolute'
  },
  selectColumn: {
    padding: '2px 0 0 9px !important'
  },
  enabledIcon: {
    color: colors.forest600,
    verticalAlign: -7
  },
  disabledIcon: {
    color: colors.scaleGray300,
    verticalAlign: -7
  },
  noRecords: {
    marginTop: theme.spacing(10)
  },
  checkboxHeaderCell: {
    marginLeft: -7,
    marginBottom: -8
  },
  nonExpandedRow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer'
  },
  expandedRow: {
    wordBreak: 'keep-all',
    overflow: 'hidden',
    cursor: 'pointer'
  },
  checkedRow: {
    color: colors.barracudaBlue
  },
  interactiveCell: {
    cursor: 'pointer'
  },
  ellipsisText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  actionButton: {
    height: theme.spacing(3)
  },
  inlineCell: {
    whiteSpace: 'nowrap',
    paddingLeft: '0 !important'
  },
  ellipsisCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  addUpdateUsersButton: {
    marginRight: theme.spacing(1)
  },
  loadingState: {
    width: '18px !important',
    height: '18px !important',
    verticalAlign: -8,
    marginLeft: theme.spacing(0.5)
  },
  dialogSubtitle: {
    marginBottom: theme.spacing(3)
  },
  domainsTable: {
    marginTop: theme.spacing(2),
    cursor: 'pointer'
  },
  disabledDomainsTable: {
    marginTop: theme.spacing(2),
    pointerEvents: 'none',
    opacity: 0.4,
    cursor: 'default'
  },
  domainColumn: {
    lineHeight: `${theme.spacing(3)}px`
  },
  criticalIcon: {
    color: colors.statusCritical,
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginRight: theme.spacing(1)
  }
}))

export default () => ({
  ...usersListStyles(),
  ...usersStyles()
})
