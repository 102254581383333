import { SupportTopicConfig } from 'types/Support'
import routesConfig from 'lib/routesConfig'

import { UserRole } from 'config/userRole'
import StatusSeenRecipients from './help/statusSeenRecipients/StatusSeenRecipients'
import Status from './help/status/Status'
import Compliance from './help/compliance/Compliance'
import Log from './help/log/Log'
import AuditLog from './help/auditLog/AuditLog'
import AtpLog from './help/atpLog/AtpLog'
import DomainVerification from './help/domainVerification/DomainVerification'
import MailServerTest from './help/mailServerTest/MailServerTest'
import DomainManager from './help/domainManager/DomainManager'
import DomainSettings from './help/domainSettings/DomainSettings'
import RecipientList from './help/recipientList/RecipientList'
import InboundAntiVirus from './help/inboundAntiVirus/InboundAntiVirus'
import InboundCustomRbls from './help/inboundCustomRbls/InboundCustomRbls'
import InboundIpAddressPolicies from './help/inboundIpAddressPolicies/InboundIpAddressPolicies'
import InboundRegionalPolicies from './help/inboundRegionalPolicies/InboundRegionalPolicies'
import InboundRecipientPolicies from './help/inboundRecipientPolicies/InboundRecipientPolicies'
import InboundSenderPolicies from './help/inboundSenderPolicies/InboundSenderPolicies'
import InboundSenderAuthentication from './help/inboundSenderAuthentication/InboundSenderAuthentication'
import InboundContentPolicies from './help/inboundContentPolicies/InboundContentPolicies'
import InboundRateControl from './help/inboundRateControl/InboundRateControl'
import InboundAntiPhishing from './help/inboundAntiPhishing/InboundAntiPhishing'
import InboundAtp from './help/inboundAtp/InboundAtp'
import OutboundSenderIp from './help/outboundSenderIp/OutboundSenderIp'
import OutboundAntiVirus from './help/outboundAntiVirus/OutboundAntiVirus'
import OutboundTagline from './help/outboundTagline/OutboundTagline'
import OutboundDlp from './help/outboundDlp/OutboundDlp'
import OutboundContentPolicies from './help/outboundContentPolicies/OutboundContentPolicies'
import SenderNotification from './help/senderNotification/SenderNotification'
import OutboundQuarantineInbox from './help/outboundQuarantineInbox/OutboundQuarantineInbox'
import UsersDefaultPolicy from './help/usersDefaultPolicy/UsersDefaultPolicy'
import UsersEdit from './help/usersEdit/UsersEdit'
import UsersEmailContinuity from './help/usersEmailContinuity/UsersEmailContinuity'
import UsersQuarantine from './help/usersQuarantine/UsersQuarantine'
import UsersList from './help/usersList/UsersList'
import LinkedAccounts from './help/linkedAccounts/LinkedAccounts'
import EndUserSenderPolicy from './help/endUserSenderPolicy/EndUserSenderPolicy'
import EndUserQuarantineNotification from './help/endUserQuarantineNotification/EndUserQuarantineNotification'
import EndUserLog from './help/endUserLog/EndUserLog'
import SelfService1 from './help/selfService1/SelfService1'
import SelfService2 from './help/selfService2/SelfService2'
import SelfService3 from './help/selfService3/SelfService3'
import SelfService4 from './help/selfService4/SelfService4'
import SelfService5 from './help/selfService5/SelfService5'
import ReportsDomain from './help/reportsDomain/ReportsDomain'
import ReportsAllDomains from './help/reportsAllDomains/ReportsAllDomains'
import SyslogIntegration from './help/syslogIntegration/SyslogIntegration'
import Regex from './help/regex/Regex'
import InboundMachineLearning from './help/InboundMachineLearning/InboundMachineLearning'

export enum TOPICS {
  seenRecipients = 'seen_recipients',
  dashboard = 'dashboard',
  stayingInCompliance = 'staying_in_compliance',
  adminMessageLog = 'message_log',
  atpLog = 'atp_log',
  auditLog = 'audit_log',
  domainVerification = 'domain_verification',
  mailServerTest = 'mail_server_test',
  domainManager = 'domain_manager',
  domainSettings = 'domain_settings',
  recipientsList = 'recipients_list',
  antiSpamAntivirus = 'anti_spam_antivirus',
  customRbls = 'custom_rbls',
  ipAddressPolicies = 'ip_address_policies',
  regionalPolicies = 'regional_policies',
  recipientPolicies = 'recipient_policies',
  senderPolicies = 'sender_policies',
  senderAuthentication = 'sender_authentication',
  inboundContentPolicies = 'content_policies',
  rateControl = 'rate_control',
  antiPhishing = 'anti_phishing',
  atpSettings = 'atp_settings',
  senderIpAddressRanges = 'sender_ip_address_ranges',
  antiVirus = 'anti_virus',
  taglineFooter = 'tagline_footer',
  dlpEncryption = 'dlp_encryption',
  outboundContentPolicies = 'content_policies',
  notifications = 'notifications',
  outboundQuarantine = 'outbound_quarantine',
  defaultPolicy = 'default_policy',
  addUpdateUsers = 'add_update_users',
  emailContinuity = 'email_continuity',
  userQuarantineNotification = 'quarantine_notification',
  usersList = 'users_list',
  linkedAccounts = 'linked_accounts',
  senderPolicy = 'sender_policy',
  endUserQuarantineNotification = 'quarantine_notification',
  userMessageLog = 'message_log',
  specifyPrimaryEmailDomain = 'specify_primary_email_domain',
  specifyEmailServers = 'specify_email_servers',
  configureSettings = 'configure_settings',
  configureOutboundSettingsForOffice365 = 'configure_outbound_settings_for_office_365',
  routeEmailThroughBarracudaNetworks = 'route_email_through_barracuda_networks',
  regularExpressions = 'regular_expressions',
  generateReportThisDomain = 'generate_report_this_domain',
  generateReportAllDomains = 'generate_report_all_domains',
  syslogIntegration = 'syslog_integration',
  configureMxRecords = 'configure_mx_records',
  machineLearning = 'machine_learning'
}

export enum GROUP_TITLE {
  dashboard = 'dashboard',
  messageLog = 'message_log',
  domains = 'domains',
  inboundSettings = 'inbound_settings',
  outboundSettings = 'outbound_settings',
  outboundQuarantine = 'outbound_quarantine',
  userTopics = 'user_topics',
  endUser = 'end_user',
  selfServicePortalTopics = 'self_service_portal_topics',
  generalTopics = 'general_topics',
  reportTopics = 'report_topics',
  accountManagementTopics = 'account_management_topics'
}

const dashboardTopics = {
  title: GROUP_TITLE.dashboard,
  minimumRole: UserRole.DOMAIN_USER,
  topics: [
    {
      label: TOPICS.seenRecipients,
      href: routesConfig.SUPPORT_HELP_SEEN_RECIPIENTS,
      content: StatusSeenRecipients,
      title: TOPICS.seenRecipients,
      groupTitle: GROUP_TITLE.dashboard
    },
    {
      label: TOPICS.dashboard,
      href: routesConfig.SUPPORT_HELP_DASHBOARD,
      content: Status,
      title: TOPICS.dashboard,
      groupTitle: GROUP_TITLE.dashboard
    },
    {
      label: TOPICS.stayingInCompliance,
      href: routesConfig.SUPPORT_HELP_COMPLIANCE,
      content: Compliance,
      title: TOPICS.stayingInCompliance,
      groupTitle: GROUP_TITLE.dashboard
    }
  ]
}

const messageLogTopics = {
  title: GROUP_TITLE.messageLog,
  minimumRole: UserRole.DOMAIN_USER,
  topics: [
    {
      label: TOPICS.adminMessageLog,
      href: routesConfig.SUPPORT_HELP_ADMIN_LOG,
      content: Log,
      title: TOPICS.adminMessageLog,
      groupTitle: GROUP_TITLE.messageLog
    },
    {
      label: TOPICS.atpLog,
      href: routesConfig.SUPPORT_HELP_ATP_LOG,
      content: AtpLog,
      title: TOPICS.atpLog,
      groupTitle: GROUP_TITLE.messageLog
    },
    {
      label: TOPICS.auditLog,
      href: routesConfig.SUPPORT_HELP_AUDIT_LOG,
      content: AuditLog,
      title: TOPICS.auditLog,
      groupTitle: GROUP_TITLE.messageLog
    }
  ]
}

const domainTopics = {
  title: GROUP_TITLE.domains,
  minimumRole: UserRole.DOMAIN_USER,
  topics: [
    {
      label: TOPICS.domainVerification,
      href: routesConfig.SUPPORT_HELP_DOMAIN_VERIFICATION,
      content: DomainVerification,
      title: TOPICS.domainVerification,
      groupTitle: GROUP_TITLE.domains
    },
    {
      label: TOPICS.mailServerTest,
      href: routesConfig.SUPPORT_HELP_MAIL_SERVER_TEST,
      content: MailServerTest,
      title: TOPICS.mailServerTest,
      groupTitle: GROUP_TITLE.domains
    },
    {
      label: TOPICS.domainManager,
      href: routesConfig.SUPPORT_HELP_DOMAIN_MANAGER,
      content: DomainManager,
      title: TOPICS.domainManager,
      groupTitle: GROUP_TITLE.domains
    },
    {
      label: TOPICS.domainSettings,
      href: routesConfig.SUPPORT_HELP_DOMAIN_SETTINGS,
      content: DomainSettings,
      title: TOPICS.domainSettings,
      groupTitle: GROUP_TITLE.domains
    },
    {
      label: TOPICS.recipientsList,
      href: routesConfig.SUPPORT_HELP_RECIPIENT_LIST,
      content: RecipientList,
      title: TOPICS.recipientsList,
      groupTitle: GROUP_TITLE.domains
    }
  ]
}
const inboundSettingTopics = {
  title: GROUP_TITLE.inboundSettings,
  minimumRole: UserRole.DOMAIN_USER,
  topics: [
    {
      label: TOPICS.antiSpamAntivirus,
      href: routesConfig.SUPPORT_HELP_INBOUND_ANTI_VIRUS,
      content: InboundAntiVirus,
      title: TOPICS.antiVirus,
      groupTitle: GROUP_TITLE.inboundSettings
    },
    {
      label: TOPICS.customRbls,
      href: routesConfig.SUPPORT_HELP_INBOUND_CUSTOM_RBLS,
      content: InboundCustomRbls,
      title: TOPICS.customRbls,
      groupTitle: GROUP_TITLE.inboundSettings
    },
    {
      label: TOPICS.ipAddressPolicies,
      href: routesConfig.SUPPORT_HELP_INBOUND_IP_ADDRESS_POLICIES,
      content: InboundIpAddressPolicies,
      title: TOPICS.ipAddressPolicies,
      groupTitle: GROUP_TITLE.inboundSettings
    },
    {
      label: TOPICS.regionalPolicies,
      href: routesConfig.SUPPORT_HELP_INBOUND_REGIONAL_POLICIES,
      content: InboundRegionalPolicies,
      title: TOPICS.regionalPolicies,
      groupTitle: GROUP_TITLE.inboundSettings
    },
    {
      label: TOPICS.recipientPolicies,
      href: routesConfig.SUPPORT_HELP_INBOUND_RECIPIENT_POLICIES,
      content: InboundRecipientPolicies,
      title: TOPICS.recipientPolicies,
      groupTitle: GROUP_TITLE.inboundSettings
    },
    {
      label: TOPICS.senderPolicies,
      href: routesConfig.SUPPORT_HELP_INBOUND_SENDER_POLICIES,
      content: InboundSenderPolicies,
      title: TOPICS.senderPolicies,
      groupTitle: GROUP_TITLE.inboundSettings
    },
    {
      label: TOPICS.senderAuthentication,
      href: routesConfig.SUPPORT_HELP_INBOUND_SENDER_AUTHENTICATION,
      content: InboundSenderAuthentication,
      title: TOPICS.senderAuthentication,
      groupTitle: GROUP_TITLE.inboundSettings
    },
    {
      label: TOPICS.inboundContentPolicies,
      href: routesConfig.SUPPORT_HELP_INBOUND_CONTENT_POLICIES,
      content: InboundContentPolicies,
      title: TOPICS.inboundContentPolicies,
      groupTitle: GROUP_TITLE.inboundSettings
    },
    {
      label: TOPICS.rateControl,
      href: routesConfig.SUPPORT_HELP_INBOUND_RATE_CONTROL,
      content: InboundRateControl,
      title: TOPICS.rateControl,
      groupTitle: GROUP_TITLE.inboundSettings
    },
    {
      label: TOPICS.antiPhishing,
      href: routesConfig.SUPPORT_HELP_INBOUND_ANTI_PHISHING,
      content: InboundAntiPhishing,
      title: TOPICS.antiPhishing,
      groupTitle: GROUP_TITLE.inboundSettings
    },
    {
      label: TOPICS.atpSettings,
      href: routesConfig.SUPPORT_HELP_INBOUND_ATP,
      atp: true,
      content: InboundAtp,
      title: TOPICS.atpSettings,
      groupTitle: GROUP_TITLE.inboundSettings
    },
    {
      label: TOPICS.machineLearning,
      href: routesConfig.SUPPORT_HELP_INBOUND_MACHINE_LEARNING,
      atp: true,
      content: InboundMachineLearning,
      title: TOPICS.machineLearning,
      groupTitle: GROUP_TITLE.inboundSettings
    }
  ]
}

const outboundSettingTopics = {
  title: GROUP_TITLE.outboundSettings,
  minimumRole: UserRole.DOMAIN_USER,
  cpl: false,
  topics: [
    {
      label: TOPICS.senderIpAddressRanges,
      href: routesConfig.SUPPORT_HELP_OUTBOUND_SENDER_IP,
      content: OutboundSenderIp,
      title: TOPICS.senderIpAddressRanges,
      groupTitle: GROUP_TITLE.outboundSettings
    },
    {
      label: TOPICS.antiVirus,
      href: routesConfig.SUPPORT_HELP_OUTBOUND_ANTI_VIRUS,
      content: OutboundAntiVirus,
      title: TOPICS.antiVirus,
      groupTitle: GROUP_TITLE.outboundSettings
    },
    {
      label: TOPICS.taglineFooter,
      href: routesConfig.SUPPORT_HELP_OUTBOUND_TAGLINE,
      content: OutboundTagline,
      title: TOPICS.taglineFooter,
      groupTitle: GROUP_TITLE.outboundSettings
    },
    {
      label: TOPICS.dlpEncryption,
      href: routesConfig.SUPPORT_HELP_OUTBOUND_DLP,
      content: OutboundDlp,
      title: TOPICS.dlpEncryption,
      groupTitle: GROUP_TITLE.outboundSettings
    },
    {
      label: TOPICS.outboundContentPolicies,
      href: routesConfig.SUPPORT_HELP_OUTBOUND_CONTENT_POLICIES,
      content: OutboundContentPolicies,
      title: TOPICS.outboundContentPolicies,
      groupTitle: GROUP_TITLE.outboundSettings
    },
    {
      label: TOPICS.notifications,
      href: routesConfig.SUPPORT_HELP_SENDER_NOTIFICATION,
      content: SenderNotification,
      title: TOPICS.notifications,
      groupTitle: GROUP_TITLE.outboundSettings
    }
  ]
}

const outboundQuarantineTopics = {
  title: GROUP_TITLE.outboundQuarantine,
  minimumRole: UserRole.DOMAIN_USER,
  cpl: false,
  topics: [
    {
      label: TOPICS.outboundQuarantine,
      href: routesConfig.SUPPORT_HELP_OUTBOUND_QUARANTINE_INBOX,
      content: OutboundQuarantineInbox,
      title: TOPICS.outboundQuarantine,
      groupTitle: GROUP_TITLE.outboundQuarantine
    }
  ]
}

const userTopics = {
  title: GROUP_TITLE.userTopics,
  minimumRole: UserRole.DOMAIN_USER,
  cpl: false,
  topics: [
    {
      label: TOPICS.defaultPolicy,
      href: routesConfig.SUPPORT_HELP_USERS_DEFAULT_POLICY,
      content: UsersDefaultPolicy,
      title: TOPICS.defaultPolicy,
      groupTitle: GROUP_TITLE.userTopics
    },
    {
      label: TOPICS.addUpdateUsers,
      href: routesConfig.SUPPORT_HELP_USERS_EDIT,
      content: UsersEdit,
      title: TOPICS.addUpdateUsers,
      groupTitle: GROUP_TITLE.userTopics
    },
    {
      label: TOPICS.emailContinuity,
      href: routesConfig.SUPPORT_HELP_USERS_EMAIL_CONTINUITY,
      content: UsersEmailContinuity,
      title: TOPICS.emailContinuity,
      groupTitle: GROUP_TITLE.userTopics
    },
    {
      label: TOPICS.userQuarantineNotification,
      href: routesConfig.SUPPORT_HELP_USERS_QUARANTINE,
      content: UsersQuarantine,
      title: TOPICS.userQuarantineNotification,
      groupTitle: GROUP_TITLE.userTopics
    },
    {
      label: TOPICS.usersList,
      href: routesConfig.SUPPORT_HELP_USERS_LIST,
      content: UsersList,
      title: TOPICS.usersList,
      groupTitle: GROUP_TITLE.userTopics
    }
  ]
}

const endUserTopics = {
  title: GROUP_TITLE.endUser,
  cpl: false,
  topics: [
    {
      label: TOPICS.linkedAccounts,
      href: routesConfig.SUPPORT_HELP_LINKED_ACCOUNTS,
      content: LinkedAccounts,
      title: TOPICS.linkedAccounts,
      groupTitle: GROUP_TITLE.endUser
    },
    {
      label: TOPICS.senderPolicy,
      href: routesConfig.SUPPORT_HELP_ENDUSER_SENDER_POLICY,
      content: EndUserSenderPolicy,
      title: TOPICS.senderPolicy,
      groupTitle: GROUP_TITLE.endUser
    },
    {
      label: TOPICS.endUserQuarantineNotification,
      href: routesConfig.SUPPORT_HELP_ENDUSER_QUARANTINE_NOTIFICATION,
      content: EndUserQuarantineNotification,
      title: TOPICS.endUserQuarantineNotification,
      groupTitle: GROUP_TITLE.endUser
    },
    {
      label: TOPICS.userMessageLog,
      href: routesConfig.SUPPORT_HELP_ENDUSER_LOG,
      content: EndUserLog,
      title: TOPICS.userMessageLog,
      groupTitle: GROUP_TITLE.endUser
    }
  ]
}

const selfServicePortalTopics = {
  title: GROUP_TITLE.selfServicePortalTopics,
  minimumRole: UserRole.DOMAIN_USER,
  cpl: false,
  topics: [
    {
      label: TOPICS.specifyPrimaryEmailDomain,
      href: routesConfig.SUPPORT_HELP_SELF_SERVICE1,
      content: SelfService1,
      title: TOPICS.specifyPrimaryEmailDomain,
      groupTitle: GROUP_TITLE.selfServicePortalTopics
    },
    {
      label: TOPICS.specifyEmailServers,
      href: routesConfig.SUPPORT_HELP_SELF_SERVICE2,
      content: SelfService2,
      title: TOPICS.specifyEmailServers,
      groupTitle: GROUP_TITLE.selfServicePortalTopics
    },
    {
      label: TOPICS.configureSettings,
      href: routesConfig.SUPPORT_HELP_SELF_SERVICE3,
      content: SelfService3,
      title: TOPICS.configureSettings,
      groupTitle: GROUP_TITLE.selfServicePortalTopics
    },
    {
      label: TOPICS.configureOutboundSettingsForOffice365,
      href: routesConfig.SUPPORT_HELP_SELF_SERVICE4,
      content: SelfService4,
      title: TOPICS.configureOutboundSettingsForOffice365,
      groupTitle: GROUP_TITLE.selfServicePortalTopics
    },
    {
      label: TOPICS.routeEmailThroughBarracudaNetworks,
      href: routesConfig.SUPPORT_HELP_SELF_SERVICE5,
      content: SelfService5,
      title: TOPICS.routeEmailThroughBarracudaNetworks,
      groupTitle: GROUP_TITLE.selfServicePortalTopics
    }
  ]
}

const generalTopics = {
  title: GROUP_TITLE.generalTopics,
  minimumRole: UserRole.DOMAIN_USER,
  topics: [
    {
      label: TOPICS.regularExpressions,
      href: routesConfig.SUPPORT_HELP_REGEX,
      content: Regex,
      title: TOPICS.linkedAccounts,
      groupTitle: GROUP_TITLE.generalTopics
    }
  ]
}

const reportTopics = {
  title: GROUP_TITLE.reportTopics,
  minimumRole: UserRole.ACCOUNT_USER,
  cpl: false,
  topics: [
    {
      label: TOPICS.generateReportThisDomain,
      href: routesConfig.SUPPORT_HELP_REPORTS_DOMAIN,
      content: ReportsDomain,
      title: TOPICS.generateReportThisDomain,
      groupTitle: GROUP_TITLE.reportTopics
    },
    {
      label: TOPICS.generateReportAllDomains,
      href: routesConfig.SUPPORT_HELP_REPORTS_ALL_DOMAIN,
      content: ReportsAllDomains,
      title: TOPICS.generateReportAllDomains,
      groupTitle: GROUP_TITLE.reportTopics
    }
  ]
}

const accountManagementTopics = {
  title: GROUP_TITLE.accountManagementTopics,
  minimumRole: UserRole.DOMAIN_USER,
  cpl: false,
  atp: true,
  topics: [
    {
      label: TOPICS.syslogIntegration,
      href: routesConfig.SUPPORT_HELP_SYSLOG_INTEGRATION,
      content: SyslogIntegration,
      title: TOPICS.syslogIntegration,
      groupTitle: GROUP_TITLE.accountManagementTopics
    }
  ]
}

/*
    The order of the appearance will depend on how the arrays are placed
    This will work both for the group order and the items in the group itself
*/
export const ALL_TOPICS: SupportTopicConfig[] = [
  dashboardTopics,
  messageLogTopics,
  domainTopics,
  inboundSettingTopics,
  outboundSettingTopics,
  outboundQuarantineTopics,
  userTopics,
  endUserTopics,
  selfServicePortalTopics,
  generalTopics,
  reportTopics,
  accountManagementTopics
]
