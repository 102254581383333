import { colors, makeStyles } from '@barracuda-internal/bds-core'

const BORDER_COLOR = '#F0F0EF'

export default makeStyles(theme => ({
  container: {
    position: 'absolute'
  },
  selector: {
    height: theme.spacing(4),
    backgroundColor: colors.white,
    borderColor: BORDER_COLOR,
    borderRadius: '0px 0px 4px 4px',
    fontSize: 14,

    '& [role="button"]': {
      padding: `0 ${theme.spacing(4)}px 2px ${theme.spacing(2)}px`
    }
  },
  dropdown: {
    position: 'relative',
    zIndex: 4999
  },
  openedContainer: {
    zIndex: 4999,
    position: 'relative',
    height: 391,
    backgroundColor: colors.white,
    padding: theme.spacing(2),
    border: `1px solid ${BORDER_COLOR}`
  },
  listContainer: {
    overflowY: 'scroll',
    marginTop: theme.spacing(1),
    height: 252
  },
  listItem: {
    paddingLeft: theme.spacing(1),
    fontSize: 14
  },
  activeListItem: {
    cursor: 'default',
    paddingLeft: theme.spacing(1),
    fontSize: 14
  },
  listItemIcon: {
    marginRight: -theme.spacing(4),
    marginBottom: 2,

    '& svg': {
      width: 16,
      height: 16
    }
  },
  buttonContainer: {
    borderTop: `1px solid ${colors.scaleGray200}`,
    position: 'absolute',
    width: 'auto',
    paddingTop: theme.spacing(2),
    left: theme.spacing(2),
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  }
}))
