import React, { useMemo } from 'react'

import { NavigationBar } from '@cuda/bds.ui.navigation.navigation-bar'
import { FaviconEmail } from '@barracuda-internal/bds-core/dist/Logos/Favicons'
import {
  ArrowDownward,
  ArrowUpward,
  BarChart,
  Dashboard,
  HelpOutline,
  Language,
  Security,
  Settings,
  VerticalSplit
} from '@material-ui/icons'
import { UserGroup } from '@barracuda-internal/bds-core/dist/Icons/Core'
import { Alert, AlertProps, Snackbar } from '@barracuda-internal/bds-core'
import { useSideBarLogic } from 'components/libs/layout/navbar/useSideBarLogic'
import styles from 'components/libs/layout/navbar/sidebarStyles'
import LanguageMenu from 'components/libs/dialog/languageMenu/LanguageMenuDialog'
import routesConfig from 'lib/routesConfig'
import { useFormatMessage } from 'lib/localization'
import appConfig from 'config/appConfig'

const BASE_I18N_KEY = 'ess.sidemenu'

const MAIN_MENU_ICONS: { [key: string]: any } = {
  [routesConfig.OVERVIEW?.id]: Dashboard,
  [routesConfig.DOMAINS?.id]: Language,
  [routesConfig.INBOUND_SETTINGS?.id]: ArrowDownward,
  [routesConfig.OUTBOUND_SETTINGS?.id]: ArrowUpward,
  [routesConfig.ATP_SETTINGS?.id]: Security,
  [routesConfig.USERS?.id]: UserGroup,
  [routesConfig.REPORT_LIST?.id]: BarChart,
  [routesConfig.ACCOUNT_MANAGEMENT?.id]: VerticalSplit,

  // enduser paths
  [routesConfig.MESSAGE_LOG?.id]: BarChart,
  [routesConfig.SETTINGS?.id]: Settings,

  // common paths
  [routesConfig.SUPPORT?.id]: HelpOutline
}

function SideBar() {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const {
    sideMenuItems,
    onSelectListItem,
    subMenuItems,
    isSideBarVisible,
    isBlockedApp,
    isUsedOnetimePasscode,
    handleCloseSnackBar,
    snackBarMessage,
    snackbarOpen,
    snackbarSeverity,
    options,
    isLanguageMenuDialogOpen,
    onCloseLanguageMenu,
    activePath
  } = useSideBarLogic()

  return useMemo(() => {
    if (
      !isSideBarVisible ||
      isBlockedApp ||
      isUsedOnetimePasscode ||
      !((appConfig.APP.IS_ENDUSER && !isUsedOnetimePasscode) || appConfig.APP.IS_ADMIN || !appConfig.APP.IS_WIZARD)
    ) {
      return null
    }

    return (
      <>
        <NavigationBar
          classes={{ navigationSubMenu: classes.navSubMenu }}
          title=""
          logo={<FaviconEmail viewBox="0 0 50 50" />}
          options={options}
          routes={sideMenuItems.map(uiRoute => {
            const Icon = MAIN_MENU_ICONS[uiRoute.id]
            return {
              path: uiRoute.path,
              name: formatMessage(uiRoute.id.toLowerCase()),
              icon: <Icon />,
              children: subMenuItems[uiRoute.id],
              testId: subMenuItems[uiRoute.id]
            }
          })}
          currentPath={activePath.url}
          onNavigate={onSelectListItem}
        />
        {isLanguageMenuDialogOpen && <LanguageMenu onClose={onCloseLanguageMenu} />}
        <Snackbar
          className={classes.snackBar}
          open={snackbarOpen}
          onClose={handleCloseSnackBar}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          TransitionProps={{
            appear: false
          }}
          data-testid="snackbar"
        >
          <Alert
            severity={snackbarSeverity as AlertProps['severity']}
            onClose={handleCloseSnackBar}
            data-testid="alert"
          >
            {snackBarMessage}
          </Alert>
        </Snackbar>
      </>
    )
  }, [
    classes.navSubMenu,
    isSideBarVisible,
    isBlockedApp,
    isUsedOnetimePasscode,
    options,
    sideMenuItems,
    onSelectListItem,
    isLanguageMenuDialogOpen,
    onCloseLanguageMenu,
    classes.snackBar,
    snackbarOpen,
    handleCloseSnackBar,
    snackbarSeverity,
    snackBarMessage,
    formatMessage,
    subMenuItems,
    activePath
  ])
}

export default SideBar
