import { UserType } from 'types/auth'
import { SortDirection } from 'types/redux/dataTables/dataTables'
import { InboundScheduleReport, InboundScheduleType, InboundScheduleUI } from 'types/Settings'

export enum AccountUserRole {
  all = 'all',
  domain_admin = 'domain_admin',
  help_desk = 'help_desk',
  user = 'user'
}

export type LinkedAccount = {
  id: string
  email: string
}

export type AccountUser = {
  userId: string
  linkedAccounts: LinkedAccount[]
  source: UserType
  role: AccountUserRole
  quarantine: QuarantineStatus
  helpdeskDomains: string[]
  managedDomains: string[]
}

export enum AccountUserSortKey {
  userId = 'user_id',
  source = 'source'
}

export type AccountUsersMetadata = {
  offset: number
  limit: number
  search: string
  roles: AccountUserRole
  sort: AccountUserSortKey
  direction: SortDirection
  domain: string
  accountId: string
  bccAccountId: string
}

export interface AccountUsersResults {
  users: AccountUser[]
  count: number
}

export enum UserUpdateResults {
  successfullyAdded = 'successfully_added',
  successfullyUpdated = 'successfully_updated',
  failedToAddUser = 'failed_to_add_user',
  failedToUpdateUser = 'failed_to_update_user',
  invalidEmail = 'invalid_email',
  invalidDomain = 'invalid_domain',
  missedDomainRight = 'missed_domain_right',
  alreadyExistsAsLinkedAccount = 'already_exists_as_linked_account'
}

export type AddUpdateAccountUserResult = [UserEmail: string, Result: UserUpdateResults]

export enum QuarantineStatus {
  loading,
  enabled,
  disabled
}

export enum ManagedPolicy {
  scan = 'scan',
  allow = 'allow',
  block = 'block'
}

export enum ExemptSenders {
  block = '0',
  allow = '1',
  partiallyAllow = '2'
}

export interface UserQNSettings {
  userId: string
  value: InboundScheduleReport
}

export interface AccountEamilContinuitySettings {
  isAutoEnabled: boolean
  isSpooling: boolean
}

export interface QuarantineNotificationSettings {
  enableQuarantine: boolean
  allowTemporaryPasscode: boolean
  quarantineNotificationInterval: InboundScheduleType
  customQuarantineNotificationInterval?: InboundScheduleUI
  allowQuarantineNotificationIntervalChange: boolean
}

export interface PoliciesSettings {
  defaultManagedScanPolicy: ManagedPolicy
  defaultUnmanagedScanPolicy: ManagedPolicy
  defaultUserExemptEnabled: ExemptSenders
  defaultUserBlockEnabled: boolean
  defaultUserDeliverEnabled: boolean
  defaultUserQnarDeliverEnabled: boolean
  defaultTimezone: string
}
