import React, { useMemo } from 'react'
import {
  Alert,
  Box,
  Button,
  DataTable,
  DataTableColumn,
  Grid,
  IconButton,
  LinearProgress,
  TextField,
  Tooltip,
  Typography
} from '@barracuda-internal/bds-core'

import SuccessIcon from '@barracuda-internal/bds-core/dist/Icons/Feedback/Success'
import CriticalIcon from '@barracuda-internal/bds-core/dist/Icons/Feedback/Critical'
import WarningIcon from '@barracuda-internal/bds-core/dist/Icons/Feedback/Warning'
import FlagIcon from '@material-ui/icons/Flag'
import { GridNoRecords } from '@progress/kendo-react-grid'
import { Search } from '@barracuda-internal/bds-core/dist/Icons/Core'
import { GROUP_TITLE, TOPICS } from 'components/pages/support/config'
import { useErrorFormatMessage, useFormatMessage } from 'lib/localization'
import { Cell } from 'components/libs/grid/cell'
import { ModifiedDomainManagerItem, useDomainsLogic } from 'components/pages/domains/useDomainsLogic'
import { DomainMoveState, DomainStatus, MxStatus } from 'types/domains'
import styles from 'components/pages/domains/domainsStyles'
import ConfigureMxRecords from 'components/pages/support/help/configureMxRecords/ConfigureMxRecords'
import Help from 'components/pages/support/help/Help'
import DomainManager from 'components/pages/support/help/domainManager/DomainManager'
import { AddDomain } from 'components/pages/domains/addDomain/AddDomain'
import { RemoveDomain } from 'components/pages/domains/removeDomain/RemoveDomain'
import { onKeyDown } from 'lib/keyEvents'
import { PageContainer, PageTitle, FormSpacer } from 'components/libs/layout/pages/pageLayout'
import WarningDialog from 'components/libs/dialog/warningDialog/WarningDialog'

const BASE_I18N_KEY = 'ess.domains'

const Domains = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const errorFormatMessage = useErrorFormatMessage()
  const [state, eventHandlers] = useDomainsLogic()
  const classes = styles()

  return useMemo(
    () => (
      <Grid container className={classes.domainsPage}>
        <WarningDialog
          open={state.globalReset.isOpen}
          title={formatMessage('global_reset.title')}
          description={formatMessage('global_reset.description')}
          onCancel={eventHandlers.globalReset.onCloseConfirmDialog}
          onConfirm={eventHandlers.globalReset.onConfirmReset}
          isPending={state.globalReset.isPending}
        />
        <Grid item className={classes.defaultRow}>
          <PageContainer>
            <Grid container justifyContent="space-between">
              <Grid item xs>
                <PageTitle
                  title={formatMessage('header.title')}
                  onOpenSupport={eventHandlers.help.onHelpOpen}
                  help={{
                    content: DomainManager,
                    onClose: eventHandlers.help.onHelpClose,
                    title: TOPICS.domainManager,
                    group: GROUP_TITLE.domains,
                    isHelpOpen: state.help.isHelpOpen
                  }}
                />
                <FormSpacer />
              </Grid>
              <Grid item className={classes.actions}>
                {state.permissions.canAddDomain && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={eventHandlers.addDomain.onOpenAddDomain}
                    data-testid="save-button"
                    disabled={false}
                  >
                    {formatMessage('header.add_domain')}
                  </Button>
                )}
              </Grid>
            </Grid>
          </PageContainer>
        </Grid>
        <Grid item className={classes.defaultRow}>
          <AddDomain open={state.addDomain.isAddDomainOpen} onClose={eventHandlers.addDomain.onCloseAddDomain} />
          <RemoveDomain
            domainId={state.removeDomain.removeDomainData?.domainId}
            isDomainMove={state.removeDomain.removeDomainData?.isDomainMove}
            onClose={eventHandlers.removeDomain.onCloseRemoveDomain}
          />
          {state.mxBanner.isVisible && (
            <Alert severity="warning" className={classes.mxBanner}>
              {formatMessage('mx_banner.text')}
              <Button variant="text" size="small" color="primary" onClick={eventHandlers.mxBanner.onClickLearnMore}>
                <Grid container>
                  <Grid item xs={12} ref={state.mxBanner.ref}>
                    {formatMessage('mx_banner.learn_more')}
                  </Grid>
                </Grid>
              </Button>
              {state.mxBanner.isPopoverOpen && (
                <Help
                  modalComponentInfo={{
                    content: ConfigureMxRecords,
                    onClose: eventHandlers.mxBanner.onClosePopover,
                    title: TOPICS.configureMxRecords,
                    groupTitle: GROUP_TITLE.domains
                  }}
                />
              )}
            </Alert>
          )}
        </Grid>
        <Grid item className={classes.defaultRow}>
          <TextField
            className={classes.searchTextField}
            label={formatMessage('search_domain.label')}
            placeholder={formatMessage('search_domain.hint')}
            variant="outlined"
            value={state.searchDomain.domainNameFilter}
            onChange={eventHandlers.searchDomain.onChangeDomainNameFilter}
            onKeyDown={onKeyDown(['Enter'], eventHandlers.searchDomain.onSearchDomain)}
            InputProps={{
              startAdornment: (
                <IconButton
                  size="small"
                  edge="start"
                  data-id="search-button"
                  data-testid="search-button"
                  onClick={eventHandlers.searchDomain.onSearchDomain}
                >
                  <Search />
                </IconButton>
              )
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{ style: { paddingTop: 15, paddingBottom: 15, paddingRight: 4, paddingLeft: 4 } }}
          />
        </Grid>
        <Grid item className={classes.defaultRow}>
          {state.table.error && <Alert severity="error">{errorFormatMessage(state.table.error)}</Alert>}
        </Grid>
        <Grid item className={classes.defaultRow}>
          {state.table.isLoading && <LinearProgress />}
        </Grid>
        <Grid item className={classes.tableRow}>
          <Grid container className={classes.domainsTable}>
            <Grid item className={classes.kendoGrid}>
              <DataTable
                data={state.table.data || []}
                pageConfig={{
                  pageable: { pageSizes: [10, 50, 100] },
                  skip: state.table.config.skip,
                  take: state.table.config.take,
                  total: state.table.total || 0
                }}
                onPageChange={eventHandlers.onPageChange}
                onSortChange={eventHandlers.onSortChange}
                resizable
                sortable={{ allowUnsort: false, mode: 'single' }}
                sort={state.table.config.sort}
                onRowClick={e => eventHandlers.onRowClick(e.syntheticEvent as any, e.dataItem)}
                detail={({ dataItem }: { dataItem: ModifiedDomainManagerItem }) => (
                  <Grid container className={classes.details}>
                    <Grid item xs={3}>
                      <Typography className={classes.detailTitle} variant="body2">
                        {formatMessage('details.mx_records_configuration')}
                      </Typography>
                      {!dataItem.isDomainMove && (
                        <>
                          <Typography variant="body2">{formatMessage('details.primary')}:</Typography>
                          <Typography variant="body2">{dataItem.primaryMxRecord?.domain}</Typography>
                          <Typography variant="body2">{formatMessage('details.backup')}:</Typography>
                          <Typography variant="body2">{dataItem.backupMxRecord?.domain}</Typography>
                        </>
                      )}
                      {!dataItem.isDomainMove && state.permissions.canSeeOutboundSmarthost && (
                        <>
                          <Typography className={`${classes.detailTitle} ${classes.detailTopMargin}`} variant="body2">
                            {formatMessage('details.outbound_smarthost_configuration')}
                          </Typography>
                          <Typography variant="body2">{formatMessage('details.hostname')}:</Typography>
                          <Typography variant="body2">{dataItem.outboundSmarthost}</Typography>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      <Typography className={classes.detailTitle} variant="body2">
                        {formatMessage('details.aliases')}
                      </Typography>
                      {(!dataItem.aliases || dataItem.aliases.length === 0) && (
                        <Typography variant="body2">None</Typography>
                      )}
                      {(dataItem.aliases || []).map(alias => (
                        <Button
                          key={alias.domainId}
                          variant="text"
                          size="small"
                          color="primary"
                          disabled={state.page.isPending || !state.permissions.canEditDomain}
                          onClick={e => eventHandlers.onEditDomain(e, alias.domainId)}
                        >
                          {alias.domainName}
                        </Button>
                      ))}
                    </Grid>
                    <Grid item xs={3}>
                      {state.permissions.canSeeEmailContinuityStatus && (
                        <>
                          <Typography className={classes.detailTitle} variant="body2">
                            {formatMessage('details.email_continuity')}
                          </Typography>
                          <Typography variant="body2">{formatMessage(dataItem.emergencyInboxEnabledTextId)}</Typography>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      {state.permissions.canResetDomainToAccountSettings && (
                        <Typography className={classes.detailTitle} variant="body2">
                          {formatMessage('details.domain_specific_policies')}
                        </Typography>
                      )}
                      {state.permissions.canResetDomainToAccountSettings && dataItem.uniqueSettings?.length && (
                        <Button
                          variant="text"
                          size="small"
                          color="primary"
                          disabled={state.page.isPending}
                          onClick={e => eventHandlers.globalReset.onOpenConfirmDialog(e, dataItem)}
                        >
                          {formatMessage('details.reset_to_account_policies')}
                        </Button>
                      )}
                      {state.permissions.canResetDomainToAccountSettings &&
                        (!dataItem.uniqueSettings || dataItem.uniqueSettings.length === 0) && (
                          <Typography variant="body2">{formatMessage('details.account_policies')}</Typography>
                        )}
                    </Grid>
                  </Grid>
                )}
                expandField="isExpanded"
              >
                <GridNoRecords>
                  {state.table.loadedAndNoRecord ? formatMessage('table.no_records_available') : ' '}
                </GridNoRecords>
                <DataTableColumn
                  field={state.table.config.GRID_COLUMNS.DOMAIN_NAME}
                  width={300}
                  {...state.table.config.columnsConfig[state.table.config.GRID_COLUMNS.DOMAIN_NAME]}
                  title={formatMessage(`table.${state.table.config.GRID_COLUMNS.DOMAIN_NAME}`)}
                  cell={({ dataItem }: { dataItem: ModifiedDomainManagerItem }) => (
                    <Cell onClick={event => eventHandlers.onRowClick(event, dataItem)}>
                      <Typography variant="body2">
                        {dataItem.domainName}
                        {dataItem.status === DomainStatus.NEW &&
                          dataItem.isDomainMove &&
                          (() => {
                            switch (dataItem.domainMoveState) {
                              case DomainMoveState.TO_TRANSFER:
                                return (
                                  <Button
                                    variant="text"
                                    color="primary"
                                    size="small"
                                    onClick={e => eventHandlers.onVerifyDomainOwnershipAndTransfer(e, dataItem)}
                                  >
                                    ({formatMessage('verify_ownership_and_transfer')})
                                  </Button>
                                )
                              case DomainMoveState.TRANSFER_PENDING:
                                return <> ({formatMessage('transfer_pending')})</>
                              case DomainMoveState.TRANSFER_COMPLETE:
                                return <> ({formatMessage('transfer_completed')})</>
                              default:
                                return null
                            }
                          })()}
                        {dataItem.status === DomainStatus.NEW && !dataItem.isDomainMove && (
                          <Button
                            variant="text"
                            color="primary"
                            size="small"
                            onClick={e => eventHandlers.onVerifyDomainOwnership(e, dataItem)}
                          >
                            (Verify ownership)
                          </Button>
                        )}
                        {state.permissions.shouldSeeHybridLabel(dataItem.type) && (
                          <> ({formatMessage('type_hybrid')})</>
                        )}
                      </Typography>
                    </Cell>
                  )}
                />
                <DataTableColumn
                  width={300}
                  field={state.table.config.GRID_COLUMNS.STATUS}
                  {...state.table.config.columnsConfig[state.table.config.GRID_COLUMNS.STATUS]}
                  title={formatMessage(`table.${state.table.config.GRID_COLUMNS.STATUS}`)}
                  cell={({ dataItem }: { dataItem: ModifiedDomainManagerItem }) => (
                    <Cell onClick={event => eventHandlers.onRowClick(event, dataItem)}>
                      {dataItem.mxStatus === MxStatus.MISCONFIGURED && (
                        <Tooltip disableFocusListener title={formatMessage('tooltip.misconfigured')}>
                          <Box className={classes.statusBox}>
                            <WarningIcon className={classes.statusIconWarning} />
                            <Typography variant="body2">{formatMessage('status.misconfigured')}</Typography>
                          </Box>
                        </Tooltip>
                      )}
                      {dataItem.mxStatus !== MxStatus.MISCONFIGURED && dataItem.status === DomainStatus.VERIFIED && (
                        <Box className={classes.statusBox}>
                          <SuccessIcon className={classes.statusIconSuccess} />
                          <Typography variant="body2">{formatMessage('status.verified')}</Typography>
                        </Box>
                      )}
                      {dataItem.mxStatus !== MxStatus.MISCONFIGURED && dataItem.status === DomainStatus.NEW && (
                        <Box className={classes.statusBox}>
                          <CriticalIcon className={classes.statusIconCritical} />
                          <Typography variant="body2">{formatMessage('status.unverified')}</Typography>
                        </Box>
                      )}
                    </Cell>
                  )}
                />
                <DataTableColumn
                  width={90}
                  field={state.table.config.GRID_COLUMNS.ALIASES}
                  {...state.table.config.columnsConfig[state.table.config.GRID_COLUMNS.ALIASES]}
                  title={formatMessage(`table.${state.table.config.GRID_COLUMNS.ALIASES}`)}
                  cell={({ dataItem }: { dataItem: ModifiedDomainManagerItem }) => (
                    <Cell onClick={event => eventHandlers.onRowClick(event, dataItem)}>
                      {!dataItem.isDomainMove && (
                        <Typography variant="body2">{(dataItem.aliases || []).length}</Typography>
                      )}
                    </Cell>
                  )}
                />
                <DataTableColumn
                  width={150}
                  field={state.table.config.GRID_COLUMNS.RECIPIENTS}
                  {...state.table.config.columnsConfig[state.table.config.GRID_COLUMNS.RECIPIENTS]}
                  title={formatMessage(`table.${state.table.config.GRID_COLUMNS.RECIPIENTS}`)}
                  cell={({ dataItem }: { dataItem: ModifiedDomainManagerItem }) => (
                    <Cell>
                      {!dataItem.isDomainMove && (
                        <Button
                          variant="text"
                          color="primary"
                          size="small"
                          onClick={e => eventHandlers.onViewDomainRecipients(e, dataItem)}
                        >
                          {dataItem.uniqueRecipientCount}
                        </Button>
                      )}
                    </Cell>
                  )}
                />
                <DataTableColumn
                  width={300}
                  field={state.table.config.GRID_COLUMNS.MAIL_SERVERS}
                  {...state.table.config.columnsConfig[state.table.config.GRID_COLUMNS.MAIL_SERVERS]}
                  title={formatMessage(`table.${state.table.config.GRID_COLUMNS.MAIL_SERVERS}`)}
                  cell={({ dataItem }: { dataItem: ModifiedDomainManagerItem }) => (
                    <Cell onClick={event => eventHandlers.onRowClick(event, dataItem)}>
                      {!dataItem.isDomainMove && dataItem.mailServers.length === 0 && (
                        <Typography variant="body2" color="error" className={classes.mailServerText}>
                          {formatMessage('table.no_mail_servers_defined')}
                        </Typography>
                      )}
                      {!dataItem.isDomainMove &&
                        dataItem.mailServers.length > 0 &&
                        dataItem.mailServers.map(srv => (
                          <Typography key={srv.id} variant="body2" className={classes.mailServerText}>
                            {srv.host}
                          </Typography>
                        ))}
                    </Cell>
                  )}
                />
                <DataTableColumn
                  width={100}
                  field={state.table.config.GRID_COLUMNS.SETTINGS}
                  {...state.table.config.columnsConfig[state.table.config.GRID_COLUMNS.SETTINGS]}
                  title={formatMessage(`table.${state.table.config.GRID_COLUMNS.SETTINGS}`)}
                  cell={({ dataItem }: { dataItem: ModifiedDomainManagerItem }) => (
                    <Cell onClick={event => eventHandlers.onRowClick(event, dataItem)}>
                      {!dataItem.isDomainMove && state.permissions.canEditDomain && (
                        <Button
                          variant="text"
                          onClick={e => eventHandlers.onEditDomain(e, dataItem.domainId)}
                          size="small"
                          color="primary"
                          disabled={state.page.isPending}
                        >
                          {formatMessage('actions.edit')}
                        </Button>
                      )}
                    </Cell>
                  )}
                />
                <DataTableColumn
                  width={200}
                  field={state.table.config.GRID_COLUMNS.DOMAIN_OPTIONS}
                  {...state.table.config.columnsConfig[state.table.config.GRID_COLUMNS.DOMAIN_OPTIONS]}
                  title={formatMessage(`table.${state.table.config.GRID_COLUMNS.DOMAIN_OPTIONS}`)}
                  cell={({ dataItem }: { dataItem: ModifiedDomainManagerItem }) => (
                    <Cell onClick={event => eventHandlers.onRowClick(event, dataItem)}>
                      <Grid container>
                        <Grid item xs={12} className={classes.domainOptionsCell}>
                          {!dataItem.isDomainMove && dataItem.hasDomainSpecificSettings && (
                            <Tooltip disableFocusListener title={formatMessage('tooltip.unique_settings')}>
                              <FlagIcon className={classes.flagIcon} />
                            </Tooltip>
                          )}
                          {!dataItem.isDomainMove && state.permissions.canManageDomain(dataItem.type) && (
                            <Button
                              variant="text"
                              onClick={e => eventHandlers.onManageDomain(e, dataItem)}
                              size="small"
                              color="primary"
                              disabled={state.page.isPending}
                              isLoading={state.manageDomain.domainId === dataItem.domainId}
                            >
                              {formatMessage(state.manageDomain.buttonLabel)}
                            </Button>
                          )}
                          {dataItem.domainMoveState !== DomainMoveState.TRANSFER_PENDING &&
                            state.permissions.canDeleteDomain && (
                              <Button
                                variant="text"
                                onClick={e => eventHandlers.onRemoveDomain(e, dataItem)}
                                size="small"
                                color="primary"
                                disabled={state.page.isPending}
                                isLoading={
                                  (state.removeDomain.isRemoveDomainPending ||
                                    state.removeDomain.isRemoveDomainMovePending) &&
                                  state.removeDomain.removeDomainData?.domainId === dataItem.domainId
                                }
                              >
                                {formatMessage('actions.remove')}
                              </Button>
                            )}
                        </Grid>
                      </Grid>
                    </Cell>
                  )}
                />
              </DataTable>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    ),
    [classes, eventHandlers, errorFormatMessage, formatMessage, state]
  )
}

export default Domains
