/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react'

import { CheckCircle as CheckCircleIcon } from '@mui/icons-material'
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Select,
  SelectMenuItem,
  SelectWrapper
} from '@barracuda-internal/bds-core'
import { SearchField } from '@cuda/bds.ui.components.search-field'
import {
  Account,
  AccountSwitcherStates,
  UnifiedAccountSwitcherLogicProps,
  useUnifiedAccountSwitcherLogic
} from './useUnifiedAccountSwitcherLogic'
import highlightText from './highlightText'

import styles from './unifiedAccountSwitcherStyles'

export type { Account } from './useUnifiedAccountSwitcherLogic'

export interface UnifiedAccountSwitcherProps extends UnifiedAccountSwitcherLogicProps {
  buttonText?: string
}

const UnifiedAccountSwitcher = ({
  accounts,
  buttonText,
  initAccount,
  onSelectAccountCallback
}: UnifiedAccountSwitcherProps) => {
  const classes = styles()
  const [state, eventHandlers] = useUnifiedAccountSwitcherLogic({ accounts, initAccount, onSelectAccountCallback })

  return useMemo(
    () => (
      <Grid container className={classes.container} justifyContent="center" alignItems="center">
        {/* closed state */}
        {state.accountSwitcherState === AccountSwitcherStates.minimized && (
          <Grid item className={classes.dropdown}>
            <SelectWrapper variant="outlined">
              <Select
                data-testid="account-selector"
                className={classes.selector}
                onMouseDown={eventHandlers.onToggleSwitcherState}
                value={state.activeAccount?.id}
              >
                {accounts?.map(account => (
                  <SelectMenuItem data-testid={account.id} key={account.id} value={account.id}>
                    {account.name}
                  </SelectMenuItem>
                ))}
              </Select>
            </SelectWrapper>
          </Grid>
        )}

        {/* opened state */}
        {state.accountSwitcherState === AccountSwitcherStates.opened && (
          <Grid container item xs={4} className={classes.openedContainer} direction="column">
            <Grid item>
              <SearchField
                id="account-switcher-search"
                placeholder="Search"
                value={state.search}
                onChange={eventHandlers.onSearch}
              />
            </Grid>
            <Grid item className={classes.listContainer}>
              <List aria-label="accounts">
                {state.activeAccount && (
                  <ListItem className={classes.activeListItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <CheckCircleIcon />
                    </ListItemIcon>
                    {state.activeAccount?.name}
                  </ListItem>
                )}
                {accounts
                  .filter(
                    account =>
                      account.id !== state.activeAccount?.id &&
                      account.name.toLowerCase().includes(state.search.toLowerCase())
                  )
                  .map(account => (
                    <ListItem
                      className={classes.listItem}
                      button
                      key={account.id}
                      onClick={() => eventHandlers.onSelectNewAccount(account.id)}
                    >
                      {highlightText(account.name, state.search)}
                    </ListItem>
                  ))}
              </List>
            </Grid>
            <Grid container className={classes.buttonContainer} justifyContent="flex-end" alignItems="center">
              <Button
                color="primary"
                variant="text"
                onClick={eventHandlers.onToggleSwitcherState}
                data-testid="cancel-button"
              >
                {buttonText || 'cancel'}
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    ),
    [classes, state, accounts, buttonText, eventHandlers]
  )
}

export default UnifiedAccountSwitcher
