import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'

import { isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import { getPoliciesSettings, resetPoliciesSettings, updatePoliciesSettings } from 'redux/features/users/usersSlice'
import { setErrorSnackBar, setSuccessSnackBar } from 'redux/features/app/appSlice'
import { useDirtyFormCheck } from 'lib/useDirtyFormCheck'
import { RadioButtonStates } from 'components/pages/users/usersTypes'
import { ExemptSenders, ManagedPolicy } from 'types/users'
import { DEFAULT_TIMEZONE } from 'lib/datetime'
import { trackEventInAllServices, TRACKING_EVENTS } from 'lib/monitoring/monitoringService'

export interface State {
  inProgress: boolean
  isLoading: boolean
  isFailedToLoad: boolean
  isDirtyForm: boolean
  defaultManagedScanPolicy: ManagedPolicy
  defaultUnmanagedScanPolicy: ManagedPolicy
  defaultUserExemptEnabled: ExemptSenders
  defaultUserBlockEnabled: RadioButtonStates
  defaultUserDeliverEnabled: RadioButtonStates
  defaultUserQnarDeliverEnabled: RadioButtonStates
  defaultTimezone: string
}

export interface EventHandlers {
  onSaveChanges: () => void
  setDefaultManagedScanPolicy: Dispatch<SetStateAction<ManagedPolicy>>
  setDefaultUnmanagedScanPolicy: Dispatch<SetStateAction<ManagedPolicy>>
  setDefaultUserExemptEnabled: Dispatch<SetStateAction<ExemptSenders>>
  setDefaultUserBlockEnabled: Dispatch<SetStateAction<RadioButtonStates>>
  setDefaultUserDeliverEnabled: Dispatch<SetStateAction<RadioButtonStates>>
  setDefaultUserQnarDeliverEnabled: Dispatch<SetStateAction<RadioButtonStates>>
  setDefaultTimezone: Dispatch<SetStateAction<string>>
}

export type UseEmailContinuityLogic = [State, EventHandlers]
export const useDefaultPolicyLogic = (): UseEmailContinuityLogic => {
  const dispatch = useAppDispatch()
  const {
    policySettings,
    inProgress,
    isLoading,
    isFailedToLoad,
    isUpdatePoliciesSettingsIsSuccess,
    isUpdatePoliciesSettingsIsFailed,
    isGetPoliciesSettingsIsSuccess
  } = useAppSelector(_store => ({
    policySettings: _store.users.policiesSettings,
    inProgress: isPending(_store.users.api.updatePoliciesSettingsApiStatus),
    isLoading: isPending(_store.users.api.getPoliciesSettingsApiStatus),
    isFailedToLoad: isFailed(_store.users.api.getPoliciesSettingsApiStatus),
    isUpdatePoliciesSettingsIsSuccess: isSuccess(_store.users.api.updatePoliciesSettingsApiStatus),
    isUpdatePoliciesSettingsIsFailed: isFailed(_store.users.api.updatePoliciesSettingsApiStatus),
    isGetPoliciesSettingsIsSuccess: isSuccess(_store.users.api.getPoliciesSettingsApiStatus)
  }))

  const [defaultManagedScanPolicy, setDefaultManagedScanPolicy] = useState<ManagedPolicy>(ManagedPolicy.scan)
  const [defaultUnmanagedScanPolicy, setDefaultUnmanagedScanPolicy] = useState<ManagedPolicy>(ManagedPolicy.scan)
  const [defaultUserExemptEnabled, setDefaultUserExemptEnabled] = useState<ExemptSenders>(ExemptSenders.allow)
  const [defaultUserBlockEnabled, setDefaultUserBlockEnabled] = useState<RadioButtonStates>(RadioButtonStates.on)
  const [defaultUserDeliverEnabled, setDefaultUserDeliverEnabled] = useState<RadioButtonStates>(RadioButtonStates.on)
  const [defaultUserQnarDeliverEnabled, setDefaultUserQnarDeliverEnabled] = useState<RadioButtonStates>(
    RadioButtonStates.off
  )
  const [defaultTimezone, setDefaultTimezone] = useState<string>(DEFAULT_TIMEZONE)

  const [isDirtyForm, resetInitialForm] = useDirtyFormCheck([
    defaultManagedScanPolicy,
    defaultUnmanagedScanPolicy,
    defaultUserExemptEnabled,
    defaultUserBlockEnabled,
    defaultUserDeliverEnabled,
    defaultUserQnarDeliverEnabled,
    defaultTimezone
  ])
  const [shouldUpdateInitialForm, setShouldUpdateInitialForm] = useState<boolean>(false)

  // init
  useEffect(
    () => {
      dispatch(getPoliciesSettings())
      trackEventInAllServices(TRACKING_EVENTS.ADMIN.DEFAULT_POLICY_PAGE_VIEW)

      return () => {
        dispatch(resetPoliciesSettings())
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  )

  // set initialForm values
  useEffect(() => {
    if (shouldUpdateInitialForm) {
      setShouldUpdateInitialForm(false)
      resetInitialForm()
    }
  }, [shouldUpdateInitialForm, resetInitialForm])

  // get is success
  useEffect(() => {
    if (isGetPoliciesSettingsIsSuccess && policySettings) {
      setDefaultManagedScanPolicy(policySettings.defaultManagedScanPolicy)
      setDefaultUnmanagedScanPolicy(policySettings.defaultUnmanagedScanPolicy)
      setDefaultUserExemptEnabled(policySettings.defaultUserExemptEnabled)
      setDefaultUserBlockEnabled(
        policySettings.defaultUserBlockEnabled || false ? RadioButtonStates.on : RadioButtonStates.off
      )
      setDefaultUserDeliverEnabled(
        policySettings.defaultUserDeliverEnabled || false ? RadioButtonStates.on : RadioButtonStates.off
      )
      setDefaultUserQnarDeliverEnabled(
        policySettings.defaultUserQnarDeliverEnabled || false ? RadioButtonStates.on : RadioButtonStates.off
      )
      setDefaultTimezone(policySettings.defaultTimezone)

      setShouldUpdateInitialForm(true)
    }
  }, [dispatch, isGetPoliciesSettingsIsSuccess, policySettings])

  // update is success
  useEffect(() => {
    if (isUpdatePoliciesSettingsIsSuccess) {
      dispatch(setSuccessSnackBar({ message: 'update_policies_settings_success' }))
    }
  }, [dispatch, isUpdatePoliciesSettingsIsSuccess])

  // update is Failed
  useEffect(() => {
    if (isUpdatePoliciesSettingsIsFailed) {
      dispatch(setErrorSnackBar({ message: 'update_policies_settings_failed' }))
    }
  }, [dispatch, isUpdatePoliciesSettingsIsFailed])

  const onSaveChanges = useCallback(() => {
    dispatch(
      updatePoliciesSettings({
        defaultManagedScanPolicy,
        defaultUnmanagedScanPolicy,
        defaultUserExemptEnabled,
        defaultUserBlockEnabled: defaultUserBlockEnabled === RadioButtonStates.on,
        defaultUserDeliverEnabled: defaultUserDeliverEnabled === RadioButtonStates.on,
        defaultUserQnarDeliverEnabled: defaultUserQnarDeliverEnabled === RadioButtonStates.on,
        defaultTimezone
      })
    )
  }, [
    dispatch,
    defaultManagedScanPolicy,
    defaultUnmanagedScanPolicy,
    defaultUserExemptEnabled,
    defaultUserBlockEnabled,
    defaultUserDeliverEnabled,
    defaultUserQnarDeliverEnabled,
    defaultTimezone
  ])

  return useMemo(
    () => [
      {
        inProgress,
        isLoading,
        isFailedToLoad,
        isDirtyForm,
        defaultManagedScanPolicy,
        defaultUnmanagedScanPolicy,
        defaultUserExemptEnabled,
        defaultUserBlockEnabled,
        defaultUserDeliverEnabled,
        defaultUserQnarDeliverEnabled,
        defaultTimezone
      },
      {
        onSaveChanges,
        setDefaultManagedScanPolicy,
        setDefaultUnmanagedScanPolicy,
        setDefaultUserExemptEnabled,
        setDefaultUserBlockEnabled,
        setDefaultUserDeliverEnabled,
        setDefaultUserQnarDeliverEnabled,
        setDefaultTimezone
      }
    ],
    [
      inProgress,
      isLoading,
      isFailedToLoad,
      isDirtyForm,
      onSaveChanges,
      defaultManagedScanPolicy,
      defaultUnmanagedScanPolicy,
      defaultUserExemptEnabled,
      defaultUserBlockEnabled,
      defaultUserDeliverEnabled,
      defaultUserQnarDeliverEnabled,
      defaultTimezone,
      setDefaultManagedScanPolicy,
      setDefaultUnmanagedScanPolicy,
      setDefaultUserExemptEnabled,
      setDefaultUserBlockEnabled,
      setDefaultUserDeliverEnabled,
      setDefaultUserQnarDeliverEnabled,
      setDefaultTimezone
    ]
  )
}
