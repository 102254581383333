import React, { useMemo } from 'react'
import { Typography, Link } from '@barracuda-internal/bds-core'
import config from 'config/appConfig'
import styles from 'components/libs/layout/footer/appFooter/appFooterStyles'
import inIframe from 'lib/inIframe'

function AppFooter() {
  const classes = styles()

  return useMemo(() => {
    const copyrightYears = new Date().getFullYear()

    if (inIframe()) {
      return null
    }

    return (
      <div className={classes.container} id="footer">
        <div className={classes.components}>
          <Typography variant="body1" className={classes.copyright}>
            © {copyrightYears} Barracuda Networks, Inc. All rights reserved. &nbsp;|
          </Typography>
          <Link
            href="https://www.barracuda.com/company/legal/privacy"
            className={classes.privacy}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </Link>
          <Typography variant="body1" className={classes.copyright}>
            &nbsp;|
          </Typography>
          <Link
            href="https://www.barracuda.com/legal/terms"
            className={classes.terms}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </Link>
          <Typography variant="body1" className={classes.copyright}>
            &nbsp;|
          </Typography>
          {config.ONETRUST_ID && (
            <button id="ot-sdk-btn" className="ot-sdk-show-settings">
              Cookie Preferences
            </button>
          )}
        </div>
      </div>
    )
  }, [classes])
}

export default AppFooter
