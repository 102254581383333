import { colors, makeStyles } from '@barracuda-internal/bds-core'

export default makeStyles(theme => ({
  contentWrapper: {
    background: colors.white,
    height: 24,
    // BDS component has negative -11px margin
    paddingRight: theme.spacing(3)
  }
}))
